#homeBanner {
  width: 100%;
  margin-top: 80px;
  position: relative;
  z-index: 0;
  overflow: hidden;
}
@media (max-width: 480px) {
  #homeBanner {
    margin-top: 70px;
  }
}
#homeBanner::after {
  position: absolute;
  content: "";
  width: 100%;
  height: 400px;
  background: #f4ffe5;
  top: 0;
  left: 0;
  z-index: -3;
}
@media (max-width: 480px) {
  #homeBanner::after {
    height: 430px;
  }
}
#homeBanner .bannerMain {
  width: 100%;
  padding: 100px 0px;
}
@media (max-width: 480px) {
  #homeBanner .bannerMain {
    display: flex;
    flex-direction: column;
    padding-top: 50px;
  }
}
#homeBanner .bannerMain .homeBannerThumbnailMob {
  display: none;
}
@media (max-width: 480px) {
  #homeBanner .bannerMain .homeBannerThumbnailMob {
    display: flex;
    width: 100%;
  }
  #homeBanner .bannerMain .homeBannerThumbnailMob img {
    width: 100%;
    height: 100%;
    -o-object-fit: contain;
       object-fit: contain;
  }
}
#homeBanner .bannerMain .bannerDetails {
  width: 50%;
  display: flex;
  flex-direction: column;
}
@media (max-width: 1440px) {
  #homeBanner .bannerMain .bannerDetails {
    width: 60%;
  }
}
@media (max-width: 1024px) {
  #homeBanner .bannerMain .bannerDetails {
    width: 65%;
  }
}
@media (max-width: 480px) {
  #homeBanner .bannerMain .bannerDetails {
    width: 100%;
    margin-top: 30px;
    justify-content: center;
    align-items: center;
  }
}
#homeBanner .bannerMain .bannerDetails h1 {
  font-size: 5.5em;
}
@media (max-width: 1350px) {
  #homeBanner .bannerMain .bannerDetails h1 {
    font-size: 5em;
  }
}
@media (max-width: 1220px) {
  #homeBanner .bannerMain .bannerDetails h1 {
    font-size: 4.5em;
  }
}
@media (max-width: 1024px) {
  #homeBanner .bannerMain .bannerDetails h1 {
    font-size: 4em;
  }
}
@media (max-width: 480px) {
  #homeBanner .bannerMain .bannerDetails h1 {
    font-size: 2.5em;
    text-align: center;
  }
}
#homeBanner .bannerMain .bannerDetails .homeBannerBtn {
  text-decoration: none;
  background: var(--primaryColor);
  color: white;
  width: -moz-fit-content;
  width: fit-content;
  padding: 16px 30px;
  font-size: 20px;
  margin-top: 40px;
  border-radius: 10px;
  transition: 0.3s;
}
#homeBanner .bannerMain .bannerDetails .homeBannerBtn:hover {
  transition: 0.3s;
  background: var(--secondaryColor);
}
#homeBanner svg {
  position: absolute;
  top: 300px;
  left: 0;
  z-index: -2;
}
@media (max-width: 480px) {
  #homeBanner svg {
    display: none;
  }
}
#homeBanner .homeBannerThumbnail {
  position: absolute;
  right: 0%;
  top: 50px;
  width: 50%;
  height: 70vh;
  z-index: -1;
}
@media (max-width: 1024px) {
  #homeBanner .homeBannerThumbnail {
    right: -20%;
    width: 70%;
  }
}
@media (max-width: 768px) {
  #homeBanner .homeBannerThumbnail {
    right: -30%;
  }
}
@media (max-width: 480px) {
  #homeBanner .homeBannerThumbnail {
    display: none;
  }
}
#homeBanner .homeBannerThumbnail img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
#homeBanner .homeBannerService {
  width: 100%;
  margin-top: 200px;
  display: flex;
  flex-direction: column;
}
@media (max-width: 480px) {
  #homeBanner .homeBannerService {
    margin-top: 0;
  }
}
#homeBanner .homeBannerService h2 {
  font-size: 3.5em;
  font-weight: 800;
}
@media (max-width: 1800px) {
  #homeBanner .homeBannerService h2 {
    font-size: 3em;
  }
}
@media (max-width: 768px) {
  #homeBanner .homeBannerService h2 {
    font-size: 2.5em;
  }
}
#homeBanner .homeBannerService p {
  font-size: 20px;
  text-align: left;
  margin-top: 20px;
  line-height: 1.5;
  width: 45%;
  font-weight: 600;
}
@media (max-width: 1800px) {
  #homeBanner .homeBannerService p {
    width: 60%;
    font-size: 18px;
  }
}
@media (max-width: 768px) {
  #homeBanner .homeBannerService p {
    width: 100%;
    font-size: 16px;
  }
}
#homeBanner .homeBannerService .homeBannerServiceList {
  width: 100%;
  margin-top: 100px;
  display: flex;
  flex-wrap: wrap;
  -moz-column-gap: 100px;
       column-gap: 100px;
  row-gap: 50px;
}
@media (max-width: 1440px) {
  #homeBanner .homeBannerService .homeBannerServiceList {
    -moz-column-gap: 60px;
         column-gap: 60px;
  }
}
@media (max-width: 480px) {
  #homeBanner .homeBannerService .homeBannerServiceList {
    row-gap: 30px;
  }
}
#homeBanner .homeBannerService .homeBannerServiceList .homeBannerServiceBox {
  width: calc(33.3333333333% - 67px);
  display: flex;
  flex-direction: column;
  text-decoration: none;
  cursor: pointer;
  transition: 0.3s;
}
#homeBanner .homeBannerService .homeBannerServiceList .homeBannerServiceBox:hover {
  transition: 0.3s;
  transform: translateY(-10px);
}
@media (max-width: 1440px) {
  #homeBanner .homeBannerService .homeBannerServiceList .homeBannerServiceBox {
    width: calc(33.3333333333% - 40px);
  }
}
@media (max-width: 1024px) {
  #homeBanner .homeBannerService .homeBannerServiceList .homeBannerServiceBox {
    width: calc(50% - 30px);
  }
}
@media (max-width: 480px) {
  #homeBanner .homeBannerService .homeBannerServiceList .homeBannerServiceBox {
    width: 100%;
  }
}
#homeBanner .homeBannerService .homeBannerServiceList .homeBannerServiceBox .homeBannerServiceBoxHead {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 20px;
}
#homeBanner .homeBannerService .homeBannerServiceList .homeBannerServiceBox .homeBannerServiceBoxHead .homeBannerServiceBoxIcon {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background: var(--secondaryColor);
  display: flex;
  justify-content: center;
  align-items: center;
}
#homeBanner .homeBannerService .homeBannerServiceList .homeBannerServiceBox .homeBannerServiceBoxHead .homeBannerServiceBoxIcon img {
  width: 55%;
  height: 55%;
  -o-object-fit: contain;
     object-fit: contain;
  filter: invert(1);
}
#homeBanner .homeBannerService .homeBannerServiceList .homeBannerServiceBox .homeBannerServiceBoxHead h3 {
  color: var(--primaryColor);
  font-size: 22px;
  width: calc(100% - 90px);
}
#homeBanner .homeBannerService .homeBannerServiceList .homeBannerServiceBox .homeBannerServiceBoxBody {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  overflow: hidden;
  height: 0;
  visibility: hidden;
  opacity: 0;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.0549019608);
  padding: 0px;
  border-radius: 10px;
}
#homeBanner .homeBannerService .homeBannerServiceList .homeBannerServiceBox .homeBannerServiceBoxBody p {
  font-size: 16px;
  width: 100%;
  font-weight: 400;
  margin-top: 0;
}

#homeAbout {
  width: 100%;
  background: var(--lightBgColor);
  position: relative;
  margin-top: 100px;
}
#homeAbout .homeAboutHeadOverlay {
  width: 100%;
  display: flex;
  align-items: center;
  height: 80px;
  background: white;
}
#homeAbout .homeAboutHeadOverlay .homeAboutHeadOverlayBox1 {
  width: 65%;
  height: 100%;
  border-top-right-radius: 50px;
  background: var(--lightBgColor);
}
#homeAbout .homeAboutHeadOverlay .homeAboutHeadOverlayBox2 {
  width: 35%;
  height: 100%;
  background: var(--lightBgColor);
}
#homeAbout .homeAboutHeadOverlay .homeAboutHeadOverlayBox2 .homeAboutHeadOverlayBox2Layer {
  background: white;
  width: 100%;
  height: 100%;
  border-bottom-left-radius: 50px;
}
#homeAbout .homeAboutMain {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 100px;
}
#homeAbout .homeAboutMain .homeAboutDetails {
  width: 100%;
  display: flex;
  flex-direction: column;
}
#homeAbout .homeAboutMain .homeAboutDetails h2 {
  text-align: left;
  font-size: 3.5em;
  line-height: 1.3;
  width: 70%;
}
@media (max-width: 1800px) {
  #homeAbout .homeAboutMain .homeAboutDetails h2 {
    width: 90%;
  }
}
@media (max-width: 1024px) {
  #homeAbout .homeAboutMain .homeAboutDetails h2 {
    font-size: 3em;
    width: 100%;
  }
}
@media (max-width: 768px) {
  #homeAbout .homeAboutMain .homeAboutDetails h2 {
    font-size: 2.7em;
  }
}
@media (max-width: 480px) {
  #homeAbout .homeAboutMain .homeAboutDetails h2 {
    font-size: 2.5em;
  }
}
#homeAbout .homeAboutMain .homeAboutDetails p {
  font-size: 20px;
  text-align: left;
  margin-top: 30px;
  line-height: 1.5;
  width: 85%;
}
@media (max-width: 1800px) {
  #homeAbout .homeAboutMain .homeAboutDetails p {
    width: 100%;
  }
}
@media (max-width: 768px) {
  #homeAbout .homeAboutMain .homeAboutDetails p {
    font-size: 18px;
  }
}
#homeAbout .homeAboutMain .homeAboutPoints {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 50px;
}
#homeAbout .homeAboutMain .homeAboutPoints .homeAboutPointsBox {
  width: calc(33.3333333333% - 15px);
  background: white;
  display: flex;
  flex-direction: column;
  padding: 40px 30px 30px 30px;
}
@media (max-width: 768px) {
  #homeAbout .homeAboutMain .homeAboutPoints .homeAboutPointsBox {
    width: calc(50% - 10px);
  }
}
@media (max-width: 480px) {
  #homeAbout .homeAboutMain .homeAboutPoints .homeAboutPointsBox {
    width: 100%;
    border-radius: 30px;
  }
}
#homeAbout .homeAboutMain .homeAboutPoints .homeAboutPointsBox:nth-of-type(1) {
  border-radius: 50px 50px 4px 50px;
}
@media (max-width: 480px) {
  #homeAbout .homeAboutMain .homeAboutPoints .homeAboutPointsBox:nth-of-type(1) {
    border-radius: 30px;
  }
}
#homeAbout .homeAboutMain .homeAboutPoints .homeAboutPointsBox:nth-of-type(1) .homeAboutPointsBoxIcon::after {
  background: #fece00;
}
#homeAbout .homeAboutMain .homeAboutPoints .homeAboutPointsBox:nth-of-type(1) .homeAboutPointsBoxIcon::before {
  background: rgba(254, 207, 0, 0.3450980392);
}
#homeAbout .homeAboutMain .homeAboutPoints .homeAboutPointsBox:nth-of-type(2) {
  border-radius: 50px 50px 50px 4px;
}
@media (max-width: 480px) {
  #homeAbout .homeAboutMain .homeAboutPoints .homeAboutPointsBox:nth-of-type(2) {
    border-radius: 30px;
  }
}
#homeAbout .homeAboutMain .homeAboutPoints .homeAboutPointsBox:nth-of-type(2) .homeAboutPointsBoxIcon::after {
  background: #ffbfd0;
}
#homeAbout .homeAboutMain .homeAboutPoints .homeAboutPointsBox:nth-of-type(2) .homeAboutPointsBoxIcon::before {
  background: rgba(255, 191, 208, 0.3254901961);
}
#homeAbout .homeAboutMain .homeAboutPoints .homeAboutPointsBox:nth-of-type(3) {
  border-radius: 50px 4px 50px 50px;
}
@media (max-width: 480px) {
  #homeAbout .homeAboutMain .homeAboutPoints .homeAboutPointsBox:nth-of-type(3) {
    border-radius: 30px;
  }
}
#homeAbout .homeAboutMain .homeAboutPoints .homeAboutPointsBox:nth-of-type(3) .homeAboutPointsBoxIcon::after {
  background: #81e3b1;
}
#homeAbout .homeAboutMain .homeAboutPoints .homeAboutPointsBox:nth-of-type(3) .homeAboutPointsBoxIcon::before {
  background: rgba(129, 227, 176, 0.3450980392);
}
#homeAbout .homeAboutMain .homeAboutPoints .homeAboutPointsBox:nth-of-type(4) {
  border-radius: 4px 50px 50px 50px;
}
@media (max-width: 480px) {
  #homeAbout .homeAboutMain .homeAboutPoints .homeAboutPointsBox:nth-of-type(4) {
    border-radius: 30px;
  }
}
#homeAbout .homeAboutMain .homeAboutPoints .homeAboutPointsBox:nth-of-type(4) .homeAboutPointsBoxIcon::after {
  background: #afd9e3;
}
#homeAbout .homeAboutMain .homeAboutPoints .homeAboutPointsBox:nth-of-type(4) .homeAboutPointsBoxIcon::before {
  background: rgba(175, 217, 227, 0.3294117647);
}
#homeAbout .homeAboutMain .homeAboutPoints .homeAboutPointsBox .homeAboutPointsBoxIcon {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: #eee;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 0;
}
#homeAbout .homeAboutMain .homeAboutPoints .homeAboutPointsBox .homeAboutPointsBoxIcon svg {
  font-size: 25px;
}
#homeAbout .homeAboutMain .homeAboutPoints .homeAboutPointsBox .homeAboutPointsBoxIcon::after {
  position: absolute;
  content: "";
  width: 60px;
  height: 60px;
  border-radius: 50%;
  z-index: -1;
  background: #eee;
}
#homeAbout .homeAboutMain .homeAboutPoints .homeAboutPointsBox .homeAboutPointsBoxIcon::before {
  position: absolute;
  content: "";
  width: 80px;
  height: 80px;
  border-radius: 50%;
  z-index: -2;
  background: rgba(238, 238, 238, 0.7098039216);
}
#homeAbout .homeAboutMain .homeAboutPoints .homeAboutPointsBox h3 {
  font-size: 22px;
  margin-top: 30px;
  line-height: 1.3;
}
#homeAbout .homeAboutMain .homeAboutPoints .homeAboutPointsBox p {
  font-size: 16px;
  margin-top: 15px;
  line-height: 1.3;
  opacity: 0.7;
}
#homeAbout .homeAboutFooterOverlay {
  width: 100%;
  display: flex;
  align-items: center;
  height: 80px;
  background: white;
  margin-top: 100px;
}
#homeAbout .homeAboutFooterOverlay .homeAboutFooterOverlayBox1 {
  width: 35%;
  height: 100%;
  background: var(--lightBgColor);
}
#homeAbout .homeAboutFooterOverlay .homeAboutFooterOverlayBox1 .homeAboutFooterOverlayBox1Layer {
  background: white;
  width: 100%;
  height: 100%;
  border-top-right-radius: 50px;
}
#homeAbout .homeAboutFooterOverlay .homeAboutFooterOverlayBox2 {
  width: 65%;
  height: 100%;
  background: var(--lightBgColor);
  border-bottom-left-radius: 50px;
}

#homeService {
  width: 100%;
  padding: 100px 0px;
}
#homeService .homeServiceTitle {
  width: 100%;
  display: flex;
  flex-direction: column;
}
#homeService .homeServiceTitle h2 {
  text-align: left;
  font-size: 3.5em;
  line-height: 1.3;
}
@media (max-width: 1024px) {
  #homeService .homeServiceTitle h2 {
    font-size: 3em;
  }
}
@media (max-width: 768px) {
  #homeService .homeServiceTitle h2 {
    font-size: 2.7em;
  }
}
@media (max-width: 480px) {
  #homeService .homeServiceTitle h2 {
    font-size: 2.5em;
  }
}
#homeService .homeServiceTitle p {
  font-size: 20px;
  text-align: left;
  margin-top: 10px;
  line-height: 1.5;
  width: 50%;
}
@media (max-width: 1800px) {
  #homeService .homeServiceTitle p {
    width: 60%;
  }
}
@media (max-width: 1024px) {
  #homeService .homeServiceTitle p {
    width: 90%;
  }
}
@media (max-width: 768px) {
  #homeService .homeServiceTitle p {
    font-size: 18px;
  }
}
@media (max-width: 480px) {
  #homeService .homeServiceTitle p {
    width: 100%;
  }
}
#homeService .homeServiceMain {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 40px;
}
#homeService .homeServiceMain .homeServiceBox {
  width: calc(33.3333333333% - 14px);
  display: flex;
  border: 1px solid #eee;
  border-radius: 20px;
  overflow: hidden;
  text-decoration: none;
}
@media (max-width: 1024px) {
  #homeService .homeServiceMain .homeServiceBox {
    width: calc(50% - 10px);
  }
}
@media (max-width: 768px) {
  #homeService .homeServiceMain .homeServiceBox {
    width: 100%;
  }
}
@media (max-width: 480px) {
  #homeService .homeServiceMain .homeServiceBox {
    flex-direction: column;
  }
}
#homeService .homeServiceMain .homeServiceBox .homeServiceBoxThumbnail {
  width: 40%;
  height: 180px;
  background: #eee;
  overflow: hidden;
}
@media (max-width: 1800px) {
  #homeService .homeServiceMain .homeServiceBox .homeServiceBoxThumbnail {
    width: 30%;
  }
}
@media (max-width: 480px) {
  #homeService .homeServiceMain .homeServiceBox .homeServiceBoxThumbnail {
    width: 100%;
    height: 180px;
  }
}
#homeService .homeServiceMain .homeServiceBox .homeServiceBoxThumbnail span {
  width: 100%;
  height: 100%;
}
#homeService .homeServiceMain .homeServiceBox .homeServiceBoxThumbnail img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
#homeService .homeServiceMain .homeServiceBox .homeServiceBoxDetails {
  width: 60%;
  padding: 20px;
  display: flex;
  flex-direction: column;
}
@media (max-width: 1800px) {
  #homeService .homeServiceMain .homeServiceBox .homeServiceBoxDetails {
    width: 70%;
  }
}
@media (max-width: 480px) {
  #homeService .homeServiceMain .homeServiceBox .homeServiceBoxDetails {
    width: 100%;
  }
}
#homeService .homeServiceMain .homeServiceBox .homeServiceBoxDetails h3 {
  font-size: 22px;
  text-align: left;
  color: #343434;
}
@media (max-width: 480px) {
  #homeService .homeServiceMain .homeServiceBox .homeServiceBoxDetails h3 {
    font-size: 24px;
  }
}
#homeService .homeServiceMain .homeServiceBox .homeServiceBoxDetails p {
  font-size: 16px;
  margin-top: 15px;
  line-height: 1.3;
  text-align: left;
  opacity: 0.7;
  color: #343434;
}
@media (max-width: 768px) {
  #homeService .homeServiceMain .homeServiceBox .homeServiceBoxDetails p {
    width: 80%;
  }
}
@media (max-width: 480px) {
  #homeService .homeServiceMain .homeServiceBox .homeServiceBoxDetails p {
    width: 100%;
    font-size: 18px;
  }
}
#homeService .homeServiceMain .homeServiceBox .homeServiceBoxDetails .homeServiceBoxBtn {
  text-decoration: none;
  border: 1px solid var(--primaryColor);
  color: var(--primaryColor);
  font-size: 16px;
  border-radius: 50px;
  padding: 8px 26px;
  transition: 0.3s;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: 20px;
  background: white;
}
@media (max-width: 1800px) {
  #homeService .homeServiceMain .homeServiceBox .homeServiceBoxDetails .homeServiceBoxBtn {
    font-size: 16px;
  }
}
#homeService .homeServiceMain .homeServiceBox .homeServiceBoxDetails .homeServiceBoxBtn:hover {
  transition: 0.3s;
  border-color: var(--secondaryColor);
  background: var(--secondaryColor);
  color: white;
}
#homeService .homeServiceMain .loaded .homeServiceBoxThumbnail {
  position: relative;
  z-index: 0;
}
#homeService .homeServiceMain .loaded .homeServiceBoxThumbnail::after {
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  top: 0;
  left: -100%;
  z-index: -1;
  background: #dedede;
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.1411764706);
  filter: blur(15px);
  opacity: 0.5;
  animation: lazyLoading1 1.5s linear infinite;
}
@keyframes lazyLoading1 {
  0% {
    left: -100%;
  }
  100% {
    left: 100%;
  }
}
#homeService .homeServiceMain .loaded .homeServiceBoxThumbnail img {
  display: none;
}
#homeService .homeServiceMain .loaded .homeServiceBoxDetails h3 {
  position: relative;
  overflow: hidden;
}
#homeService .homeServiceMain .loaded .homeServiceBoxDetails h3::after {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  background: #eee;
  border-radius: 5px;
  z-index: 1;
  left: 0;
  top: 0;
}
#homeService .homeServiceMain .loaded .homeServiceBoxDetails h3::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  background: #eee;
  border-radius: 5px;
  z-index: 2;
  left: 0;
  top: 0;
  background: #dedede;
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.1411764706);
  filter: blur(15px);
  opacity: 0.5;
  animation: lazyLoading2 1.5s linear infinite;
}
@keyframes lazyLoading2 {
  0% {
    left: -100%;
  }
  100% {
    left: 100%;
  }
}
#homeService .homeServiceMain .loaded .homeServiceBoxDetails p {
  position: relative;
  overflow: hidden;
}
#homeService .homeServiceMain .loaded .homeServiceBoxDetails p::after {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  background: #eee;
  border-radius: 5px;
  z-index: 1;
  left: 0;
  top: 0;
}
#homeService .homeServiceMain .loaded .homeServiceBoxDetails p::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  background: #eee;
  border-radius: 5px;
  z-index: 2;
  left: 0;
  top: 0;
  background: #dedede;
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.1411764706);
  filter: blur(15px);
  opacity: 0.5;
  animation: lazyLoading3 1.5s linear infinite;
}
@keyframes lazyLoading3 {
  0% {
    left: -100%;
  }
  100% {
    left: 100%;
  }
}
#homeService .homeServiceMain .loaded .homeServiceBoxDetails .homeServiceBoxBtn {
  position: relative;
  overflow: hidden;
  border-color: #eee;
}
#homeService .homeServiceMain .loaded .homeServiceBoxDetails .homeServiceBoxBtn::after {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  background: #eee;
  border-radius: 5px;
  z-index: 1;
  left: 0;
  top: 0;
}
#homeService .homeServiceMain .loaded .homeServiceBoxDetails .homeServiceBoxBtn::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  background: #eee;
  border-radius: 5px;
  z-index: 2;
  left: 0;
  top: 0;
  background: #dedede;
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.1411764706);
  filter: blur(15px);
  opacity: 0.5;
  animation: lazyLoading2 1.5s linear infinite;
}
@keyframes lazyLoading2 {
  0% {
    left: -100%;
  }
  100% {
    left: 100%;
  }
}
#homeService .homeBannerServiceList {
  width: 100%;
  margin-top: 100px;
  display: flex;
  flex-wrap: wrap;
  -moz-column-gap: 100px;
       column-gap: 100px;
  row-gap: 50px;
}
@media (max-width: 1440px) {
  #homeService .homeBannerServiceList {
    -moz-column-gap: 60px;
         column-gap: 60px;
  }
}
@media (max-width: 480px) {
  #homeService .homeBannerServiceList {
    row-gap: 30px;
  }
}
#homeService .homeBannerServiceList .homeBannerServiceBox {
  width: calc(33.3333333333% - 67px);
  display: flex;
  flex-direction: column;
  text-decoration: none;
  cursor: pointer;
}
@media (max-width: 1440px) {
  #homeService .homeBannerServiceList .homeBannerServiceBox {
    width: calc(33.3333333333% - 40px);
  }
}
@media (max-width: 1024px) {
  #homeService .homeBannerServiceList .homeBannerServiceBox {
    width: calc(50% - 30px);
  }
}
@media (max-width: 480px) {
  #homeService .homeBannerServiceList .homeBannerServiceBox {
    width: 100%;
  }
}
#homeService .homeBannerServiceList .homeBannerServiceBox .homeBannerServiceBoxHead {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 20px;
}
#homeService .homeBannerServiceList .homeBannerServiceBox .homeBannerServiceBoxHead .homeBannerServiceBoxIcon {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background: var(--secondaryColor);
  display: flex;
  justify-content: center;
  align-items: center;
}
#homeService .homeBannerServiceList .homeBannerServiceBox .homeBannerServiceBoxHead .homeBannerServiceBoxIcon img {
  width: 55%;
  height: 55%;
  -o-object-fit: contain;
     object-fit: contain;
  filter: invert(1);
}
#homeService .homeBannerServiceList .homeBannerServiceBox .homeBannerServiceBoxHead h3 {
  color: var(--primaryColor);
  font-size: 22px;
  width: calc(100% - 90px);
}
#homeService .homeBannerServiceList .homeBannerServiceBox .homeBannerServiceBoxBody {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  overflow: hidden;
  height: 0;
  visibility: hidden;
  opacity: 0;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.0549019608);
  padding: 0px;
  border-radius: 10px;
}
#homeService .homeBannerServiceList .homeBannerServiceBox .homeBannerServiceBoxBody p {
  font-size: 16px;
  width: 100%;
  font-weight: 400;
  margin-top: 0;
}

.ServicePageStrip {
  width: 100%;
  margin-top: 80px;
}
@media (max-width: 480px) {
  .ServicePageStrip {
    margin-top: 70px;
  }
}

#HomeBlog {
  width: 100%;
  position: relative;
  padding: 100px 0px;
}
@media (max-width: 480px) {
  #HomeBlog {
    padding-top: 0;
  }
}
#HomeBlog .HomeBlogHeadOverlay {
  width: 100%;
  display: none;
  align-items: center;
  height: 80px;
  background: white;
}
#HomeBlog .HomeBlogHeadOverlay .HomeBlogHeadOverlayBox1 {
  width: 65%;
  height: 100%;
  border-top-right-radius: 50px;
  background: var(--lightBgColor);
}
#HomeBlog .HomeBlogHeadOverlay .HomeBlogHeadOverlayBox2 {
  width: 35%;
  height: 100%;
  background: var(--lightBgColor);
}
#HomeBlog .HomeBlogHeadOverlay .HomeBlogHeadOverlayBox2 .HomeBlogHeadOverlayBox2Layer {
  background: white;
  width: 100%;
  height: 100%;
  border-bottom-left-radius: 50px;
}
#HomeBlog .homeBlogTitle {
  width: 100%;
  display: flex;
  flex-direction: column;
}
#HomeBlog .homeBlogTitle h2 {
  text-align: left;
  font-size: 3.5em;
  line-height: 1.3;
  width: 60%;
}
@media (max-width: 1024px) {
  #HomeBlog .homeBlogTitle h2 {
    font-size: 3em;
    width: 100%;
    margin-top: 20px;
  }
}
@media (max-width: 768px) {
  #HomeBlog .homeBlogTitle h2 {
    font-size: 2.7em;
  }
}
@media (max-width: 480px) {
  #HomeBlog .homeBlogTitle h2 {
    font-size: 2.5em;
  }
}
#HomeBlog .homeBlogTitle p {
  font-size: 20px;
  text-align: left;
  margin-top: 10px;
  line-height: 1.5;
  width: 50%;
}
@media (max-width: 1800px) {
  #HomeBlog .homeBlogTitle p {
    width: 60%;
  }
}
@media (max-width: 1024px) {
  #HomeBlog .homeBlogTitle p {
    width: 100%;
  }
}
@media (max-width: 768px) {
  #HomeBlog .homeBlogTitle p {
    font-size: 18px;
  }
}
#HomeBlog .homeBlogTitle a {
  text-decoration: none;
  border: 1px solid var(--primaryColor);
  color: var(--primaryColor);
  font-size: 18px;
  border-radius: 50px;
  padding: 12px 30px;
  transition: 0.3s;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: 40px;
  background: white;
}
@media (max-width: 1800px) {
  #HomeBlog .homeBlogTitle a {
    font-size: 16px;
  }
}
#HomeBlog .homeBlogTitle a:hover {
  transition: 0.3s;
  border-color: var(--secondaryColor);
  background: var(--secondaryColor);
  color: white;
}
#HomeBlog .HomeBlogMain {
  width: 100%;
  margin-top: 80px;
  /* main.css */
}
#HomeBlog .HomeBlogMain .HomeBlogBox {
  width: 100%;
  display: flex;
  flex-direction: column;
  background: white;
  overflow: hidden;
  text-decoration: none;
  border-radius: 10px;
  transition: 0.3s;
}
#HomeBlog .HomeBlogMain .HomeBlogBox:hover {
  transition: 0.3s;
  transform: scale(0.95);
}
#HomeBlog .HomeBlogMain .HomeBlogBox:hover .HomeBlogThumbnail img {
  transform: scale(1.05);
  transition: 0.3s;
}
#HomeBlog .HomeBlogMain .HomeBlogBox .HomeBlogThumbnail {
  width: 100%;
  height: 200px;
  background: #eee;
  overflow: hidden;
  border-radius: 10px;
}
@media (max-width: 1800px) {
  #HomeBlog .HomeBlogMain .HomeBlogBox .HomeBlogThumbnail {
    height: 180px;
  }
}
#HomeBlog .HomeBlogMain .HomeBlogBox .HomeBlogThumbnail img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  transition: 0.3s;
}
#HomeBlog .HomeBlogMain .HomeBlogBox .HomeBlogDetails {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}
#HomeBlog .HomeBlogMain .HomeBlogBox .HomeBlogDetails .homeBlogDate {
  text-align: left;
  color: var(--secondaryColor);
}
#HomeBlog .HomeBlogMain .HomeBlogBox .HomeBlogDetails h3 {
  color: #343434;
  text-align: left;
  font-size: 25px;
  margin-top: 15px;
  line-height: 1.3;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
@media (max-width: 1800px) {
  #HomeBlog .HomeBlogMain .HomeBlogBox .HomeBlogDetails h3 {
    font-size: 22px;
  }
}
#HomeBlog .HomeBlogMain .HomeBlogBox .HomeBlogDetails p {
  font-size: 16px;
  margin-top: 15px;
  line-height: 1.5;
  text-align: left;
  opacity: 0.7;
  color: #343434;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
@media (max-width: 1800px) {
  #HomeBlog .HomeBlogMain .HomeBlogBox .HomeBlogDetails p {
    margin-top: 10px;
  }
}
#HomeBlog .HomeBlogMain .loaded .HomeBlogThumbnail {
  position: relative;
  z-index: 0;
}
#HomeBlog .HomeBlogMain .loaded .HomeBlogThumbnail::after {
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  top: 0;
  left: -100%;
  z-index: -1;
  background: #dedede;
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.1411764706);
  filter: blur(15px);
  opacity: 0.5;
  animation: lazyLoading1 1.5s linear infinite;
}
@keyframes lazyLoading1 {
  0% {
    left: -100%;
  }
  100% {
    left: 100%;
  }
}
#HomeBlog .HomeBlogMain .loaded .HomeBlogThumbnail img {
  display: none;
}
#HomeBlog .HomeBlogMain .loaded .HomeBlogDetails h3 {
  position: relative;
  overflow: hidden;
}
#HomeBlog .HomeBlogMain .loaded .HomeBlogDetails h3::after {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  background: #eee;
  border-radius: 5px;
  z-index: 1;
  left: 0;
  top: 0;
}
#HomeBlog .HomeBlogMain .loaded .HomeBlogDetails h3::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  background: #eee;
  border-radius: 5px;
  z-index: 2;
  left: 0;
  top: 0;
  background: #dedede;
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.1411764706);
  filter: blur(15px);
  opacity: 0.5;
  animation: lazyLoading2 1.5s linear infinite;
}
@keyframes lazyLoading2 {
  0% {
    left: -100%;
  }
  100% {
    left: 100%;
  }
}
#HomeBlog .HomeBlogMain .loaded .HomeBlogDetails p {
  position: relative;
  overflow: hidden;
}
#HomeBlog .HomeBlogMain .loaded .HomeBlogDetails p::after {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  background: #eee;
  border-radius: 5px;
  z-index: 1;
  left: 0;
  top: 0;
}
#HomeBlog .HomeBlogMain .loaded .HomeBlogDetails p::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  background: #eee;
  border-radius: 5px;
  z-index: 2;
  left: 0;
  top: 0;
  background: #dedede;
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.1411764706);
  filter: blur(15px);
  opacity: 0.5;
  animation: lazyLoading3 1.5s linear infinite;
}
@keyframes lazyLoading3 {
  0% {
    left: -100%;
  }
  100% {
    left: 100%;
  }
}
#HomeBlog .HomeBlogMain .loaded .HomeBlogDetails .homeBlogDate {
  position: relative;
  overflow: hidden;
  border-color: #eee;
  width: -moz-fit-content;
  width: fit-content;
}
#HomeBlog .HomeBlogMain .loaded .HomeBlogDetails .homeBlogDate::after {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  background: #eee;
  border-radius: 5px;
  z-index: 1;
  left: 0;
  top: 0;
}
#HomeBlog .HomeBlogMain .loaded .HomeBlogDetails .homeBlogDate::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  background: #eee;
  border-radius: 5px;
  z-index: 2;
  left: 0;
  top: 0;
  background: #dedede;
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.1411764706);
  filter: blur(15px);
  opacity: 0.5;
  animation: lazyLoading2 1.5s linear infinite;
}
@keyframes lazyLoading2 {
  0% {
    left: -100%;
  }
  100% {
    left: 100%;
  }
}
#HomeBlog .HomeBlogMain .slick-slide {
  padding: 0 10px;
}
#HomeBlog .HomeBlogMain .slick-list {
  margin: 0 -10px;
}
#HomeBlog .HomeBlogMain .slick-prev:before,
#HomeBlog .HomeBlogMain .slick-next:before {
  content: "";
  display: none;
}
#HomeBlog .HomeBlogMain .slick-prev,
#HomeBlog .HomeBlogMain .slick-next {
  width: 30px;
  height: 30px;
  z-index: 1;
}
#HomeBlog .HomeBlogMain .slick-prev {
  left: -35px; /* Adjust positioning */
}
@media (max-width: 480px) {
  #HomeBlog .HomeBlogMain .slick-prev {
    left: 0px;
  }
}
#HomeBlog .HomeBlogMain .slick-next {
  right: -35px; /* Adjust positioning */
}
@media (max-width: 480px) {
  #HomeBlog .HomeBlogMain .slick-next {
    right: 0;
  }
}
#HomeBlog .HomeBlogMain .slick-prev,
#HomeBlog .HomeBlogMain .slick-next {
  background: var(--gradientBgColor);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
#HomeBlog .HomeBlogMain .slick-prev svg,
#HomeBlog .HomeBlogMain .slick-next svg {
  color: white;
  width: 60%;
  height: 60%;
}
#HomeBlog .HomeBlogMain .slick-prev:hover,
#HomeBlog .HomeBlogMain .slick-next:hover {
  background: white;
}
#HomeBlog .HomeBlogMain .slick-prev:hover svg,
#HomeBlog .HomeBlogMain .slick-next:hover svg {
  color: var(--primaryColor);
}
#HomeBlog .HomeBlogFooterOverlay {
  width: 100%;
  display: none;
  align-items: center;
  height: 80px;
  background: var(--lightBgColor);
  margin-top: 100px;
}
#HomeBlog .HomeBlogFooterOverlay .HomeBlogFooterOverlayBox1 {
  width: 35%;
  height: 100%;
  background: var(--lightBgColor);
}
#HomeBlog .HomeBlogFooterOverlay .HomeBlogFooterOverlayBox1 .HomeBlogFooterOverlayBox1Layer {
  background: var(--lightBgColor);
  width: 100%;
  height: 100%;
  border-top-right-radius: 50px;
}
#HomeBlog .HomeBlogFooterOverlay .HomeBlogFooterOverlayBox2 {
  width: 65%;
  height: 100%;
  background: var(--lightBgColor);
  border-bottom-left-radius: 50px;
}

#homeAboutSection {
  width: 100%;
  padding: 100px 0px;
}
#homeAboutSection .homeAboutSectionMain {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
}
#homeAboutSection .homeAboutSectionMain .homeAboutSectionThumbnail {
  flex: 0 0 45%;
  background: #eee;
  border-radius: 10px;
  overflow: hidden;
  border-radius: 20px;
  height: 80vh;
  position: relative;
}
@media (max-width: 1024px) {
  #homeAboutSection .homeAboutSectionMain .homeAboutSectionThumbnail {
    flex: 100%;
    height: 500px;
  }
}
@media (max-width: 768px) {
  #homeAboutSection .homeAboutSectionMain .homeAboutSectionThumbnail {
    height: 400px;
  }
}
#homeAboutSection .homeAboutSectionMain .homeAboutSectionThumbnail img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
#homeAboutSection .homeAboutSectionMain .homeAboutSectionThumbnail .homeAboutSectionThumbnailTitle {
  position: absolute;
  top: 20px;
  right: 20px;
  padding: 14px 50px;
  background: rgba(255, 255, 255, 0.239);
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}
#homeAboutSection .homeAboutSectionMain .homeAboutSectionThumbnail .homeAboutSectionThumbnailTitle h2 {
  font-size: 25px;
  color: white;
}
@media (max-width: 480px) {
  #homeAboutSection .homeAboutSectionMain .homeAboutSectionThumbnail .homeAboutSectionThumbnailTitle h2 {
    font-size: 20px;
  }
}
#homeAboutSection .homeAboutSectionMain .loaded {
  position: relative;
  z-index: 0;
}
#homeAboutSection .homeAboutSectionMain .loaded::after {
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  top: 0;
  left: -100%;
  z-index: -1;
  background: #dedede;
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.1411764706);
  filter: blur(15px);
  opacity: 0.5;
  animation: abtLazyLoading1 1.5s linear infinite;
}
@keyframes abtLazyLoading1 {
  0% {
    left: -100%;
  }
  100% {
    left: 100%;
  }
}
#homeAboutSection .homeAboutSectionMain .loaded img {
  display: none;
}
#homeAboutSection .homeAboutSectionMain .homeAboutSectionDetails {
  flex: 0 0 47%;
  display: flex;
  flex-direction: column;
}
@media (max-width: 1024px) {
  #homeAboutSection .homeAboutSectionMain .homeAboutSectionDetails {
    flex: 100%;
    margin-top: 40px;
  }
}
#homeAboutSection .homeAboutSectionMain .homeAboutSectionDetails h2 {
  text-align: left;
  font-size: 3.5em;
  line-height: 1.3;
}
@media (max-width: 1024px) {
  #homeAboutSection .homeAboutSectionMain .homeAboutSectionDetails h2 {
    font-size: 3em;
    width: 100%;
  }
}
@media (max-width: 768px) {
  #homeAboutSection .homeAboutSectionMain .homeAboutSectionDetails h2 {
    font-size: 2.7em;
  }
}
@media (max-width: 480px) {
  #homeAboutSection .homeAboutSectionMain .homeAboutSectionDetails h2 {
    font-size: 2.5em;
  }
}
#homeAboutSection .homeAboutSectionMain .homeAboutSectionDetails p {
  font-size: 20px;
  text-align: left;
  margin-top: 30px;
  line-height: 1.5;
  width: 90%;
}
@media (max-width: 1800px) {
  #homeAboutSection .homeAboutSectionMain .homeAboutSectionDetails p {
    width: 100%;
  }
}
@media (max-width: 768px) {
  #homeAboutSection .homeAboutSectionMain .homeAboutSectionDetails p {
    font-size: 18px;
  }
}
#homeAboutSection .homeAboutSectionMain .homeAboutSectionDetails ul {
  width: 100%;
  margin-top: 40px;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}
#homeAboutSection .homeAboutSectionMain .homeAboutSectionDetails ul li {
  list-style: none;
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  border: 1px solid #BFD8AF;
  width: 230px;
  padding: 30px 20px;
  position: relative;
  z-index: 0;
  overflow: hidden;
}
@media (max-width: 1800px) {
  #homeAboutSection .homeAboutSectionMain .homeAboutSectionDetails ul li {
    width: calc(33.3333333333% - 14px);
    padding: 20px;
  }
}
@media (max-width: 768px) {
  #homeAboutSection .homeAboutSectionMain .homeAboutSectionDetails ul li {
    width: calc(50% - 10px);
  }
}
@media (max-width: 480px) {
  #homeAboutSection .homeAboutSectionMain .homeAboutSectionDetails ul li {
    width: 100%;
  }
}
#homeAboutSection .homeAboutSectionMain .homeAboutSectionDetails ul li::after {
  position: absolute;
  content: "";
  width: 150px;
  height: 150px;
  border-radius: 50%;
  background: #BFD8AF;
  top: -85px;
  right: -85px;
  z-index: -1;
}
#homeAboutSection .homeAboutSectionMain .homeAboutSectionDetails ul li::before {
  position: absolute;
  content: "";
  width: 150px;
  height: 150px;
  border-radius: 50%;
  background: #E8EFCF;
  top: -45px;
  right: -75px;
  z-index: -2;
}
#homeAboutSection .homeAboutSectionMain .homeAboutSectionDetails ul li h3 {
  text-align: left;
  font-size: 2em;
  font-family: myFont2;
  background: var(--gradientBgColor);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
#homeAboutSection .homeAboutSectionMain .homeAboutSectionDetails ul li h4 {
  text-align: left;
  margin-top: 20px;
  font-size: 20px;
  font-family: myFont3;
}
@media (max-width: 1800px) {
  #homeAboutSection .homeAboutSectionMain .homeAboutSectionDetails ul li h4 {
    font-size: 16px;
  }
}
@media (max-width: 480px) {
  #homeAboutSection .homeAboutSectionMain .homeAboutSectionDetails ul li h4 {
    font-size: 20px;
  }
}
#homeAboutSection .homeAboutSectionMain .homeAboutSectionDetails a {
  text-decoration: none;
  border: 1px solid var(--secondaryColor);
  background: var(--secondaryColor);
  font-size: 18px;
  border-radius: 50px;
  padding: 12px 30px;
  transition: 0.3s;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: 40px;
  color: white;
}
@media (max-width: 1800px) {
  #homeAboutSection .homeAboutSectionMain .homeAboutSectionDetails a {
    font-size: 16px;
  }
}
#homeAboutSection .homeAboutSectionMain .homeAboutSectionDetails a:hover {
  transition: 0.3s;
  border-color: var(--thirdColor);
  background: var(--thirdColor);
  color: white;
}

#homeParallax {
  width: 100%;
  padding: 200px 0px 300px 0px;
  background-color: #eee;
  background-image: url(../images/whyChoos.webp);
  background-attachment: fixed;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  z-index: 0;
}
@media (max-width: 1800px) {
  #homeParallax {
    padding: 200px 0px;
  }
}
#homeParallax::after {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
  background: rgba(0, 0, 0, 0.586);
  -webkit-backdrop-filter: blur(3px);
          backdrop-filter: blur(3px);
}
@media (max-width: 480px) {
  #homeParallax::after {
    -webkit-backdrop-filter: blur(1px);
            backdrop-filter: blur(1px);
  }
}
#homeParallax .homeParallaxShape1 {
  position: absolute;
  top: -2px;
  right: -2px;
  width: 50%;
  z-index: 1;
}
@media (max-width: 768px) {
  #homeParallax .homeParallaxShape1 {
    width: 80%;
  }
}
@media (max-width: 480px) {
  #homeParallax .homeParallaxShape1 {
    width: 100%;
  }
}
#homeParallax .homeParallaxShape1 img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
#homeParallax .homeParallaxShape2 {
  position: absolute;
  bottom: -6px;
  left: -2px;
  width: 50%;
  z-index: 1;
}
@media (max-width: 768px) {
  #homeParallax .homeParallaxShape2 {
    width: 80%;
  }
}
@media (max-width: 480px) {
  #homeParallax .homeParallaxShape2 {
    width: 100%;
  }
}
#homeParallax .homeParallaxShape2 img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
#homeParallax .homeParallaxMain {
  width: 100%;
  display: flex;
  flex-direction: column;
}
#homeParallax .homeParallaxMain h2 {
  text-align: left;
  font-size: 3.5em;
  line-height: 1.3;
  width: 40%;
  color: white;
}
@media (max-width: 1800px) {
  #homeParallax .homeParallaxMain h2 {
    width: 60%;
  }
}
@media (max-width: 1024px) {
  #homeParallax .homeParallaxMain h2 {
    font-size: 3em;
    width: 80%;
  }
}
@media (max-width: 768px) {
  #homeParallax .homeParallaxMain h2 {
    font-size: 2.7em;
  }
}
@media (max-width: 480px) {
  #homeParallax .homeParallaxMain h2 {
    font-size: 2.5em;
    width: 100%;
  }
}
#homeParallax .homeParallaxMain p {
  font-size: 20px;
  text-align: left;
  margin-top: 10px;
  line-height: 1.5;
  width: 55%;
  color: white;
}
@media (max-width: 1024px) {
  #homeParallax .homeParallaxMain p {
    width: 80%;
  }
}
@media (max-width: 768px) {
  #homeParallax .homeParallaxMain p {
    font-size: 18px;
    width: 100%;
  }
}

#homeTestimonials {
  width: 100%;
  padding: 100px 0px;
  overflow: hidden;
}
#homeTestimonials .homeTestimonialsTitle {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
#homeTestimonials .homeTestimonialsTitle h2 {
  text-align: center;
  font-size: 3.5em;
  line-height: 1.3;
  width: 60%;
}
@media (max-width: 1800px) {
  #homeTestimonials .homeTestimonialsTitle h2 {
    width: 100%;
  }
}
@media (max-width: 1024px) {
  #homeTestimonials .homeTestimonialsTitle h2 {
    font-size: 3em;
    width: 100%;
  }
}
@media (max-width: 768px) {
  #homeTestimonials .homeTestimonialsTitle h2 {
    font-size: 2.7em;
  }
}
@media (max-width: 480px) {
  #homeTestimonials .homeTestimonialsTitle h2 {
    font-size: 2.5em;
  }
}
#homeTestimonials .homeTestimonialsTitle p {
  font-size: 20px;
  text-align: center;
  margin-top: 10px;
  line-height: 1.5;
  width: 50%;
}
@media (max-width: 1800px) {
  #homeTestimonials .homeTestimonialsTitle p {
    width: 60%;
  }
}
@media (max-width: 1024px) {
  #homeTestimonials .homeTestimonialsTitle p {
    width: 80%;
  }
}
@media (max-width: 768px) {
  #homeTestimonials .homeTestimonialsTitle p {
    font-size: 18px;
    width: 85%;
  }
}
@media (max-width: 480px) {
  #homeTestimonials .homeTestimonialsTitle p {
    width: 100%;
  }
}
#homeTestimonials .homeTestimonialsMain {
  width: 100%;
  margin-top: 80px;
  /* main.css */
}
#homeTestimonials .homeTestimonialsMain .slick-slide:nth-of-type(odd) .homeTestimonialsBoxDetails {
  background: #FEECE2;
}
#homeTestimonials .homeTestimonialsMain .slick-slide:nth-of-type(odd) .homeTestimonialsBoxDetails::after {
  background: #FEECE2;
}
#homeTestimonials .homeTestimonialsMain .slick-slide:nth-of-type(even) .homeTestimonialsBoxDetails {
  background: #E1F0DA;
}
#homeTestimonials .homeTestimonialsMain .slick-slide:nth-of-type(even) .homeTestimonialsBoxDetails::after {
  background: #E1F0DA;
}
#homeTestimonials .homeTestimonialsMain .homeTestimonialsBox {
  width: 100%;
  display: flex;
  flex-direction: column;
}
#homeTestimonials .homeTestimonialsMain .homeTestimonialsBox .homeTestimonialsBoxDetails {
  width: 100%;
  padding: 30px;
  border-radius: 20px;
  position: relative;
  margin-top: 20px;
  padding-top: 40px;
}
#homeTestimonials .homeTestimonialsMain .homeTestimonialsBox .homeTestimonialsBoxDetails .homeTestimonialsBoxImoji {
  position: absolute;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  top: -20px;
  left: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: bounceEmoji 1s linear infinite;
}
@keyframes bounceEmoji {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
#homeTestimonials .homeTestimonialsMain .homeTestimonialsBox .homeTestimonialsBoxDetails .homeTestimonialsBoxImoji img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
#homeTestimonials .homeTestimonialsMain .homeTestimonialsBox .homeTestimonialsBoxDetails::after {
  position: absolute;
  content: "";
  width: 16px;
  height: 16px;
  bottom: -8px;
  left: 30px;
  transform: rotate(45deg);
  border-radius: 0px 0px 5px 0px;
}
#homeTestimonials .homeTestimonialsMain .homeTestimonialsBox .homeTestimonialsBoxDetails p {
  font-size: 20px;
  line-height: 1.3;
  text-align: left;
}
#homeTestimonials .homeTestimonialsMain .homeTestimonialsBox .homeTestimonialsBoxProfile {
  width: 100%;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  padding: 0px 30px;
}
#homeTestimonials .homeTestimonialsMain .homeTestimonialsBox .homeTestimonialsBoxProfile h3 {
  font-size: 18px;
  text-align: left;
}
#homeTestimonials .homeTestimonialsMain .homeTestimonialsBox .homeTestimonialsBoxProfile h4 {
  font-size: 14px;
  text-align: left;
  margin-top: 4px;
  font-family: myFont3;
}
#homeTestimonials .homeTestimonialsMain .slick-slide {
  padding: 0 30px;
}
#homeTestimonials .homeTestimonialsMain .slick-list {
  margin: 0 -30px;
}
#homeTestimonials .homeTestimonialsMain .slick-prev:before,
#homeTestimonials .homeTestimonialsMain .slick-next:before {
  content: "";
  display: none;
}
#homeTestimonials .homeTestimonialsMain .slick-prev,
#homeTestimonials .homeTestimonialsMain .slick-next {
  width: 30px;
  height: 30px;
  z-index: 1;
}
#homeTestimonials .homeTestimonialsMain .slick-prev {
  left: -35px; /* Adjust positioning */
}
@media (max-width: 480px) {
  #homeTestimonials .homeTestimonialsMain .slick-prev {
    left: 0;
  }
}
#homeTestimonials .homeTestimonialsMain .slick-next {
  right: -35px; /* Adjust positioning */
}
@media (max-width: 480px) {
  #homeTestimonials .homeTestimonialsMain .slick-next {
    right: 0;
  }
}
#homeTestimonials .homeTestimonialsMain .slick-prev,
#homeTestimonials .homeTestimonialsMain .slick-next {
  background: var(--gradientBgColor);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
#homeTestimonials .homeTestimonialsMain .slick-prev svg,
#homeTestimonials .homeTestimonialsMain .slick-next svg {
  color: white;
  width: 60%;
  height: 60%;
}
#homeTestimonials .homeTestimonialsMain .slick-prev:hover,
#homeTestimonials .homeTestimonialsMain .slick-next:hover {
  background: var(--lightBgColor);
}
#homeTestimonials .homeTestimonialsMain .slick-prev:hover svg,
#homeTestimonials .homeTestimonialsMain .slick-next:hover svg {
  color: var(--primaryColor);
}

footer {
  width: 100%;
  padding: 0px 0px 50px 0px;
  background: #0d1427;
}
footer .footerHeadOverlay {
  width: 100%;
  display: flex;
  align-items: center;
  height: 80px;
  background: white;
  overflow: hidden;
}
footer .footerHeadOverlay .footerHeadOverlayBox1 {
  width: 60%;
  height: 100%;
  border-top-right-radius: 50px;
  background: #0d1427;
}
footer .footerHeadOverlay .footerHeadOverlayBox2 {
  width: 40%;
  height: 100%;
  background: #0d1427;
}
footer .footerHeadOverlay .footerHeadOverlayBox2 .footerHeadOverlayBox2Layer {
  background: white;
  width: 102%;
  height: 100%;
  margin-left: -2px;
  border-bottom-left-radius: 50px;
}
footer .footerMain {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 100px;
}
footer .footerMain .footerCol1 {
  flex: 0 0 30%;
  display: flex;
  flex-direction: column;
}
@media (max-width: 768px) {
  footer .footerMain .footerCol1 {
    flex: 100%;
  }
}
footer .footerMain .footerCol1 .footerLogo {
  width: 250px;
}
footer .footerMain .footerCol1 .footerLogo img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
}
footer .footerMain .footerCol1 p {
  font-size: 16px;
  margin-top: 20px;
  text-align: left;
  color: white;
  opacity: 0.7;
  line-height: 1.5;
}
footer .footerMain .footerCol1 .footerTiming {
  width: 100%;
  margin-top: 40px;
  display: flex;
  flex-direction: column;
}
footer .footerMain .footerCol1 .footerTiming span {
  font-size: 16px;
  color: var(--primaryColor);
  text-align: left;
}
footer .footerMain .footerCol1 .footerTiming ul {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  gap: 15px;
}
footer .footerMain .footerCol1 .footerTiming ul li {
  list-style: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
footer .footerMain .footerCol1 .footerTiming ul li .footerTimingCol1 {
  flex: 0 0 35%;
  font-size: 16px;
  color: white;
  text-align: left;
}
footer .footerMain .footerCol1 .footerTiming ul li .footerTimingCol2 {
  flex: 0 0 60%;
  font-size: 16px;
  color: white;
  text-align: left;
}
footer .footerMain .footerCol1 .footerSocialLinks {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-top: 40px;
  flex-wrap: wrap;
}
footer .footerMain .footerCol1 .footerSocialLinks span {
  font-size: 16px;
  color: var(--primaryColor);
  text-align: left;
  width: 100%;
}
footer .footerMain .footerCol1 .footerSocialLinks a {
  text-decoration: none;
  border-radius: 10px;
  font-size: 22px;
  width: 40px;
  height: 40px;
  color: white;
  position: relative;
  z-index: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--gradientBgColor);
}
footer .footerMain .footerCol1 .footerSocialLinks a:hover::before {
  opacity: 0;
  transition: 0.3s;
}
footer .footerMain .footerCol1 .footerSocialLinks a::after {
  position: absolute;
  content: "";
  width: calc(100% + 6px);
  height: calc(100% + 6px);
  background: var(--gradientBgColor);
  z-index: -2;
  border-radius: 10px;
}
footer .footerMain .footerCol1 .footerSocialLinks a::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  background: #0d1427;
  z-index: -1;
  border-radius: 8px;
  transition: 0.3s;
}
footer .footerMain .footerCol2 {
  flex: 0 0 30%;
  display: flex;
  flex-direction: column;
}
@media (max-width: 768px) {
  footer .footerMain .footerCol2 {
    flex: 100%;
    margin-top: 50px;
  }
}
footer .footerMain .footerCol2 h2 {
  color: white;
  font-size: 22px;
  text-align: left;
}
footer .footerMain .footerCol2 ul {
  width: 100%;
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  row-gap: 20px;
}
footer .footerMain .footerCol2 ul li {
  flex: 0 0 48%;
  list-style: none;
  display: flex;
  align-items: center;
}
@media (max-width: 1200px) {
  footer .footerMain .footerCol2 ul li {
    flex: 100%;
  }
  footer .footerMain .footerCol2 ul li:nth-of-type(2) {
    order: 5;
  }
  footer .footerMain .footerCol2 ul li:nth-of-type(4) {
    order: 6;
  }
}
footer .footerMain .footerCol2 ul li span {
  width: 8px;
  height: 8px;
  background: var(--gradientBgColor);
  border-radius: 10px;
  margin-right: 10px;
}
footer .footerMain .footerCol2 ul li a {
  text-decoration: none;
  color: white;
  font-size: 18px;
  text-align: left;
}
footer .footerMain .footerCol2 ul li a:hover {
  text-decoration: underline;
}
footer .footerMain .footerCol2 .footerTiming {
  width: 100%;
  margin-top: 40px;
  display: flex;
  flex-direction: column;
}
footer .footerMain .footerCol2 .footerTiming span {
  font-size: 16px;
  color: var(--primaryColor);
  text-align: left;
}
footer .footerMain .footerCol2 .footerTiming ul {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
  gap: 15px;
}
footer .footerMain .footerCol2 .footerTiming ul li {
  flex: 100% !important;
  list-style: none;
  display: flex;
  align-items: center;
}
footer .footerMain .footerCol2 .footerTiming ul li .footerTimingCol1 {
  flex: 0 0 35%;
  font-size: 16px;
  color: white;
  text-align: left;
}
footer .footerMain .footerCol2 .footerTiming ul li .footerTimingCol2 {
  flex: 0 0 60%;
  font-size: 16px;
  color: white;
  text-align: left;
}
footer .footerMain .footerCol3 {
  flex: 0 0 30%;
  display: flex;
  flex-direction: column;
}
@media (max-width: 1200px) {
  footer .footerMain .footerCol3 {
    flex: 0 0 47%;
    margin-top: 50px;
  }
}
@media (max-width: 768px) {
  footer .footerMain .footerCol3 {
    flex: 100%;
  }
}
footer .footerMain .footerCol3 h2 {
  color: white;
  font-size: 22px;
  text-align: left;
}
footer .footerMain .footerCol3 ul {
  width: 100%;
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  gap: 30px;
}
footer .footerMain .footerCol3 ul li {
  list-style: none;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
footer .footerMain .footerCol3 ul li .footerCol3Icon {
  width: 30px;
  font-size: 20px;
  line-height: 0.5;
  color: var(--secondaryColor);
  margin-top: 0px;
}
footer .footerMain .footerCol3 ul li .footerCol3Content {
  width: calc(100% - 40px);
  display: flex;
  flex-direction: column;
}
footer .footerMain .footerCol3 ul li .footerCol3Content span {
  color: var(--primaryColor);
  font-size: 18px;
  text-align: left;
}
footer .footerMain .footerCol3 ul li .footerCol3Content p {
  font-size: 18px;
  color: white;
  margin-top: 10px;
  text-align: left;
  line-height: 1.5;
}
footer .footerMain .footerCol3 ul li .footerCol3Content a {
  text-decoration: none;
  font-size: 18px;
  color: white;
  margin-top: 7px;
  text-align: left;
}
footer .footerMain .footerCol3 ul li .footerCol3Content a:hover {
  text-decoration: underline;
}
footer .footerStrip {
  border-top: 1px solid #3b3b3b;
  margin-top: 50px;
  padding-top: 50px;
  width: 100%;
}
footer .footerStrip .footerStripMain {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
@media (max-width: 480px) {
  footer .footerStrip .footerStripMain {
    flex-wrap: wrap;
  }
}
footer .footerStrip .footerStripMain .footerStripMainCol1 {
  width: 100%;
}
@media (max-width: 680px) {
  footer .footerStrip .footerStripMain .footerStripMainCol1 {
    width: 100%;
    align-items: center;
    justify-content: center;
  }
}
footer .footerStrip .footerStripMain .footerStripMainCol1 p {
  font-size: 14px;
  color: white;
  text-align: left;
}
@media (max-width: 480px) {
  footer .footerStrip .footerStripMain .footerStripMainCol1 p {
    text-align: center;
  }
}
footer .footerStrip .footerStripMain .footerStripMainCol2 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}
@media (max-width: 680px) {
  footer .footerStrip .footerStripMain .footerStripMainCol2 {
    width: 100%;
    justify-content: center;
    margin-top: 10px;
  }
}
footer .footerStrip .footerStripMain .footerStripMainCol2 a {
  text-decoration: none;
  color: white;
  font-size: 14px;
  text-align: left;
}
footer .footerStrip .footerStripMain .footerStripMainCol2 a:hover {
  text-decoration: underline;
}
footer .footerStrip .footerStripMain .footerStripMainCol2 p {
  font-size: 14px;
  color: white;
  text-align: left;
}
footer .footerStrip .footerStripMain .footerStripMainCol3 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
@media (max-width: 480px) {
  footer .footerStrip .footerStripMain .footerStripMainCol3 {
    margin-top: 10px;
    justify-content: center;
  }
}
footer .footerStrip .footerStripMain .footerStripMainCol3 .desined {
  display: flex;
  align-items: center;
  gap: 10px;
}
footer .footerStrip .footerStripMain .footerStripMainCol3 p {
  font-size: 14px;
  text-align: left;
  color: white;
}
footer .footerStrip .footerStripMain .footerStripMainCol3 svg {
  font-size: 14px;
  color: white;
}
footer .footerStrip .footerStripMain .footerStripMainCol3 a {
  text-decoration: none;
  width: 60px;
}
footer .footerStrip .footerStripMain .footerStripMainCol3 a img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
  filter: grayscale(1);
}

#aboutBanner {
  width: 100%;
  margin-top: 80px;
  padding: 100px 0px;
}
@media (max-width: 480px) {
  #aboutBanner {
    margin-top: 70px;
  }
}
#aboutBanner .aboutBannerMain {
  width: 100%;
  display: flex;
  flex-direction: column;
}
#aboutBanner .aboutBannerMain h1 {
  text-align: left;
  font-size: 3.5em;
  line-height: 1.3;
  width: 60%;
}
@media (max-width: 1024px) {
  #aboutBanner .aboutBannerMain h1 {
    font-size: 3em;
    width: 80%;
  }
}
@media (max-width: 768px) {
  #aboutBanner .aboutBannerMain h1 {
    font-size: 2.7em;
    width: 100%;
  }
}
@media (max-width: 480px) {
  #aboutBanner .aboutBannerMain h1 {
    font-size: 2.5em;
  }
}
#aboutBanner .aboutBannerMain p {
  font-size: 20px;
  text-align: left;
  margin-top: 10px;
  line-height: 1.5;
  width: 50%;
}
@media (max-width: 1800px) {
  #aboutBanner .aboutBannerMain p {
    width: 60%;
  }
}
@media (max-width: 1024px) {
  #aboutBanner .aboutBannerMain p {
    width: 80%;
  }
}
@media (max-width: 768px) {
  #aboutBanner .aboutBannerMain p {
    font-size: 18px;
    width: 100%;
  }
}
#aboutBanner .aboutBannerMain .aboutBannerThumbnail {
  width: 100%;
  margin-top: 40px;
  height: 800px;
  background: #eee;
  border-radius: 20px;
  overflow: hidden;
  position: relative;
}
@media (max-width: 1800px) {
  #aboutBanner .aboutBannerMain .aboutBannerThumbnail {
    height: 600px;
  }
}
@media (max-width: 1024px) {
  #aboutBanner .aboutBannerMain .aboutBannerThumbnail {
    height: 500px;
  }
}
@media (max-width: 768px) {
  #aboutBanner .aboutBannerMain .aboutBannerThumbnail {
    height: 400px;
  }
}
@media (max-width: 480px) {
  #aboutBanner .aboutBannerMain .aboutBannerThumbnail {
    height: 250px;
  }
}
#aboutBanner .aboutBannerMain .aboutBannerThumbnail .aboutBannerThumbnailShape1 {
  position: absolute;
  top: -6px;
  right: -6px;
  width: 50%;
  z-index: 1;
}
@media (max-width: 768px) {
  #aboutBanner .aboutBannerMain .aboutBannerThumbnail .aboutBannerThumbnailShape1 {
    width: 80%;
  }
}
#aboutBanner .aboutBannerMain .aboutBannerThumbnail .aboutBannerThumbnailShape1 img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
#aboutBanner .aboutBannerMain .aboutBannerThumbnail .aboutBannerThumbnailShape2 {
  position: absolute;
  bottom: -13px;
  left: -9px;
  width: 50%;
  z-index: 1;
}
@media (max-width: 768px) {
  #aboutBanner .aboutBannerMain .aboutBannerThumbnail .aboutBannerThumbnailShape2 {
    width: 80%;
  }
}
#aboutBanner .aboutBannerMain .aboutBannerThumbnail .aboutBannerThumbnailShape2 img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
#aboutBanner .aboutBannerMain .aboutBannerThumbnail img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}

#aboutDetailsSection {
  width: 100%;
  padding-bottom: 100px;
}
@media (max-width: 480px) {
  #aboutDetailsSection {
    padding-bottom: 0;
  }
}
#aboutDetailsSection .aboutDetailsSectionMain {
  width: 100%;
  display: flex;
  flex-direction: column;
}
#aboutDetailsSection .aboutDetailsSectionMain p {
  font-size: 30px;
  text-align: left;
  margin-top: 10px;
  line-height: 1.5;
  margin-top: 50px;
}
#aboutDetailsSection .aboutDetailsSectionMain p:nth-of-type(1) {
  margin-top: 0;
}
@media (max-width: 1800px) {
  #aboutDetailsSection .aboutDetailsSectionMain p {
    font-size: 24px;
  }
}
@media (max-width: 768px) {
  #aboutDetailsSection .aboutDetailsSectionMain p {
    font-size: 20px;
  }
}
@media (max-width: 480px) {
  #aboutDetailsSection .aboutDetailsSectionMain p {
    font-size: 18px;
  }
}
#aboutDetailsSection .aboutDetailsSectionMain ul {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 40px;
  margin-top: 50px;
}
@media (max-width: 1800px) {
  #aboutDetailsSection .aboutDetailsSectionMain ul {
    gap: 20px;
  }
}
#aboutDetailsSection .aboutDetailsSectionMain ul li {
  list-style: none;
  width: calc(25% - 30px);
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  border: 1px solid #BFD8AF;
  padding: 30px 20px;
  position: relative;
  z-index: 0;
  overflow: hidden;
}
@media (max-width: 1800px) {
  #aboutDetailsSection .aboutDetailsSectionMain ul li {
    width: calc(25% - 15px);
  }
}
@media (max-width: 1024px) {
  #aboutDetailsSection .aboutDetailsSectionMain ul li {
    width: calc(50% - 10px);
  }
}
@media (max-width: 480px) {
  #aboutDetailsSection .aboutDetailsSectionMain ul li {
    width: 100%;
  }
}
#aboutDetailsSection .aboutDetailsSectionMain ul li::after {
  position: absolute;
  content: "";
  width: 150px;
  height: 150px;
  border-radius: 50%;
  background: #BFD8AF;
  top: -85px;
  right: -85px;
  z-index: -1;
}
#aboutDetailsSection .aboutDetailsSectionMain ul li::before {
  position: absolute;
  content: "";
  width: 150px;
  height: 150px;
  border-radius: 50%;
  background: #E8EFCF;
  top: -45px;
  right: -75px;
  z-index: -2;
}
#aboutDetailsSection .aboutDetailsSectionMain ul li .aboutDetailsCol2 {
  width: 100%;
  display: flex;
  flex-direction: column;
}
#aboutDetailsSection .aboutDetailsSectionMain ul li .aboutDetailsCol2 h3 {
  text-align: left;
  font-size: 25px;
  font-family: myFont2;
  background: var(--gradientBgColor);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
#aboutDetailsSection .aboutDetailsSectionMain ul li .aboutDetailsCol2 h4 {
  text-align: left;
  margin-top: 15px;
  font-size: 18px;
  font-family: myFont3;
  line-height: 1.5;
}

#aboutTeam {
  width: 100%;
  margin-top: 100px;
  background: var(--lightBgColor);
  position: relative;
}
#aboutTeam .aboutTeamHeadOverlay {
  width: 100%;
  display: flex;
  align-items: center;
  height: 80px;
  background: white;
}
#aboutTeam .aboutTeamHeadOverlay .aboutTeamHeadOverlayBox1 {
  width: 65%;
  height: 100%;
  border-top-right-radius: 50px;
  background: var(--lightBgColor);
}
#aboutTeam .aboutTeamHeadOverlay .aboutTeamHeadOverlayBox2 {
  width: 35%;
  height: 100%;
  background: var(--lightBgColor);
}
#aboutTeam .aboutTeamHeadOverlay .aboutTeamHeadOverlayBox2 .aboutTeamHeadOverlayBox2Layer {
  background: white;
  width: 100%;
  height: 100%;
  border-bottom-left-radius: 50px;
}
#aboutTeam .aboutTeamFooterOverlay {
  width: 100%;
  display: flex;
  align-items: center;
  height: 80px;
  background: white;
  margin-top: 100px;
}
#aboutTeam .aboutTeamFooterOverlay .aboutTeamFooterOverlayBox1 {
  width: 35%;
  height: 100%;
  background: var(--lightBgColor);
}
#aboutTeam .aboutTeamFooterOverlay .aboutTeamFooterOverlayBox1 .aboutTeamFooterOverlayBox1Layer {
  background: white;
  width: 100%;
  height: 100%;
  border-top-right-radius: 50px;
}
#aboutTeam .aboutTeamFooterOverlay .aboutTeamFooterOverlayBox2 {
  width: 65%;
  height: 100%;
  background: var(--lightBgColor);
  border-bottom-left-radius: 50px;
}
#aboutTeam .aboutTeamTitle {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 50px;
}
#aboutTeam .aboutTeamTitle h2 {
  text-align: left;
  font-size: 3.5em;
  line-height: 1.3;
  width: 60%;
}
@media (max-width: 1800px) {
  #aboutTeam .aboutTeamTitle h2 {
    width: 80%;
  }
}
@media (max-width: 1024px) {
  #aboutTeam .aboutTeamTitle h2 {
    font-size: 3em;
    width: 100%;
  }
}
@media (max-width: 768px) {
  #aboutTeam .aboutTeamTitle h2 {
    font-size: 2.7em;
  }
}
@media (max-width: 480px) {
  #aboutTeam .aboutTeamTitle h2 {
    font-size: 2.5em;
  }
}
#aboutTeam .aboutTeamTitle p {
  font-size: 20px;
  text-align: left;
  margin-top: 10px;
  line-height: 1.5;
  width: 60%;
}
@media (max-width: 1800px) {
  #aboutTeam .aboutTeamTitle p {
    width: 70%;
  }
}
@media (max-width: 768px) {
  #aboutTeam .aboutTeamTitle p {
    font-size: 18px;
    width: 100%;
  }
}
#aboutTeam .aboutTeamMain {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 40px;
  margin-top: 50px;
}
@media (max-width: 1800px) {
  #aboutTeam .aboutTeamMain {
    gap: 20px;
  }
}
#aboutTeam .aboutTeamMain .aboutTeamBox {
  width: calc(25% - 30px);
  display: flex;
  flex-direction: column;
  background: white;
  border-radius: 20px;
}
@media (max-width: 1800px) {
  #aboutTeam .aboutTeamMain .aboutTeamBox {
    width: calc(25% - 15px);
  }
}
@media (max-width: 1024px) {
  #aboutTeam .aboutTeamMain .aboutTeamBox {
    width: calc(33.3333333333% - 14px);
  }
}
@media (max-width: 768px) {
  #aboutTeam .aboutTeamMain .aboutTeamBox {
    width: calc(50% - 10px);
  }
}
@media (max-width: 480px) {
  #aboutTeam .aboutTeamMain .aboutTeamBox {
    width: 100%;
  }
}
#aboutTeam .aboutTeamMain .aboutTeamBox .aboutTeamBoxThumbnail {
  width: 100%;
  height: 350px;
  background-color: #eee;
  border-radius: 20px;
  overflow: hidden;
}
@media (max-width: 1800px) {
  #aboutTeam .aboutTeamMain .aboutTeamBox .aboutTeamBoxThumbnail {
    height: 320px;
  }
}
#aboutTeam .aboutTeamMain .aboutTeamBox .aboutTeamBoxThumbnail img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
#aboutTeam .aboutTeamMain .aboutTeamBox .aboutTeamBoxProfile {
  width: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
}
#aboutTeam .aboutTeamMain .aboutTeamBox .aboutTeamBoxProfile h3 {
  font-size: 20px;
  text-align: left;
}
#aboutTeam .aboutTeamMain .aboutTeamBox .aboutTeamBoxProfile h4 {
  font-size: 16px;
  text-align: left;
  margin-top: 4px;
  font-family: myFont3;
}
#aboutTeam .aboutTeamMain .loaded .aboutTeamBoxThumbnail {
  position: relative;
  z-index: 0;
}
#aboutTeam .aboutTeamMain .loaded .aboutTeamBoxThumbnail::after {
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  top: 0;
  left: -100%;
  z-index: -1;
  background: #dedede;
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.1411764706);
  filter: blur(15px);
  opacity: 0.5;
  animation: abtLazyLoading1 1.5s linear infinite;
}
@keyframes abtLazyLoading1 {
  0% {
    left: -100%;
  }
  100% {
    left: 100%;
  }
}
#aboutTeam .aboutTeamMain .loaded .aboutTeamBoxThumbnail img {
  display: none;
}
#aboutTeam .aboutTeamMain .loaded .aboutTeamBoxProfile h3 {
  position: relative;
  overflow: hidden;
}
#aboutTeam .aboutTeamMain .loaded .aboutTeamBoxProfile h3::after {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  background: #eee;
  border-radius: 5px;
  z-index: 1;
  left: 0;
  top: 0;
}
#aboutTeam .aboutTeamMain .loaded .aboutTeamBoxProfile h3::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  background: #eee;
  border-radius: 5px;
  z-index: 2;
  left: 0;
  top: 0;
  background: #dedede;
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.1411764706);
  filter: blur(15px);
  opacity: 0.5;
  animation: lazyLoading2 1.5s linear infinite;
}
@keyframes lazyLoading2 {
  0% {
    left: -100%;
  }
  100% {
    left: 100%;
  }
}
#aboutTeam .aboutTeamMain .loaded .aboutTeamBoxProfile h4 {
  position: relative;
  overflow: hidden;
}
#aboutTeam .aboutTeamMain .loaded .aboutTeamBoxProfile h4::after {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  background: #eee;
  border-radius: 5px;
  z-index: 1;
  left: 0;
  top: 0;
}
#aboutTeam .aboutTeamMain .loaded .aboutTeamBoxProfile h4::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  background: #eee;
  border-radius: 5px;
  z-index: 2;
  left: 0;
  top: 0;
  background: #dedede;
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.1411764706);
  filter: blur(15px);
  opacity: 0.5;
  animation: lazyLoading3 1.5s linear infinite;
}
@keyframes lazyLoading3 {
  0% {
    left: -100%;
  }
  100% {
    left: 100%;
  }
}

#aboutValues {
  width: 100%;
  padding: 100px 0px;
}
#aboutValues .aboutValuesMain {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
#aboutValues .aboutValuesMain .aboutValuesThumbnail {
  flex: 0 0 40%;
}
@media (max-width: 1024px) {
  #aboutValues .aboutValuesMain .aboutValuesThumbnail {
    flex: 100%;
  }
}
#aboutValues .aboutValuesMain .aboutValuesThumbnail .aboutValuesThumbnailBox {
  width: 100%;
  height: 700px;
  border-radius: 20px;
  overflow: hidden;
  background: #eee;
}
@media (max-width: 1024px) {
  #aboutValues .aboutValuesMain .aboutValuesThumbnail .aboutValuesThumbnailBox {
    height: 500px;
  }
}
@media (max-width: 768px) {
  #aboutValues .aboutValuesMain .aboutValuesThumbnail .aboutValuesThumbnailBox {
    height: 400px;
  }
}
@media (max-width: 480px) {
  #aboutValues .aboutValuesMain .aboutValuesThumbnail .aboutValuesThumbnailBox {
    height: 250px;
  }
}
#aboutValues .aboutValuesMain .aboutValuesThumbnail .aboutValuesThumbnailBox img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
#aboutValues .aboutValuesMain .loaded .aboutValuesThumbnailBox {
  position: relative;
  z-index: 0;
}
#aboutValues .aboutValuesMain .loaded .aboutValuesThumbnailBox::after {
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  top: 0;
  left: -100%;
  z-index: -1;
  background: #dedede;
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.1411764706);
  filter: blur(15px);
  opacity: 0.5;
  animation: abtLazyLoading1 1.5s linear infinite;
}
@keyframes abtLazyLoading1 {
  0% {
    left: -100%;
  }
  100% {
    left: 100%;
  }
}
#aboutValues .aboutValuesMain .loaded .aboutValuesThumbnailBox img {
  display: none;
}
#aboutValues .aboutValuesMain .aboutValuesDetails {
  flex: 0 0 50%;
  display: flex;
  flex-direction: column;
}
@media (max-width: 1800px) {
  #aboutValues .aboutValuesMain .aboutValuesDetails {
    flex: 0 0 55%;
  }
}
@media (max-width: 1024px) {
  #aboutValues .aboutValuesMain .aboutValuesDetails {
    flex: 100%;
    margin-top: 40px;
  }
}
#aboutValues .aboutValuesMain .aboutValuesDetails h4 {
  font-size: 16px;
  width: -moz-fit-content;
  width: fit-content;
  padding: 12px 30px;
  border-radius: 50px;
  background: var(--lightBgColor);
  color: var(--thirdColor);
}
#aboutValues .aboutValuesMain .aboutValuesDetails h2 {
  text-align: left;
  font-size: 3.5em;
  margin-top: 20px;
  line-height: 1.3;
}
@media (max-width: 1024px) {
  #aboutValues .aboutValuesMain .aboutValuesDetails h2 {
    font-size: 3em;
  }
}
@media (max-width: 768px) {
  #aboutValues .aboutValuesMain .aboutValuesDetails h2 {
    font-size: 2.7em;
  }
}
@media (max-width: 480px) {
  #aboutValues .aboutValuesMain .aboutValuesDetails h2 {
    font-size: 2.5em;
  }
}
#aboutValues .aboutValuesMain .aboutValuesDetails p {
  font-size: 20px;
  text-align: left;
  margin-top: 30px;
  line-height: 1.5;
}
@media (max-width: 768px) {
  #aboutValues .aboutValuesMain .aboutValuesDetails p {
    font-size: 18px;
  }
}

#aboutMissionVision {
  width: 100%;
  padding: 100px 0px;
}
@media (max-width: 1800px) {
  #aboutMissionVision {
    padding-top: 50px;
  }
}
#aboutMissionVision .aboutMissionVisionMain {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 40px;
  border-radius: 20px;
  align-items: flex-start;
}
#aboutMissionVision .aboutMissionVisionMain .aboutMissionVisionBox {
  width: calc(50% - 20px);
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  background: var(--primaryColor);
}
@media (max-width: 768px) {
  #aboutMissionVision .aboutMissionVisionMain .aboutMissionVisionBox {
    width: 100%;
  }
}
#aboutMissionVision .aboutMissionVisionMain .aboutMissionVisionBox .aboutMissionVisionBoxDetails {
  padding: 30px;
  display: flex;
  flex-direction: column;
}
@media (max-width: 480px) {
  #aboutMissionVision .aboutMissionVisionMain .aboutMissionVisionBox .aboutMissionVisionBoxDetails {
    padding: 20px;
  }
}
#aboutMissionVision .aboutMissionVisionMain .aboutMissionVisionBox .aboutMissionVisionBoxDetails h2 {
  text-align: left;
  font-size: 2.5em;
  line-height: 1.3;
  color: white;
}
#aboutMissionVision .aboutMissionVisionMain .aboutMissionVisionBox .aboutMissionVisionBoxDetails p {
  font-size: 20px;
  text-align: left;
  margin-top: 10px;
  line-height: 1.5;
  color: white;
}
@media (max-width: 768px) {
  #aboutMissionVision .aboutMissionVisionMain .aboutMissionVisionBox .aboutMissionVisionBoxDetails p {
    font-size: 18px;
  }
}
#aboutMissionVision .aboutMissionVisionMain .aboutMissionVisionBox .aboutMissionVisionBoxThumbnail {
  width: 100%;
  height: 350px;
  background: #eee;
  border-radius: 20px;
  overflow: hidden;
}
@media (max-width: 480px) {
  #aboutMissionVision .aboutMissionVisionMain .aboutMissionVisionBox .aboutMissionVisionBoxThumbnail {
    height: 250px;
  }
}
#aboutMissionVision .aboutMissionVisionMain .aboutMissionVisionBox .aboutMissionVisionBoxThumbnail .aboutMissionVisionBoxThumbnailBox {
  width: 100%;
  height: 100%;
}
#aboutMissionVision .aboutMissionVisionMain .aboutMissionVisionBox .aboutMissionVisionBoxThumbnail .aboutMissionVisionBoxThumbnailBox img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
#aboutMissionVision .aboutMissionVisionMain .aboutMissionVisionBox .loaded .aboutMissionVisionBoxThumbnailBox {
  position: relative;
  z-index: 0;
}
#aboutMissionVision .aboutMissionVisionMain .aboutMissionVisionBox .loaded .aboutMissionVisionBoxThumbnailBox::after {
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  top: 0;
  left: -100%;
  z-index: -1;
  background: #dedede;
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.1411764706);
  filter: blur(15px);
  opacity: 0.5;
  animation: abtLazyLoading1 1.5s linear infinite;
}
@keyframes abtLazyLoading1 {
  0% {
    left: -100%;
  }
  100% {
    left: 100%;
  }
}
#aboutMissionVision .aboutMissionVisionMain .aboutMissionVisionBox .loaded .aboutMissionVisionBoxThumbnailBox img {
  display: none;
}

#innerServiceBanner {
  width: 100%;
  padding: 100px 0px;
  margin-top: 80px;
  background: #faf5f0;
  border-radius: 0px 0px 50px 50px;
}
@media (max-width: 480px) {
  #innerServiceBanner {
    margin-top: 70px;
    padding-top: 50px;
  }
}
#innerServiceBanner .innerServiceBannerMain {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media (max-width: 1024px) {
  #innerServiceBanner .innerServiceBannerMain {
    flex-wrap: wrap;
  }
}
#innerServiceBanner .innerServiceBannerMain .innerServiceBannerDetails {
  flex: 0 0 45%;
  display: flex;
  flex-direction: column;
}
@media (max-width: 1024px) {
  #innerServiceBanner .innerServiceBannerMain .innerServiceBannerDetails {
    flex: 100%;
    order: 2;
    margin-top: 40px;
  }
}
#innerServiceBanner .innerServiceBannerMain .innerServiceBannerDetails h1 {
  text-align: left;
  font-size: 3.5em;
  line-height: 1.3;
}
@media (max-width: 1024px) {
  #innerServiceBanner .innerServiceBannerMain .innerServiceBannerDetails h1 {
    font-size: 3em;
  }
}
@media (max-width: 768px) {
  #innerServiceBanner .innerServiceBannerMain .innerServiceBannerDetails h1 {
    font-size: 2.7em;
  }
}
@media (max-width: 480px) {
  #innerServiceBanner .innerServiceBannerMain .innerServiceBannerDetails h1 {
    font-size: 2.5em;
  }
}
#innerServiceBanner .innerServiceBannerMain .innerServiceBannerDetails p {
  font-size: 20px;
  text-align: left;
  margin-top: 10px;
  line-height: 1.5;
}
@media (max-width: 768px) {
  #innerServiceBanner .innerServiceBannerMain .innerServiceBannerDetails p {
    font-size: 18px;
  }
}
#innerServiceBanner .innerServiceBannerMain .innerServiceBannerDetails a {
  text-decoration: none;
  border: 1px solid var(--secondaryColor);
  background: var(--secondaryColor);
  font-size: 18px;
  border-radius: 50px;
  padding: 12px 30px;
  transition: 0.3s;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: 30px;
  color: white;
}
@media (max-width: 1800px) {
  #innerServiceBanner .innerServiceBannerMain .innerServiceBannerDetails a {
    font-size: 16px;
  }
}
#innerServiceBanner .innerServiceBannerMain .innerServiceBannerDetails a:hover {
  transition: 0.3s;
  border-color: var(--thirdColor);
  background: var(--thirdColor);
  color: white;
}
#innerServiceBanner .innerServiceBannerMain .innerServiceBannerThumbnail {
  flex: 0 0 45%;
  background: #eee;
  border-radius: 10px;
  height: 400px;
  overflow: hidden;
}
@media (max-width: 1024px) {
  #innerServiceBanner .innerServiceBannerMain .innerServiceBannerThumbnail {
    flex: 100%;
    order: 1;
  }
}
@media (max-width: 480px) {
  #innerServiceBanner .innerServiceBannerMain .innerServiceBannerThumbnail {
    height: 300px;
  }
}
#innerServiceBanner .innerServiceBannerMain .innerServiceBannerThumbnail .innerServiceBannerThumbnailBox {
  width: 100%;
  height: 100%;
}
#innerServiceBanner .innerServiceBannerMain .innerServiceBannerThumbnail .innerServiceBannerThumbnailBox img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
#innerServiceBanner .innerServiceBannerMain .loaded .innerServiceBannerThumbnailBox {
  position: relative;
  z-index: 0;
}
#innerServiceBanner .innerServiceBannerMain .loaded .innerServiceBannerThumbnailBox::after {
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  top: 0;
  left: -100%;
  z-index: -1;
  background: #dedede;
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.1411764706);
  filter: blur(15px);
  opacity: 0.5;
  animation: abtLazyLoading1 1.5s linear infinite;
}
@keyframes abtLazyLoading1 {
  0% {
    left: -100%;
  }
  100% {
    left: 100%;
  }
}
#innerServiceBanner .innerServiceBannerMain .loaded .innerServiceBannerThumbnailBox img {
  display: none;
}

#innerServicePage {
  width: 100%;
  padding: 100px 0px;
}

#blogBanner {
  width: 100%;
  margin-top: 80px;
  padding: 30px;
  position: relative;
}
@media (max-width: 480px) {
  #blogBanner {
    margin-top: 70px;
  }
}
#blogBanner .blogBannerMain {
  width: 100%;
  padding: 50px 130px 100px 130px;
  background: paleturquoise;
  border-top-right-radius: 30px;
  border-bottom-left-radius: 30px;
}
@media (max-width: 1800px) {
  #blogBanner .blogBannerMain {
    padding: 50px 70px 100px 70px;
  }
}
@media (max-width: 768px) {
  #blogBanner .blogBannerMain {
    padding: 30px 50px 60px 50px;
  }
}
@media (max-width: 480px) {
  #blogBanner .blogBannerMain {
    padding: 30px;
  }
}
#blogBanner .blogBannerMain h1 {
  text-align: left;
  font-size: 3.5em;
  line-height: 1.3;
}
@media (max-width: 1024px) {
  #blogBanner .blogBannerMain h1 {
    font-size: 3em;
  }
}
@media (max-width: 768px) {
  #blogBanner .blogBannerMain h1 {
    font-size: 2.7em;
  }
}
@media (max-width: 480px) {
  #blogBanner .blogBannerMain h1 {
    font-size: 2.5em;
  }
}
#blogBanner .blogBannerMain p {
  font-size: 20px;
  text-align: left;
  margin-top: 30px;
  line-height: 1.5;
  width: 85%;
}
@media (max-width: 768px) {
  #blogBanner .blogBannerMain p {
    font-size: 18px;
    width: 100%;
  }
}
#blogBanner .blogBannerHeadOverlay {
  width: 100%;
  display: flex;
  align-items: center;
  height: 80px;
  background: white;
}
@media (max-width: 480px) {
  #blogBanner .blogBannerHeadOverlay {
    height: 40px;
  }
}
#blogBanner .blogBannerHeadOverlay .blogBannerHeadOverlayBox1 {
  width: 65%;
  height: 100%;
  border-top-right-radius: 50px;
  border-top-left-radius: 30px;
  background: paleturquoise;
}
@media (max-width: 480px) {
  #blogBanner .blogBannerHeadOverlay .blogBannerHeadOverlayBox1 {
    border-top-right-radius: 25px;
    border-top-left-radius: 20px;
  }
}
#blogBanner .blogBannerHeadOverlay .blogBannerHeadOverlayBox2 {
  width: 35%;
  height: 100%;
  background: paleturquoise;
}
#blogBanner .blogBannerHeadOverlay .blogBannerHeadOverlayBox2 .blogBannerHeadOverlayBox2Layer {
  background: white;
  width: 100%;
  height: 100%;
  border-bottom-left-radius: 50px;
}
@media (max-width: 480px) {
  #blogBanner .blogBannerHeadOverlay .blogBannerHeadOverlayBox2 .blogBannerHeadOverlayBox2Layer {
    border-bottom-left-radius: 25px;
  }
}
#blogBanner .blogBannerFooterOverlay {
  width: 100%;
  display: flex;
  align-items: center;
  height: 80px;
  background: white;
}
@media (max-width: 480px) {
  #blogBanner .blogBannerFooterOverlay {
    height: 40px;
  }
}
#blogBanner .blogBannerFooterOverlay .blogBannerFooterOverlayBox1 {
  width: 35%;
  height: 100%;
  background: paleturquoise;
}
#blogBanner .blogBannerFooterOverlay .blogBannerFooterOverlayBox1 .blogBannerFooterOverlayBox1Layer {
  background: white;
  width: 100%;
  height: 100%;
  border-top-right-radius: 50px;
}
@media (max-width: 480px) {
  #blogBanner .blogBannerFooterOverlay .blogBannerFooterOverlayBox1 .blogBannerFooterOverlayBox1Layer {
    border-top-right-radius: 25px;
  }
}
#blogBanner .blogBannerFooterOverlay .blogBannerFooterOverlayBox2 {
  width: 65%;
  height: 100%;
  background: paleturquoise;
  border-bottom-left-radius: 50px;
  border-bottom-right-radius: 30px;
}
@media (max-width: 480px) {
  #blogBanner .blogBannerFooterOverlay .blogBannerFooterOverlayBox2 {
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 20px;
  }
}

#blogList {
  width: 100%;
  padding: 100px 0px;
}
#blogList .blogListMain {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 40px;
}
@media (max-width: 1800px) {
  #blogList .blogListMain {
    gap: 20px;
  }
}
#blogList .blogListMain .HomeBlogBox {
  width: calc(25% - 30px);
  display: flex;
  flex-direction: column;
  transition: 0.3s;
  text-decoration: none;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.062745098);
  border-radius: 10px;
}
@media (max-width: 1800px) {
  #blogList .blogListMain .HomeBlogBox {
    width: calc(25% - 15px);
  }
}
@media (max-width: 1024px) {
  #blogList .blogListMain .HomeBlogBox {
    width: calc(33.3333333333% - 14px);
  }
}
@media (max-width: 768px) {
  #blogList .blogListMain .HomeBlogBox {
    width: calc(50% - 10px);
  }
}
@media (max-width: 480px) {
  #blogList .blogListMain .HomeBlogBox {
    width: 100%;
  }
}
#blogList .blogListMain .HomeBlogBox:hover {
  transition: 0.3s;
  transform: scale(0.95);
}
#blogList .blogListMain .HomeBlogBox:hover .HomeBlogThumbnail img {
  transform: scale(1.05);
  transition: 0.3s;
}
#blogList .blogListMain .HomeBlogBox .HomeBlogThumbnail {
  width: 100%;
  height: 200px;
  background: #eee;
  overflow: hidden;
  border-radius: 10px;
}
@media (max-width: 1800px) {
  #blogList .blogListMain .HomeBlogBox .HomeBlogThumbnail {
    height: 180px;
  }
}
#blogList .blogListMain .HomeBlogBox .HomeBlogThumbnail img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  transition: 0.3s;
}
#blogList .blogListMain .HomeBlogBox .HomeBlogDetails {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px;
}
#blogList .blogListMain .HomeBlogBox .HomeBlogDetails .homeBlogDate {
  text-align: left;
  color: var(--secondaryColor);
}
#blogList .blogListMain .HomeBlogBox .HomeBlogDetails h3 {
  color: #343434;
  text-align: left;
  font-size: 25px;
  margin-top: 15px;
  line-height: 1.3;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
@media (max-width: 1800px) {
  #blogList .blogListMain .HomeBlogBox .HomeBlogDetails h3 {
    font-size: 22px;
  }
}
#blogList .blogListMain .HomeBlogBox .HomeBlogDetails p {
  font-size: 16px;
  margin-top: 15px;
  line-height: 1.5;
  text-align: left;
  opacity: 0.7;
  color: #343434;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
@media (max-width: 1800px) {
  #blogList .blogListMain .HomeBlogBox .HomeBlogDetails p {
    margin-top: 10px;
  }
}
#blogList .blogListMain .loaded .HomeBlogThumbnail {
  position: relative;
  z-index: 0;
}
#blogList .blogListMain .loaded .HomeBlogThumbnail::after {
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  top: 0;
  left: -100%;
  z-index: -1;
  background: #dedede;
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.1411764706);
  filter: blur(15px);
  opacity: 0.5;
  animation: lazyLoading1 1.5s linear infinite;
}
@keyframes lazyLoading1 {
  0% {
    left: -100%;
  }
  100% {
    left: 100%;
  }
}
#blogList .blogListMain .loaded .HomeBlogThumbnail img {
  display: none;
}
#blogList .blogListMain .loaded .HomeBlogDetails h3 {
  position: relative;
  overflow: hidden;
}
#blogList .blogListMain .loaded .HomeBlogDetails h3::after {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  background: #eee;
  border-radius: 5px;
  z-index: 1;
  left: 0;
  top: 0;
}
#blogList .blogListMain .loaded .HomeBlogDetails h3::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  background: #eee;
  border-radius: 5px;
  z-index: 2;
  left: 0;
  top: 0;
  background: #dedede;
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.1411764706);
  filter: blur(15px);
  opacity: 0.5;
  animation: lazyLoading2 1.5s linear infinite;
}
@keyframes lazyLoading2 {
  0% {
    left: -100%;
  }
  100% {
    left: 100%;
  }
}
#blogList .blogListMain .loaded .HomeBlogDetails p {
  position: relative;
  overflow: hidden;
}
#blogList .blogListMain .loaded .HomeBlogDetails p::after {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  background: #eee;
  border-radius: 5px;
  z-index: 1;
  left: 0;
  top: 0;
}
#blogList .blogListMain .loaded .HomeBlogDetails p::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  background: #eee;
  border-radius: 5px;
  z-index: 2;
  left: 0;
  top: 0;
  background: #dedede;
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.1411764706);
  filter: blur(15px);
  opacity: 0.5;
  animation: lazyLoading3 1.5s linear infinite;
}
@keyframes lazyLoading3 {
  0% {
    left: -100%;
  }
  100% {
    left: 100%;
  }
}
#blogList .blogListMain .loaded .HomeBlogDetails .homeBlogDate {
  position: relative;
  overflow: hidden;
  border-color: #eee;
  width: -moz-fit-content;
  width: fit-content;
}
#blogList .blogListMain .loaded .HomeBlogDetails .homeBlogDate::after {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  background: #eee;
  border-radius: 5px;
  z-index: 1;
  left: 0;
  top: 0;
}
#blogList .blogListMain .loaded .HomeBlogDetails .homeBlogDate::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  background: #eee;
  border-radius: 5px;
  z-index: 2;
  left: 0;
  top: 0;
  background: #dedede;
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.1411764706);
  filter: blur(15px);
  opacity: 0.5;
  animation: lazyLoading2 1.5s linear infinite;
}
@keyframes lazyLoading2 {
  0% {
    left: -100%;
  }
  100% {
    left: 100%;
  }
}

#innerBlogDetails {
  width: 100%;
  margin-top: 80px;
  padding: 50px 0px 100px 0px;
  background: var(--lightBgColor);
}
@media (max-width: 480px) {
  #innerBlogDetails {
    margin-top: 70px;
  }
}
#innerBlogDetails .innerBlogDetailsMain {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 80px 15%;
  background: white;
  border-radius: 20px;
}
@media (max-width: 1800px) {
  #innerBlogDetails .innerBlogDetailsMain {
    padding: 80px 10%;
  }
}
@media (max-width: 1024px) {
  #innerBlogDetails .innerBlogDetailsMain {
    padding: 50px 5%;
  }
}
#innerBlogDetails .innerBlogDetailsMain .backBtn {
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  width: -moz-fit-content;
  width: fit-content;
  font-size: 16px;
  transition: 0.3s;
  background: rgba(0, 0, 0, 0.062745098);
  padding: 10px 30px;
  border-radius: 10px;
}
#innerBlogDetails .innerBlogDetailsMain .backBtn:hover {
  transition: 0.3s;
  color: var(--primaryColor);
  background: rgba(0, 0, 0, 0.0274509804);
}
#innerBlogDetails .innerBlogDetailsMain .backBtn svg {
  width: 20px;
  height: 20px;
}
#innerBlogDetails .innerBlogDetailsMain h1 {
  text-align: left;
  font-size: 3.5em;
  line-height: 1.3;
  margin-top: 40px;
}
@media (max-width: 1024px) {
  #innerBlogDetails .innerBlogDetailsMain h1 {
    font-size: 3em;
  }
}
@media (max-width: 768px) {
  #innerBlogDetails .innerBlogDetailsMain h1 {
    font-size: 2.7em;
  }
}
@media (max-width: 480px) {
  #innerBlogDetails .innerBlogDetailsMain h1 {
    font-size: 2.5em;
  }
}
#innerBlogDetails .innerBlogDetailsMain p {
  font-size: 20px;
  text-align: left;
  margin-top: 30px;
  line-height: 1.5;
}
@media (max-width: 768px) {
  #innerBlogDetails .innerBlogDetailsMain p {
    font-size: 18px;
  }
}
#innerBlogDetails .innerBlogDetailsMain img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  margin-top: 30px;
}

#relatedBlogs {
  width: 100%;
  padding-bottom: 100px;
}
#relatedBlogs .relatedBlogsHeadOverlay {
  width: 100%;
  display: flex;
  align-items: center;
  height: 80px;
  background: white;
}
#relatedBlogs .relatedBlogsHeadOverlay .relatedBlogsHeadOverlayBox1 {
  width: 35%;
  height: 100%;
  background: var(--lightBgColor);
}
#relatedBlogs .relatedBlogsHeadOverlay .relatedBlogsHeadOverlayBox1 .relatedBlogsHeadOverlayBox2Layer {
  background: white;
  width: 100%;
  height: 100%;
  border-top-right-radius: 50px;
}
#relatedBlogs .relatedBlogsHeadOverlay .relatedBlogsHeadOverlayBox2 {
  width: 65%;
  height: 100%;
  background: var(--lightBgColor);
  border-bottom-left-radius: 50px;
  border-bottom-right-radius: 30px;
}
#relatedBlogs .relatedBlogTitle {
  width: 100%;
  margin-top: 100px;
  display: flex;
  flex-direction: column;
}
#relatedBlogs .relatedBlogTitle h2 {
  text-align: left;
  font-size: 3.5em;
  line-height: 1.3;
}
@media (max-width: 1024px) {
  #relatedBlogs .relatedBlogTitle h2 {
    font-size: 3em;
  }
}
@media (max-width: 768px) {
  #relatedBlogs .relatedBlogTitle h2 {
    font-size: 2.7em;
  }
}
@media (max-width: 480px) {
  #relatedBlogs .relatedBlogTitle h2 {
    font-size: 2.5em;
  }
}
#relatedBlogs .relatedBlogTitle p {
  font-size: 20px;
  text-align: left;
  margin-top: 30px;
  line-height: 1.5;
  width: 50%;
}
@media (max-width: 1800px) {
  #relatedBlogs .relatedBlogTitle p {
    width: 60%;
  }
}
@media (max-width: 1024px) {
  #relatedBlogs .relatedBlogTitle p {
    width: 80%;
  }
}
@media (max-width: 768px) {
  #relatedBlogs .relatedBlogTitle p {
    font-size: 18px;
    width: 100%;
  }
}
#relatedBlogs .relatedBlogsMain {
  width: 100%;
  margin-top: 80px;
  /* main.css */
}
#relatedBlogs .relatedBlogsMain .HomeBlogBox {
  width: 100%;
  display: flex;
  flex-direction: column;
  background: white;
  overflow: hidden;
  text-decoration: none;
  border-radius: 10px;
  transition: 0.3s;
}
#relatedBlogs .relatedBlogsMain .HomeBlogBox:hover {
  transition: 0.3s;
  transform: scale(0.95);
}
#relatedBlogs .relatedBlogsMain .HomeBlogBox:hover .HomeBlogThumbnail img {
  transform: scale(1.05);
  transition: 0.3s;
}
#relatedBlogs .relatedBlogsMain .HomeBlogBox .HomeBlogThumbnail {
  width: 100%;
  height: 200px;
  background: #eee;
  overflow: hidden;
  border-radius: 10px;
}
@media (max-width: 1800px) {
  #relatedBlogs .relatedBlogsMain .HomeBlogBox .HomeBlogThumbnail {
    height: 180px;
  }
}
#relatedBlogs .relatedBlogsMain .HomeBlogBox .HomeBlogThumbnail img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  transition: 0.3s;
}
#relatedBlogs .relatedBlogsMain .HomeBlogBox .HomeBlogDetails {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px;
}
#relatedBlogs .relatedBlogsMain .HomeBlogBox .HomeBlogDetails .homeBlogDate {
  text-align: left;
  color: var(--secondaryColor);
}
#relatedBlogs .relatedBlogsMain .HomeBlogBox .HomeBlogDetails h3 {
  color: #343434;
  text-align: left;
  font-size: 25px;
  margin-top: 15px;
  line-height: 1.3;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
#relatedBlogs .relatedBlogsMain .HomeBlogBox .HomeBlogDetails p {
  font-size: 16px;
  margin-top: 15px;
  line-height: 1.5;
  text-align: left;
  opacity: 0.7;
  color: #343434;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
#relatedBlogs .relatedBlogsMain .loaded .HomeBlogThumbnail {
  position: relative;
  z-index: 0;
}
#relatedBlogs .relatedBlogsMain .loaded .HomeBlogThumbnail::after {
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  top: 0;
  left: -100%;
  z-index: -1;
  background: #dedede;
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.1411764706);
  filter: blur(15px);
  opacity: 0.5;
  animation: lazyLoading1 1.5s linear infinite;
}
@keyframes lazyLoading1 {
  0% {
    left: -100%;
  }
  100% {
    left: 100%;
  }
}
#relatedBlogs .relatedBlogsMain .loaded .HomeBlogThumbnail img {
  display: none;
}
#relatedBlogs .relatedBlogsMain .loaded .HomeBlogDetails h3 {
  position: relative;
  overflow: hidden;
}
#relatedBlogs .relatedBlogsMain .loaded .HomeBlogDetails h3::after {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  background: #eee;
  border-radius: 5px;
  z-index: 1;
  left: 0;
  top: 0;
}
#relatedBlogs .relatedBlogsMain .loaded .HomeBlogDetails h3::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  background: #eee;
  border-radius: 5px;
  z-index: 2;
  left: 0;
  top: 0;
  background: #dedede;
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.1411764706);
  filter: blur(15px);
  opacity: 0.5;
  animation: lazyLoading2 1.5s linear infinite;
}
@keyframes lazyLoading2 {
  0% {
    left: -100%;
  }
  100% {
    left: 100%;
  }
}
#relatedBlogs .relatedBlogsMain .loaded .HomeBlogDetails p {
  position: relative;
  overflow: hidden;
}
#relatedBlogs .relatedBlogsMain .loaded .HomeBlogDetails p::after {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  background: #eee;
  border-radius: 5px;
  z-index: 1;
  left: 0;
  top: 0;
}
#relatedBlogs .relatedBlogsMain .loaded .HomeBlogDetails p::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  background: #eee;
  border-radius: 5px;
  z-index: 2;
  left: 0;
  top: 0;
  background: #dedede;
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.1411764706);
  filter: blur(15px);
  opacity: 0.5;
  animation: lazyLoading3 1.5s linear infinite;
}
@keyframes lazyLoading3 {
  0% {
    left: -100%;
  }
  100% {
    left: 100%;
  }
}
#relatedBlogs .relatedBlogsMain .loaded .HomeBlogDetails .homeBlogDate {
  position: relative;
  overflow: hidden;
  border-color: #eee;
  width: -moz-fit-content;
  width: fit-content;
}
#relatedBlogs .relatedBlogsMain .loaded .HomeBlogDetails .homeBlogDate::after {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  background: #eee;
  border-radius: 5px;
  z-index: 1;
  left: 0;
  top: 0;
}
#relatedBlogs .relatedBlogsMain .loaded .HomeBlogDetails .homeBlogDate::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  background: #eee;
  border-radius: 5px;
  z-index: 2;
  left: 0;
  top: 0;
  background: #dedede;
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.1411764706);
  filter: blur(15px);
  opacity: 0.5;
  animation: lazyLoading2 1.5s linear infinite;
}
@keyframes lazyLoading2 {
  0% {
    left: -100%;
  }
  100% {
    left: 100%;
  }
}
#relatedBlogs .relatedBlogsMain .slick-slide {
  padding: 0 10px;
}
#relatedBlogs .relatedBlogsMain .slick-list {
  margin: 0 -10px;
}
#relatedBlogs .relatedBlogsMain .slick-prev:before,
#relatedBlogs .relatedBlogsMain .slick-next:before {
  content: "";
  display: none;
}
#relatedBlogs .relatedBlogsMain .slick-prev,
#relatedBlogs .relatedBlogsMain .slick-next {
  width: 30px;
  height: 30px;
  z-index: 1;
}
#relatedBlogs .relatedBlogsMain .slick-prev {
  left: -35px; /* Adjust positioning */
}
@media (max-width: 480px) {
  #relatedBlogs .relatedBlogsMain .slick-prev {
    left: 0;
  }
}
#relatedBlogs .relatedBlogsMain .slick-next {
  right: -35px; /* Adjust positioning */
}
@media (max-width: 480px) {
  #relatedBlogs .relatedBlogsMain .slick-next {
    right: 0;
  }
}
#relatedBlogs .relatedBlogsMain .slick-prev,
#relatedBlogs .relatedBlogsMain .slick-next {
  background: var(--gradientBgColor);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
#relatedBlogs .relatedBlogsMain .slick-prev svg,
#relatedBlogs .relatedBlogsMain .slick-next svg {
  color: white;
  width: 60%;
  height: 60%;
}
#relatedBlogs .relatedBlogsMain .slick-prev:hover,
#relatedBlogs .relatedBlogsMain .slick-next:hover {
  background: white;
}
#relatedBlogs .relatedBlogsMain .slick-prev:hover svg,
#relatedBlogs .relatedBlogsMain .slick-next:hover svg {
  color: var(--primaryColor);
}

#contactPage {
  margin-top: 80px;
  padding: 50px 0px 100px 0px;
  width: 100%;
}
@media (max-width: 480px) {
  #contactPage {
    margin-top: 70px;
  }
}
#contactPage .contactPageTitle {
  width: 100%;
  display: flex;
  flex-direction: column;
}
#contactPage .contactPageTitle h1 {
  text-align: left;
  font-size: 3.5em;
  line-height: 1.3;
  width: 60%;
}
@media (max-width: 1024px) {
  #contactPage .contactPageTitle h1 {
    font-size: 3em;
  }
}
@media (max-width: 768px) {
  #contactPage .contactPageTitle h1 {
    font-size: 2.7em;
  }
}
@media (max-width: 480px) {
  #contactPage .contactPageTitle h1 {
    font-size: 2.5em;
  }
}
#contactPage .contactPageTitle p {
  font-size: 20px;
  text-align: left;
  margin-top: 10px;
  line-height: 1.5;
  width: 60%;
}
@media (max-width: 1800px) {
  #contactPage .contactPageTitle p {
    width: 70%;
  }
}
@media (max-width: 768px) {
  #contactPage .contactPageTitle p {
    font-size: 18px;
    width: 100%;
  }
}
#contactPage .contactPageMain {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 80px;
  align-items: flex-start;
}
#contactPage .contactPageMain .contactPageDetails {
  flex: 0 0 50%;
  display: flex;
  flex-wrap: wrap;
  gap: 40px;
}
@media (max-width: 1800px) {
  #contactPage .contactPageMain .contactPageDetails {
    gap: 20px;
    flex: 0 0 60%;
  }
}
@media (max-width: 1024px) {
  #contactPage .contactPageMain .contactPageDetails {
    flex: 100%;
  }
}
#contactPage .contactPageMain .contactPageDetails .contactPageDetailsBox {
  width: calc(50% - 20px);
  display: flex;
  flex-direction: column;
}
@media (max-width: 1800px) {
  #contactPage .contactPageMain .contactPageDetails .contactPageDetailsBox {
    width: calc(50% - 10px);
  }
}
@media (max-width: 480px) {
  #contactPage .contactPageMain .contactPageDetails .contactPageDetailsBox {
    width: 100%;
  }
}
#contactPage .contactPageMain .contactPageDetails .contactPageDetailsBox .contactHeadOverlay {
  width: 100%;
  display: flex;
  align-items: center;
  height: 30px;
  background: white;
}
#contactPage .contactPageMain .contactPageDetails .contactPageDetailsBox .contactHeadOverlay .contactHeadOverlayBox1 {
  width: 65%;
  height: 100%;
  border-top-right-radius: 20px;
  border-top-left-radius: 15px;
  background: var(--lightBgColor);
}
#contactPage .contactPageMain .contactPageDetails .contactPageDetailsBox .contactHeadOverlay .contactHeadOverlayBox2 {
  width: 35%;
  height: 100%;
  background: var(--lightBgColor);
}
#contactPage .contactPageMain .contactPageDetails .contactPageDetailsBox .contactHeadOverlay .contactHeadOverlayBox2 .contactHeadOverlayBox2Layer {
  background: white;
  width: 100%;
  height: 100%;
  border-bottom-left-radius: 20px;
}
#contactPage .contactPageMain .contactPageDetails .contactPageDetailsBox .contactFooterOverlay {
  width: 100%;
  display: flex;
  align-items: center;
  height: 30px;
  background: white;
}
#contactPage .contactPageMain .contactPageDetails .contactPageDetailsBox .contactFooterOverlay .contactFooterOverlayBox1 {
  width: 35%;
  height: 100%;
  background: var(--lightBgColor);
}
#contactPage .contactPageMain .contactPageDetails .contactPageDetailsBox .contactFooterOverlay .contactFooterOverlayBox1 .contactFooterOverlayBox1Layer {
  background: white;
  width: 100%;
  height: 100%;
  border-top-right-radius: 20px;
}
#contactPage .contactPageMain .contactPageDetails .contactPageDetailsBox .contactFooterOverlay .contactFooterOverlayBox2 {
  width: 65%;
  height: 100%;
  background: var(--lightBgColor);
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 15px;
}
#contactPage .contactPageMain .contactPageDetails .contactPageDetailsBox .contactPageDetailsBoxMain {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0px 30px;
  background: var(--lightBgColor);
  border-radius: 0px 20px 0px 20px;
  padding-bottom: 30px;
}
#contactPage .contactPageMain .contactPageDetails .contactPageDetailsBox .contactPageDetailsBoxMain .contactPageDetailsBoxIcon {
  text-align: left;
}
#contactPage .contactPageMain .contactPageDetails .contactPageDetailsBox .contactPageDetailsBoxMain .contactPageDetailsBoxIcon svg {
  width: 60px;
  height: 60px;
  color: var(--secondaryColor);
}
#contactPage .contactPageMain .contactPageDetails .contactPageDetailsBox .contactPageDetailsBoxMain h3 {
  text-align: left;
  font-size: 1.5rem;
  font-family: myFont3;
  margin-top: 10px;
}
#contactPage .contactPageMain .contactPageDetails .contactPageDetailsBox .contactPageDetailsBoxMain h4 {
  color: rgba(0, 0, 0, 0.4117647059);
  text-align: left;
  font-size: 1.25rem;
  margin-top: 20px;
}
@media (max-width: 768px) {
  #contactPage .contactPageMain .contactPageDetails .contactPageDetailsBox .contactPageDetailsBoxMain h4 {
    font-size: 16px;
  }
}
#contactPage .contactPageMain .contactPageDetails .contactPageDetailsBox .contactPageDetailsBoxMain p {
  white-space: normal;
  margin-top: 1rem;
  font-size: 1.125rem;
  font-weight: 300;
  text-decoration: none;
  text-align: left;
  line-height: 1.5;
}
#contactPage .contactPageMain .contactPageDetails .contactPageDetailsBox .contactPageDetailsBoxMain a {
  color: black;
  white-space: normal;
  margin-top: 1rem;
  font-size: 1.125rem;
  font-weight: 300;
  text-decoration: none;
  text-align: left;
  line-height: 1.5;
}
#contactPage .contactPageMain .contactPageForm {
  flex: 0 0 45%;
}
@media (max-width: 1800px) {
  #contactPage .contactPageMain .contactPageForm {
    flex: 0 0 35%;
  }
}
@media (max-width: 1024px) {
  #contactPage .contactPageMain .contactPageForm {
    flex: 100%;
    margin-top: 40px;
  }
}
#contactPage .contactPageMain .contactPageForm .contactHeadOverlay {
  width: 100%;
  display: flex;
  align-items: center;
  height: 50px;
  background: white;
}
#contactPage .contactPageMain .contactPageForm .contactHeadOverlay .contactHeadOverlayBox1 {
  width: 65%;
  height: 100%;
  border-top-right-radius: 30px;
  border-top-left-radius: 20px;
  background: paleturquoise;
}
#contactPage .contactPageMain .contactPageForm .contactHeadOverlay .contactHeadOverlayBox2 {
  width: 35%;
  height: 100%;
  background: paleturquoise;
}
#contactPage .contactPageMain .contactPageForm .contactHeadOverlay .contactHeadOverlayBox2 .contactHeadOverlayBox2Layer {
  background: white;
  width: 100%;
  height: 100%;
  border-bottom-left-radius: 30px;
}
#contactPage .contactPageMain .contactPageForm .contactFooterOverlay {
  width: 100%;
  display: flex;
  align-items: center;
  height: 50px;
  background: white;
}
#contactPage .contactPageMain .contactPageForm .contactFooterOverlay .contactFooterOverlayBox1 {
  width: 35%;
  height: 100%;
  background: paleturquoise;
}
#contactPage .contactPageMain .contactPageForm .contactFooterOverlay .contactFooterOverlayBox1 .contactFooterOverlayBox1Layer {
  background: white;
  width: 100%;
  height: 100%;
  border-top-right-radius: 30px;
}
#contactPage .contactPageMain .contactPageForm .contactFooterOverlay .contactFooterOverlayBox2 {
  width: 65%;
  height: 100%;
  background: paleturquoise;
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 20px;
}
#contactPage .contactPageMain .contactPageForm form {
  border-radius: 0px 20px 0px 20px;
  background: paleturquoise;
  padding: 40px;
  display: flex;
  flex-direction: column;
}
@media (max-width: 480px) {
  #contactPage .contactPageMain .contactPageForm form {
    padding: 20px;
  }
}
#contactPage .contactPageMain .contactPageForm form .formGroup {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}
#contactPage .contactPageMain .contactPageForm form .formGroup:nth-of-type(1) {
  margin-top: 0;
}
#contactPage .contactPageMain .contactPageForm form .formGroup label {
  font-size: 20px;
  text-align: left;
}
#contactPage .contactPageMain .contactPageForm form .formGroup input {
  width: 100%;
  height: 45px;
  background: white;
  border: 1px solid white;
  outline: none;
  margin-top: 4px;
  padding: 0px 15px;
  border-radius: 5px;
  font-size: 20px;
}
#contactPage .contactPageMain .contactPageForm form .formGroup input:focus {
  border-color: var(--primaryColor);
}
#contactPage .contactPageMain .contactPageForm form .formGroup textarea {
  width: 100%;
  height: 100px;
  background: white;
  border: 1px solid white;
  outline: none;
  margin-top: 4px;
  padding: 15px 15px;
  border-radius: 5px;
  font-size: 20px;
}
#contactPage .contactPageMain .contactPageForm form .formGroup textarea:focus {
  border-color: var(--primaryColor);
}
#contactPage .contactPageMain .contactPageForm form .formBtnArea {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 30px;
}
#contactPage .contactPageMain .contactPageForm form .formBtnArea button {
  text-decoration: none;
  color: white;
  background: var(--thirdColor);
  font-size: 18px;
  cursor: pointer;
  border-radius: 50px;
  padding: 12px 50px;
  transition: 0.3s;
  position: relative;
  overflow: hidden;
  z-index: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  cursor: pointer;
}
@media (max-width: 1800px) {
  #contactPage .contactPageMain .contactPageForm form .formBtnArea button {
    font-size: 16px;
  }
}
#contactPage .contactPageMain .contactPageForm form .formBtnArea button::after {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  z-index: -1;
  background: var(--gradientBgColor);
  transition: 0.3s;
  border-radius: 50px;
}
#contactPage .contactPageMain .contactPageForm form .formBtnArea button:hover::after {
  transform: scale(0.5);
  opacity: 0;
  transition: 0.3s;
}
#contactPage .map {
  width: 100%;
  margin-top: 100px;
  border-radius: 20px;
  overflow: hidden;
}
#contactPage .map .mapMain {
  width: 100%;
  height: 400px;
  background: #eee;
}
@media (max-width: 480px) {
  #contactPage .map .mapMain {
    height: 300px;
  }
}
#contactPage .map .mapMain iframe {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}/*# sourceMappingURL=style.css.map */