
// homeBanner
#homeBanner{
    width: 100%;
    margin-top: 80px;
    position: relative;
    z-index: 0;
    overflow: hidden;
    @media(max-width:480px){
        margin-top: 70px;
    }
    &::after{
        position: absolute;
        content: '';
        width: 100%;
        height: 400px;
        background: #f4ffe5;
        // background: #11b28b;
        top: 0;
        left: 0;
        z-index: -3;
        @media(max-width:480px){
            height: 430px;
        }
    }
    .bannerMain{
        width: 100%;
        padding: 100px 0px;
        @media(max-width:480px){
            display: flex;
            flex-direction: column;
            padding-top: 50px;
        }
        .homeBannerThumbnailMob{
            display: none;
            @media(max-width:480px){
                display: flex;
                width: 100%;
                img{
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
            }
        }
        .bannerDetails{
            width: 50%;
            display: flex;
            flex-direction: column;
            @media(max-width:1440px){
                width: 60%;
            }
            @media(max-width:1024px){
                width: 65%;
            }
            @media(max-width:480px){
                width: 100%;
                margin-top: 30px;
                justify-content: center;
                align-items: center;
            }
            h1{
                font-size: 5.5em;
                @media(max-width:1350px){
                    font-size: 5em;
                }
                @media(max-width:1220px){
                    font-size: 4.5em;
                }
                @media(max-width:1024px){
                    font-size: 4em;
                }
                @media(max-width:480px){
                    font-size: 2.5em;
                    text-align: center;
                }
            }
            .homeBannerBtn{
                text-decoration: none;
                background: var(--primaryColor);
                color: white;
                width: fit-content;
                padding: 16px 30px;
                font-size: 20px;
                margin-top: 40px;
                border-radius: 10px;
                transition: .3s;
                &:hover{
                    transition: .3s;
                    background: var(--secondaryColor);
                }
            }
        }
    }
    svg{
        position: absolute;
        top: 300px;
        left: 0;
        z-index: -2;
        @media(max-width:480px){
            display: none;
        }
    }
    .homeBannerThumbnail{
        position: absolute;
        right: 0%;
        top: 50px;
        width: 50%;
        height: 70vh;
        z-index: -1;
        @media(max-width:1024px){
            right: -20%;
            width: 70%;
        }
        @media(max-width:768px){
            right: -30%;
        }
        @media(max-width:480px){
            display: none;
        }
        img{
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
    .homeBannerService{
        width: 100%;
        margin-top: 200px;
        display: flex;
        flex-direction: column;
        @media(max-width:480px){
            margin-top: 0;
        }
        h2{
            font-size: 3.5em;
            font-weight: 800;
            @media(max-width:1800px){
                font-size: 3em;
            }
            @media(max-width:768px){
                font-size: 2.5em;
            }
        }
        p{
            font-size: 20px;
            text-align: left;
            margin-top: 20px;
            line-height: 1.5;
            width: 45%;
            font-weight: 600;
            @media(max-width:1800px){
                width: 60%;
                font-size: 18px;
            }
            @media(max-width:768px){
                width: 100%;
                font-size: 16px;
            }
        }
        .homeBannerServiceList{
            width: 100%;
            margin-top: 100px;
            display: flex;
            flex-wrap: wrap;
            column-gap: 100px;
            row-gap: 50px;
            @media(max-width:1440px){
                column-gap: 60px;
            }
            @media(max-width:480px){
                row-gap: 30px;
            }
            .homeBannerServiceBox{
                width: calc(100% / 3 - 67px);
                display: flex;
                flex-direction: column;
                text-decoration: none;
                cursor: pointer;
                transition: .3s;
                &:hover{
                    transition: .3s;
                    transform: translateY(-10px);
                }
                @media(max-width:1440px){
                    width: calc(100% / 3 - 40px);
                }
                @media(max-width:1024px){
                    width: calc(100% / 2 - 30px);
                }
                @media(max-width:480px){
                    width: 100%;
                }
                .homeBannerServiceBoxHead{
                    width: 100%;
                    display: flex;
                    align-items: center;
                    gap: 20px;
                    .homeBannerServiceBoxIcon{
                        width: 70px;
                        height: 70px;
                        border-radius: 50%;
                        background: var(--secondaryColor);
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        img{
                            width: 55%;
                            height: 55%;
                            object-fit: contain;
                            filter: invert(1);
                        }
                    }
                    h3{
                        color: var(--primaryColor);
                        font-size: 22px;
                        width: calc(100% - 90px);
                    }
                }
                .homeBannerServiceBoxBody{
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    // border-top: 1px solid #aaa;
                    margin-top: 10px;
                    overflow: hidden;
                    height: 0;
                    visibility: hidden;
                    opacity: 0;
                    box-shadow: 0 0 15px #0000000e;
                    padding: 0px;
                    border-radius: 10px;
                    p{
                        font-size: 16px;
                        width: 100%;
                        font-weight: 400;
                        margin-top: 0;
                    }
                }
            }
        }
    }
}

// homeAbout
#homeAbout{
    width: 100%;
    background: var(--lightBgColor);
    position: relative;
    margin-top: 100px;
    .homeAboutHeadOverlay{
        width: 100%;
        display: flex;
        align-items: center;
        height: 80px;
        background: white;
        .homeAboutHeadOverlayBox1{
            width: 65%;
            height: 100%;
            border-top-right-radius: 50px;
            background: var(--lightBgColor);
        }
        .homeAboutHeadOverlayBox2{
            width: 35%;
            height: 100%;
            background: var(--lightBgColor);
            .homeAboutHeadOverlayBox2Layer{
                background: white;
                width: 100%;
                height: 100%;
                border-bottom-left-radius: 50px;
            }
        }
    }
    .homeAboutMain{
        width: 100%;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        align-items: center;
        margin-top: 100px;
        .homeAboutDetails{
            width: 100%;
            display: flex;
            flex-direction: column;
            // @media(max-width:1800px){
            //     flex: 0 0 45%;
            // }
            // @media(max-width:1024px){
            //     flex: 100%;
            // }
            h2{
                text-align: left;
                font-size: 3.5em;
                line-height: 1.3;
                width: 70%;
                @media(max-width:1800px){
                    width: 90%;
                }
                @media(max-width:1024px){
                    font-size: 3em;
                    width: 100%;
                }
                @media(max-width:768px){
                    font-size: 2.7em;
                }
                @media(max-width:480px){
                    font-size: 2.5em;
                }
            }
            p{
                font-size: 20px;
                text-align: left;
                margin-top: 30px;
                line-height: 1.5;
                width: 85%;
                @media(max-width:1800px){
                    width: 100%;
                }
                @media(max-width:768px){
                    font-size: 18px;
                }
            }
        }
        .homeAboutPoints{
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            gap: 20px;
            margin-top: 50px;
            // @media(max-width:1800px){
            //     flex: 0 0 50%;
            // }
            // @media(max-width:1024px){
            //     flex: 100%;
            //     margin-top: 50px;
            // }
            .homeAboutPointsBox{
                width: calc(100% / 3 - 15px);
                background: white;
                display: flex;
                flex-direction: column;
                padding: 40px 30px 30px 30px;
                @media(max-width:768px){
                    width: calc(100% / 2 - 10px);
                }
                @media(max-width:480px){
                    width: 100%;
                    border-radius: 30px;
                }
                &:nth-of-type(1){
                    border-radius: 50px 50px 4px 50px;
                    @media(max-width:480px){
                        border-radius: 30px;
                    }
                    .homeAboutPointsBoxIcon{
                        &::after{
                            background: #fece00;
                        }
                        &::before{
                            background: #fecf0058;
                        }
                    }
                }
                &:nth-of-type(2){
                    border-radius: 50px 50px 50px 4px;
                    @media(max-width:480px){
                        border-radius: 30px;
                    }
                    .homeAboutPointsBoxIcon{
                        &::after{
                            background: #ffbfd0;
                        }
                        &::before{
                            background: #ffbfd053;
                        }
                    }
                }
                &:nth-of-type(3){
                    border-radius: 50px 4px 50px 50px;
                    @media(max-width:480px){
                        border-radius: 30px;
                    }
                    .homeAboutPointsBoxIcon{
                        &::after{
                            background: #81e3b1;
                        }
                        &::before{
                            background: #81e3b058;
                        }
                    }
                }
                &:nth-of-type(4){
                    border-radius: 4px 50px 50px 50px;
                    @media(max-width:480px){
                        border-radius: 30px;
                    }
                    .homeAboutPointsBoxIcon{
                        &::after{
                            background: #afd9e3;
                        }
                        &::before{
                            background: #afd9e354;
                        }
                    }
                }
                .homeAboutPointsBoxIcon{
                    width: 60px;
                    height: 60px;
                    border-radius: 50%;
                    background: #eee;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    position: relative;
                    z-index: 0;
                    svg{
                        font-size: 25px;
                    }
                    &::after{
                        position: absolute;
                        content: '';
                        width: 60px;
                        height: 60px;
                        border-radius: 50%;
                        z-index: -1;
                        background: #eee;
                    }
                    &::before{
                        position: absolute;
                        content: '';
                        width: 80px;
                        height: 80px;
                        border-radius: 50%;
                        z-index: -2;
                        background: #eeeeeeb5;
                    }
                }
                h3{
                    font-size: 22px;
                    margin-top: 30px;
                    line-height: 1.3;
                }
                p{
                    font-size: 16px;
                    margin-top: 15px;
                    line-height: 1.3;
                    opacity: .7;
                }
            }
        }
    }
    .homeAboutFooterOverlay{
        width: 100%;
        display: flex;
        align-items: center;
        height: 80px;
        background: white;
        margin-top: 100px;
        .homeAboutFooterOverlayBox1{
            width: 35%;
            height: 100%;
            background: var(--lightBgColor);
            .homeAboutFooterOverlayBox1Layer{
                background: white;
                width: 100%;
                height: 100%;
                border-top-right-radius: 50px;
            }
        }
        .homeAboutFooterOverlayBox2{
            width: 65%;
            height: 100%;
            background: var(--lightBgColor);
            border-bottom-left-radius: 50px;
        }
    }
}

// homeService
#homeService{
    width: 100%;
    padding: 100px 0px;
    .homeServiceTitle{
        width: 100%;
        display: flex;
        flex-direction: column;
        h2{
            text-align: left;
            font-size: 3.5em;
            line-height: 1.3;
            @media(max-width:1024px){
                font-size: 3em;
            }
            @media(max-width:768px){
                font-size: 2.7em;
            }
            @media(max-width:480px){
                font-size: 2.5em;
            }
        }
        p{
            font-size: 20px;
            text-align: left;
            margin-top: 10px;
            line-height: 1.5;
            width: 50%;
            @media(max-width:1800px){
                width: 60%;
            }
            @media(max-width:1024px){
                width: 90%;
            }
            @media(max-width:768px){
                font-size: 18px;
            }
            @media(max-width:480px){
                width: 100%;
            }
        }
    }
    .homeServiceMain{
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
        margin-top: 40px;
        .homeServiceBox{
            width: calc(100% / 3 - 14px);
            display: flex;
            border: 1px solid #eee;
            border-radius: 20px;
            overflow: hidden;
            text-decoration: none;
            @media(max-width:1024px){
                width: calc(100% / 2 - 10px);
            }
            @media(max-width:768px){
                width: 100%;
            }
            @media(max-width:480px){
                flex-direction: column;
            }
            .homeServiceBoxThumbnail{
                width: 40%;
                height: 180px;
                background: #eee;
                overflow: hidden;
                @media(max-width:1800px){
                    width: 30%;
                }
                @media(max-width:480px){
                    width: 100%;
                    height: 180px;
                }
                span{
                    width: 100%;
                    height: 100%;
                }
                img{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
            .homeServiceBoxDetails{
                width: 60%;
                padding: 20px;
                display: flex;
                flex-direction: column;
                @media(max-width:1800px){
                    width: 70%;
                }
                @media(max-width:480px){
                    width: 100%;
                }
                h3{
                    font-size: 22px;
                    text-align: left;
                    color: #343434;
                    @media(max-width:480px){
                        font-size: 24px;
                    }
                }
                p{
                    font-size: 16px;
                    margin-top: 15px;
                    line-height: 1.3;
                    text-align: left;
                    opacity: .7;
                    color: #343434;
                    @media(max-width:768px){
                        width: 80%;
                    }
                    @media(max-width:480px){
                        width: 100%;
                        font-size: 18px;
                    }
                }
                .homeServiceBoxBtn{
                    text-decoration: none;
                    border: 1px solid var(--primaryColor);
                    color: var(--primaryColor);
                    font-size: 16px;
                    border-radius: 50px;
                    padding: 8px 26px;
                    transition: .3s;
                    width: fit-content;
                    margin-top: 20px;
                    background: white;
                    @media(max-width:1800px){
                        font-size: 16px;
                    }
                    &:hover{
                        transition: .3s;
                        border-color: var(--secondaryColor);
                        background: var(--secondaryColor);
                        color: white;
                    }
                }
            }
        }
        .loaded{
            .homeServiceBoxThumbnail{
                position: relative;
                z-index: 0;
                &::after{
                    position: absolute;
                    content: '';
                    height: 100%;
                    width: 100%;
                    top: 0;
                    left: -100%;
                    z-index: -1;
                    background: #dedede;
                    box-shadow: 0 0 50px #00000024;
                    filter: blur(15px);
                    opacity: .5;
                    animation: lazyLoading1 1.5s linear infinite;
                    @keyframes lazyLoading1 {
                        0%{
                            left: -100%;
                        }
                        100%{
                            left: 100%;
                        }
                    }
                }
                img{
                    display: none;
                }
            }
            .homeServiceBoxDetails{
                h3{
                    position: relative;
                    overflow: hidden;
                    &::after{
                        position: absolute;
                        content: '';
                        width: 100%;
                        height: 100%;
                        background: #eee;
                        border-radius: 5px;
                        z-index: 1;
                        left: 0;
                        top: 0;
                    }
                    &::before{
                        position: absolute;
                        content: '';
                        width: 100%;
                        height: 100%;
                        background: #eee;
                        border-radius: 5px;
                        z-index: 2;
                        left: 0;
                        top: 0;
                        background: #dedede;
                        box-shadow: 0 0 50px #00000024;
                        filter: blur(15px);
                        opacity: .5;
                        animation: lazyLoading2 1.5s linear infinite;
                        @keyframes lazyLoading2 {
                            0%{
                                left: -100%;
                            }
                            100%{
                                left: 100%;
                            }
                        }
                    }
                }
                p{
                    position: relative;
                    overflow: hidden;
                    &::after{
                        position: absolute;
                        content: '';
                        width: 100%;
                        height: 100%;
                        background: #eee;
                        border-radius: 5px;
                        z-index: 1;
                        left: 0;
                        top: 0;
                    }
                    &::before{
                        position: absolute;
                        content: '';
                        width: 100%;
                        height: 100%;
                        background: #eee;
                        border-radius: 5px;
                        z-index: 2;
                        left: 0;
                        top: 0;
                        background: #dedede;
                        box-shadow: 0 0 50px #00000024;
                        filter: blur(15px);
                        opacity: .5;
                        animation: lazyLoading3 1.5s linear infinite;
                        @keyframes lazyLoading3 {
                            0%{
                                left: -100%;
                            }
                            100%{
                                left: 100%;
                            }
                        }
                    }
                }
                .homeServiceBoxBtn{
                    position: relative;
                    overflow: hidden;
                    border-color: #eee;
                    &::after{
                        position: absolute;
                        content: '';
                        width: 100%;
                        height: 100%;
                        background: #eee;
                        border-radius: 5px;
                        z-index: 1;
                        left: 0;
                        top: 0;
                    }
                    &::before{
                        position: absolute;
                        content: '';
                        width: 100%;
                        height: 100%;
                        background: #eee;
                        border-radius: 5px;
                        z-index: 2;
                        left: 0;
                        top: 0;
                        background: #dedede;
                        box-shadow: 0 0 50px #00000024;
                        filter: blur(15px);
                        opacity: .5;
                        animation: lazyLoading2 1.5s linear infinite;
                        @keyframes lazyLoading2 {
                            0%{
                                left: -100%;
                            }
                            100%{
                                left: 100%;
                            }
                        }
                    }
                }
            }
        }
    }
    .homeBannerServiceList{
        width: 100%;
        margin-top: 100px;
        display: flex;
        flex-wrap: wrap;
        column-gap: 100px;
        row-gap: 50px;
        @media(max-width:1440px){
            column-gap: 60px;
        }
        @media(max-width:480px){
            row-gap: 30px;
        }
        .homeBannerServiceBox{
            width: calc(100% / 3 - 67px);
            display: flex;
            flex-direction: column;
            text-decoration: none;
            cursor: pointer;
            @media(max-width:1440px){
                width: calc(100% / 3 - 40px);
            }
            @media(max-width:1024px){
                width: calc(100% / 2 - 30px);
            }
            @media(max-width:480px){
                width: 100%;
            }
            .homeBannerServiceBoxHead{
                width: 100%;
                display: flex;
                align-items: center;
                gap: 20px;
                .homeBannerServiceBoxIcon{
                    width: 70px;
                    height: 70px;
                    border-radius: 50%;
                    background: var(--secondaryColor);
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    img{
                        width: 55%;
                        height: 55%;
                        object-fit: contain;
                        filter: invert(1);
                    }
                }
                h3{
                    color: var(--primaryColor);
                    font-size: 22px;
                    width: calc(100% - 90px);
                }
            }
            .homeBannerServiceBoxBody{
                width: 100%;
                display: flex;
                flex-direction: column;
                // border-top: 1px solid #aaa;
                margin-top: 10px;
                overflow: hidden;
                height: 0;
                visibility: hidden;
                opacity: 0;
                box-shadow: 0 0 15px #0000000e;
                padding: 0px;
                border-radius: 10px;
                p{
                    font-size: 16px;
                    width: 100%;
                    font-weight: 400;
                    margin-top: 0;
                }
            }
        }
    }
}
.ServicePageStrip{
    width: 100%;
    margin-top: 80px;
    @media(max-width:480px){
        margin-top: 70px;
    }
}

// HomeBlog
#HomeBlog{
    width: 100%;
    // background: var(--lightBgColor);
    position: relative;
    padding: 100px 0px;
    @media(max-width:480px){
        padding-top: 0;
    }
    .HomeBlogHeadOverlay{
        width: 100%;
        display: none;
        align-items: center;
        height: 80px;
        background: white;
        .HomeBlogHeadOverlayBox1{
            width: 65%;
            height: 100%;
            border-top-right-radius: 50px;
            background: var(--lightBgColor);
        }
        .HomeBlogHeadOverlayBox2{
            width: 35%;
            height: 100%;
            background: var(--lightBgColor);
            .HomeBlogHeadOverlayBox2Layer{
                background: white;
                width: 100%;
                height: 100%;
                border-bottom-left-radius: 50px;
            }
        }
    }
    .homeBlogTitle{
        width: 100%;
        display: flex;
        flex-direction: column;
        h2{
            text-align: left;
            font-size: 3.5em;
            line-height: 1.3;
            width: 60%;
            @media(max-width:1024px){
                font-size: 3em;
                width: 100%;
                margin-top: 20px;
            }
            @media(max-width:768px){
                font-size: 2.7em;
            }
            @media(max-width:480px){
                font-size: 2.5em;
            }
        }
        p{
            font-size: 20px;
            text-align: left;
            margin-top: 10px;
            line-height: 1.5;
            width: 50%;
            @media(max-width:1800px){
                width: 60%;
            }
            @media(max-width:1024px){
                width: 100%;
            }
            @media(max-width:768px){
                font-size: 18px;
            }
        }
        a{
            text-decoration: none;
            border: 1px solid var(--primaryColor);
            color: var(--primaryColor);
            font-size: 18px;
            border-radius: 50px;
            padding: 12px 30px;
            transition: .3s;
            width: fit-content;
            margin-top: 40px;
            background: white;
            @media(max-width:1800px){
                font-size: 16px;
            }
            &:hover{
                transition: .3s;
                border-color: var(--secondaryColor);
                background: var(--secondaryColor);
                color: white;
            }
        }
    }
    .HomeBlogMain{
        width: 100%;
        margin-top: 80px;
        .HomeBlogBox{
            width: 100%;
            display: flex;
            flex-direction: column;
            background: white;
            overflow: hidden;
            text-decoration: none;
            border-radius: 10px;
            transition: .3s;
            &:hover{
                transition: .3s;
                transform: scale(.95);
                .HomeBlogThumbnail{
                    img{
                        transform: scale(1.05);
                        transition: .3s;
                    }
                }
            }
            .HomeBlogThumbnail{
                width: 100%;
                height: 200px;
                background: #eee;
                overflow: hidden;
                border-radius: 10px;
                @media(max-width:1800px){
                    height: 180px;
                }
                img{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    transition: .3s;
                }
            }
            .HomeBlogDetails{
                width: 100%;
                display: flex;
                flex-direction: column;
                // padding: 20px;
                margin-top: 20px;
                .homeBlogDate{
                    text-align: left;
                    color: var(--secondaryColor);
                }
                h3{
                    color: #343434;
                    text-align: left;
                    font-size: 25px;
                    margin-top: 15px;
                    line-height: 1.3;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    @media(max-width:1800px){
                        font-size: 22px;
                    }
                }
                p{
                    font-size: 16px;
                    margin-top: 15px;
                    line-height: 1.5;
                    text-align: left;
                    opacity: .7;
                    color: #343434;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    @media(max-width:1800px){
                        margin-top: 10px;
                    }
                }
            }
        }
        .loaded{
            .HomeBlogThumbnail{
                position: relative;
                z-index: 0;
                &::after{
                    position: absolute;
                    content: '';
                    height: 100%;
                    width: 100%;
                    top: 0;
                    left: -100%;
                    z-index: -1;
                    background: #dedede;
                    box-shadow: 0 0 50px #00000024;
                    filter: blur(15px);
                    opacity: .5;
                    animation: lazyLoading1 1.5s linear infinite;
                    @keyframes lazyLoading1 {
                        0%{
                            left: -100%;
                        }
                        100%{
                            left: 100%;
                        }
                    }
                }
                img{
                    display: none;
                }
            }
            .HomeBlogDetails{
                h3{
                    position: relative;
                    overflow: hidden;
                    &::after{
                        position: absolute;
                        content: '';
                        width: 100%;
                        height: 100%;
                        background: #eee;
                        border-radius: 5px;
                        z-index: 1;
                        left: 0;
                        top: 0;
                    }
                    &::before{
                        position: absolute;
                        content: '';
                        width: 100%;
                        height: 100%;
                        background: #eee;
                        border-radius: 5px;
                        z-index: 2;
                        left: 0;
                        top: 0;
                        background: #dedede;
                        box-shadow: 0 0 50px #00000024;
                        filter: blur(15px);
                        opacity: .5;
                        animation: lazyLoading2 1.5s linear infinite;
                        @keyframes lazyLoading2 {
                            0%{
                                left: -100%;
                            }
                            100%{
                                left: 100%;
                            }
                        }
                    }
                }
                p{
                    position: relative;
                    overflow: hidden;
                    &::after{
                        position: absolute;
                        content: '';
                        width: 100%;
                        height: 100%;
                        background: #eee;
                        border-radius: 5px;
                        z-index: 1;
                        left: 0;
                        top: 0;
                    }
                    &::before{
                        position: absolute;
                        content: '';
                        width: 100%;
                        height: 100%;
                        background: #eee;
                        border-radius: 5px;
                        z-index: 2;
                        left: 0;
                        top: 0;
                        background: #dedede;
                        box-shadow: 0 0 50px #00000024;
                        filter: blur(15px);
                        opacity: .5;
                        animation: lazyLoading3 1.5s linear infinite;
                        @keyframes lazyLoading3 {
                            0%{
                                left: -100%;
                            }
                            100%{
                                left: 100%;
                            }
                        }
                    }
                }
                .homeBlogDate{
                    position: relative;
                    overflow: hidden;
                    border-color: #eee;
                    width: fit-content;
                    &::after{
                        position: absolute;
                        content: '';
                        width: 100%;
                        height: 100%;
                        background: #eee;
                        border-radius: 5px;
                        z-index: 1;
                        left: 0;
                        top: 0;
                    }
                    &::before{
                        position: absolute;
                        content: '';
                        width: 100%;
                        height: 100%;
                        background: #eee;
                        border-radius: 5px;
                        z-index: 2;
                        left: 0;
                        top: 0;
                        background: #dedede;
                        box-shadow: 0 0 50px #00000024;
                        filter: blur(15px);
                        opacity: .5;
                        animation: lazyLoading2 1.5s linear infinite;
                        @keyframes lazyLoading2 {
                            0%{
                                left: -100%;
                            }
                            100%{
                                left: 100%;
                            }
                        }
                    }
                }
            }
        }
        .slick-slide {
            padding: 0 10px;
        }
        
        .slick-list {
            margin: 0 -10px;
        }
        /* main.css */
        .slick-prev:before,
        .slick-next:before {
            content: '';
            display: none;
        }

        .slick-prev,
        .slick-next {
            width: 30px;
            height: 30px;
            z-index: 1;
        }

        .slick-prev {
            left: -35px; /* Adjust positioning */
            @media(max-width:480px){
                left: 0px;
            }
        }

        .slick-next {
            right: -35px; /* Adjust positioning */
            @media(max-width:480px){
                right: 0;
            }
        }

        .slick-prev,
        .slick-next {
            background: var(--gradientBgColor);
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            svg{
                color: white;
                width: 60%;
                height: 60%;
            }
        }

        .slick-prev:hover,
        .slick-next:hover {
            background: white;
            svg{
                color: var(--primaryColor);
            }
        }

    }
    .HomeBlogFooterOverlay{
        width: 100%;
        display: none;
        align-items: center;
        height: 80px;
        background: var(--lightBgColor);
        margin-top: 100px;
        .HomeBlogFooterOverlayBox1{
            width: 35%;
            height: 100%;
            background: var(--lightBgColor);
            .HomeBlogFooterOverlayBox1Layer{
                background: var(--lightBgColor);
                width: 100%;
                height: 100%;
                border-top-right-radius: 50px;
            }
        }
        .HomeBlogFooterOverlayBox2{
            width: 65%;
            height: 100%;
            background: var(--lightBgColor);
            border-bottom-left-radius: 50px;
        }
    }
}

// homeAboutSection
#homeAboutSection{
    width: 100%;
    padding: 100px 0px;
    .homeAboutSectionMain{
        width: 100%;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        align-items: center;
        .homeAboutSectionThumbnail{
            flex: 0 0 45%;
            background: #eee;
            border-radius: 10px;
            overflow: hidden;
            border-radius: 20px;
            height: 80vh;
            position: relative;
            @media(max-width:1024px){
                flex: 100%;
                height: 500px;
            }
            @media(max-width:768px){
                height: 400px;
            }
            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
            .homeAboutSectionThumbnailTitle{
                position: absolute;
                top: 20px;
                right: 20px;
                padding: 14px 50px;
                background: rgba(255, 255, 255, 0.239);
                border-radius: 50px;
                display: flex;
                justify-content: center;
                align-items: center;
                h2{
                    font-size: 25px;
                    color: white;
                    @media(max-width:480px){
                        font-size: 20px;
                    }
                }
            }
        }
        .loaded{
            position: relative;
            z-index: 0;
            &::after{
                position: absolute;
                content: '';
                height: 100%;
                width: 100%;
                top: 0;
                left: -100%;
                z-index: -1;
                background: #dedede;
                box-shadow: 0 0 50px #00000024;
                filter: blur(15px);
                opacity: .5;
                animation: abtLazyLoading1 1.5s linear infinite;
                @keyframes abtLazyLoading1 {
                    0%{
                        left: -100%;
                    }
                    100%{
                        left: 100%;
                    }
                }
            }
            img{
                display: none;
            }
        }
        .homeAboutSectionDetails{
            flex: 0 0 47%;
            display: flex;
            flex-direction: column;
            @media(max-width:1024px){
                flex: 100%;
                margin-top: 40px;
            }
            h2{
                text-align: left;
                font-size: 3.5em;
                line-height: 1.3;
                @media(max-width:1024px){
                    font-size: 3em;
                    width: 100%;
                }
                @media(max-width:768px){
                    font-size: 2.7em;
                }
                @media(max-width:480px){
                    font-size: 2.5em;
                }
            }
            p{
                font-size: 20px;
                text-align: left;
                margin-top: 30px;
                line-height: 1.5;
                width: 90%;
                @media(max-width:1800px){
                    width: 100%;
                }
                @media(max-width:768px){
                    font-size: 18px;
                }
            }
            ul{
                width: 100%;
                margin-top: 40px;
                display: flex;
                flex-wrap: wrap;
                gap: 20px;
                li{
                    list-style: none;
                    display: flex;
                    flex-direction: column;
                    border-radius: 20px;
                    border: 1px solid #BFD8AF;
                    width: 230px;
                    padding: 30px 20px;
                    position: relative;
                    z-index: 0;
                    overflow: hidden;
                    @media(max-width:1800px){
                        width: calc(100% / 3 - 14px);
                        padding: 20px;
                    }
                    @media(max-width:768px){
                        width: calc(100% / 2 - 10px);
                    }
                    @media(max-width:480px){
                        width: 100%;
                    }
                    &::after{
                        position: absolute;
                        content: '';
                        width: 150px;
                        height: 150px;
                        border-radius: 50%;
                        background: #BFD8AF;
                        top: -85px;
                        right: -85px;
                        z-index: -1;
                    }
                    &::before{
                        position: absolute;
                        content: '';
                        width: 150px;
                        height: 150px;
                        border-radius: 50%;
                        background: #E8EFCF;
                        top: -45px;
                        right: -75px;
                        z-index: -2;
                    }
                    h3{
                        text-align: left;
                        font-size: 2em;
                        font-family: myFont2;
                        background: var(--gradientBgColor);
                        background-clip: text;
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                    }
                    h4{
                        text-align: left;
                        margin-top: 20px;
                        font-size: 20px;
                        font-family: myFont3;
                        @media(max-width:1800px){
                            font-size: 16px;
                        }
                        @media(max-width:480px){
                            font-size: 20px;
                        }
                    }
                }
            }
            a{
                text-decoration: none;
                border: 1px solid var(--secondaryColor);
                background: var(--secondaryColor);
                font-size: 18px;
                border-radius: 50px;
                padding: 12px 30px;
                transition: .3s;
                width: fit-content;
                margin-top: 40px;
                color: white;
                @media(max-width:1800px){
                    font-size: 16px;
                }
                &:hover{
                    transition: .3s;
                    border-color: var(--thirdColor);
                    background: var(--thirdColor);
                    color: white;
                }
            }
        }
    }
}

// homeParallax
#homeParallax{
    width: 100%;
    padding: 200px 0px 300px 0px;
    background-color: #eee;
    background-image: url(../images/whyChoos.webp);
    background-attachment: fixed;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
    z-index: 0;
    @media(max-width:1800px){
        padding: 200px 0px;
    }
    &::after{
        position: absolute;
        content: '';
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: -1;
        background: rgba(0, 0, 0, 0.586);
        backdrop-filter: blur(3px);
        @media(max-width:480px){
            backdrop-filter: blur(1px);
        }
    }
    .homeParallaxShape1{
        position: absolute;
        top: -2px;
        right: -2px;
        width: 50%;
        z-index: 1;
        @media(max-width:768px){
            width: 80%;
        }
        @media(max-width:480px){
            width: 100%;
        }
        img{
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
    .homeParallaxShape2{
        position: absolute;
        bottom: -6px;
        left: -2px;
        width: 50%;
        z-index: 1;
        @media(max-width:768px){
            width: 80%;
        }
        @media(max-width:480px){
            width: 100%;
        }
        img{
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
    .homeParallaxMain{
        width: 100%;
        display: flex;
        // justify-content: center;
        // align-items: center;
        flex-direction: column;
        h2{
            text-align: left;
            font-size: 3.5em;
            line-height: 1.3;
            width: 40%;
            color: white;
            @media(max-width:1800px){
                width: 60%;
            }
            @media(max-width:1024px){
                font-size: 3em;
                width: 80%;
            }
            @media(max-width:768px){
                font-size: 2.7em;
            }
            @media(max-width:480px){
                font-size: 2.5em;
                width: 100%;
            }
        }
        p{
            font-size: 20px;
            text-align: left;
            margin-top: 10px;
            line-height: 1.5;
            width: 55%;
            color: white;
            @media(max-width:1024px){
                width: 80%;
            }
            @media(max-width:768px){
                font-size: 18px;
                width: 100%;
            }
        }
    }
}

// homeTestimonials
#homeTestimonials{
    width: 100%;
    padding: 100px 0px;
    overflow: hidden;
    .homeTestimonialsTitle{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        h2{
            text-align: center;
            font-size: 3.5em;
            line-height: 1.3;
            width: 60%;
            @media(max-width:1800px){
                width: 100%;
            }
            @media(max-width:1024px){
                font-size: 3em;
                width: 100%;
            }
            @media(max-width:768px){
                font-size: 2.7em;
            }
            @media(max-width:480px){
                font-size: 2.5em;
            }
        }
        p{
            font-size: 20px;
            text-align: center;
            margin-top: 10px;
            line-height: 1.5;
            width: 50%;
            @media(max-width:1800px){
                width: 60%;
            }
            @media(max-width:1024px){
                width: 80%;
            }
            @media(max-width:768px){
                font-size: 18px;
                width: 85%;
            }
            @media(max-width:480px){
                width: 100%;
            }
        }
    }
    .homeTestimonialsMain{
        width: 100%;
        margin-top: 80px;
        .slick-slide{
            &:nth-of-type(odd){
                .homeTestimonialsBoxDetails{
                    background: #FEECE2;
                    &::after{
                        background: #FEECE2;
                    }
                }
            }
            &:nth-of-type(even){
                .homeTestimonialsBoxDetails{
                    background: #E1F0DA;
                    &::after{
                        background: #E1F0DA;
                    }
                }
            }
        }
        .homeTestimonialsBox{
            width: 100%;
            display: flex;
            flex-direction: column;
            .homeTestimonialsBoxDetails{
                width: 100%;
                padding: 30px;
                border-radius: 20px;
                position: relative;
                margin-top: 20px;
                padding-top: 40px;
                .homeTestimonialsBoxImoji{
                    position: absolute;
                    width: 40px;
                    height: 40px;
                    border-radius: 50%;
                    top: -20px;
                    left: 30px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    animation: bounceEmoji 1s linear infinite;
                    @keyframes bounceEmoji {
                        0%{
                            transform: scale(1);
                        }
                        50%{
                            transform: scale(1.2);
                        }
                        100%{
                            transform: scale(1);
                        }
                    }
                    img{
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                    }
                }
                &::after{
                    position: absolute;
                    content: '';
                    width: 16px;
                    height: 16px;
                    bottom: -8px;
                    left: 30px;
                    transform: rotate(45deg);
                    border-radius: 0px 0px 5px 0px;
                }
                p{
                    font-size: 20px;
                    line-height: 1.3;
                    text-align: left;
                }
            }
            .homeTestimonialsBoxProfile{
                width: 100%;
                margin-top: 20px;
                display: flex;
                flex-direction: column;
                padding: 0px 30px;
                h3{
                    font-size: 18px;
                    text-align: left;
                }
                h4{
                    font-size: 14px;
                    text-align: left;
                    margin-top: 4px;
                    font-family: myFont3;
                }
            }
        }
        .slick-slide {
            padding: 0 30px;
        }
        
        .slick-list {
            margin: 0 -30px;
        }
        /* main.css */
        .slick-prev:before,
        .slick-next:before {
            content: '';
            display: none;
        }

        .slick-prev,
        .slick-next {
            width: 30px;
            height: 30px;
            z-index: 1;
        }

        .slick-prev {
            left: -35px; /* Adjust positioning */
            @media(max-width:480px){
                left: 0;
            }
        }

        .slick-next {
            right: -35px; /* Adjust positioning */
            @media(max-width:480px){
                right: 0;
            }
        }

        .slick-prev,
        .slick-next {
            background: var(--gradientBgColor);
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            svg{
                color: white;
                width: 60%;
                height: 60%;
            }
        }

        .slick-prev:hover,
        .slick-next:hover {
            background: var(--lightBgColor);
            svg{
                color: var(--primaryColor);
            }
        }
    }
}

// footer
footer {
    width: 100%;
    padding: 0px 0px 50px 0px;
    background: #0d1427;
    .footerHeadOverlay{
        width: 100%;
        display: flex;
        align-items: center;
        height: 80px;
        background: white;
        overflow: hidden;
        .footerHeadOverlayBox1{
            width: 60%;
            height: 100%;
            border-top-right-radius: 50px;
            background: #0d1427;
        }
        .footerHeadOverlayBox2{
            width: 40%;
            height: 100%;
            background: #0d1427;
            .footerHeadOverlayBox2Layer{
                background: white;
                width: 102%;
                height: 100%;
                margin-left: -2px;
                border-bottom-left-radius: 50px;
            }
        }
    }
    .footerMain {
        width: 100%;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-top: 100px;
        .footerCol1 {
            flex: 0 0 30%;
            display: flex;
            flex-direction: column;
            @media (max-width: 768px) {
                flex: 100%;
            }
            .footerLogo {
                width: 250px;
                img {
                    width: 100%;
                    height: 100%;
                    -o-object-fit: contain;
                    object-fit: contain;
                }
            }
            p {
                font-size: 16px;
                margin-top: 20px;
                text-align: left;
                color: white;
                opacity: .7;
                line-height: 1.5;
            }
            .footerTiming{
                width: 100%;
                margin-top: 40px;
                display: flex;
                flex-direction: column;
                span{
                    font-size: 16px;
                    color: var(--primaryColor);
                    text-align: left;
                }
                ul{
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    margin-top: 20px;
                    gap: 15px;
                    li{
                        list-style: none;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        .footerTimingCol1{
                            flex: 0 0 35%;
                            font-size: 16px;
                            color: white;
                            text-align: left;
                        }
                        .footerTimingCol2{
                            flex: 0 0 60%;
                            font-size: 16px;
                            color: white;
                            text-align: left;
                        }
                    }
                }
            }
            .footerSocialLinks {
                display: flex;
                align-items: center;
                gap: 20px;
                margin-top: 40px;
                flex-wrap: wrap;
                span{
                    font-size: 16px;
                    color: var(--primaryColor);
                    text-align: left;
                    width: 100%;
                }
                a {
                    text-decoration: none;
                    border-radius: 10px;
                    font-size: 22px;
                    width: 40px;
                    height: 40px;
                    color: white;
                    position: relative;
                    z-index: 0;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background: var(--gradientBgColor);
                    &:hover::before {
                        opacity: 0;
                        transition: 0.3s;
                    }
                    &::after {
                        position: absolute;
                        content: "";
                        width: calc(100% + 6px);
                        height: calc(100% + 6px);
                        background: var(--gradientBgColor);
                        z-index: -2;
                        border-radius: 10px;
                    }
                    &::before {
                        position: absolute;
                        content: "";
                        width: 100%;
                        height: 100%;
                        background: #0d1427;
                        z-index: -1;
                        border-radius: 8px;
                        transition: 0.3s;
                    }
                }
            }
        }
        .footerCol2 {
            flex: 0 0 30%;
            display: flex;
            flex-direction: column;
            @media (max-width: 768px) {
                flex: 100%;
                margin-top: 50px;
            }
            h2 {
                color: white;
                font-size: 22px;
                text-align: left;
            }
            ul {
                width: 100%;
                margin-top: 30px;
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
                row-gap: 20px;
                li {
                    flex: 0 0 48%;
                    list-style: none;
                    display: flex;
                    align-items: center;
                    @media (max-width: 1200px) {
                        flex: 100%;
                        &:nth-of-type(2) {
                            order: 5;
                        }
                        &:nth-of-type(4) {
                            order: 6;
                        }
                    }
                    span {
                        width: 8px;
                        height: 8px;
                        background: var(--gradientBgColor);
                        border-radius: 10px;
                        margin-right: 10px;
                    }
                    a {
                        text-decoration: none;
                        color: white;
                        font-size: 18px;
                        text-align: left;
                        &:hover {
                            text-decoration: underline;
                        }
                    }
                }
            }
            .footerTiming{
                width: 100%;
                margin-top: 40px;
                display: flex;
                flex-direction: column;
                span{
                    font-size: 16px;
                    color: var(--primaryColor);
                    text-align: left;
                }
                ul{
                    width: 100%;
                    display: flex;
                    flex-wrap: wrap;
                    margin-top: 20px;
                    gap: 15px;
                    li{
                        flex: 100% !important;
                        list-style: none;
                        display: flex;
                        align-items: center;
                        .footerTimingCol1{
                            flex: 0 0 35%;
                            font-size: 16px;
                            color: white;
                            text-align: left;
                        }
                        .footerTimingCol2{
                            flex: 0 0 60%;
                            font-size: 16px;
                            color: white;
                            text-align: left;
                        }
                    }
                }
            }
        }
        .footerCol3 {
            flex: 0 0 30%;
            display: flex;
            flex-direction: column;
            @media (max-width: 1200px) {
                flex: 0 0 47%;
                margin-top: 50px;
            }
            @media (max-width: 768px) {
                flex: 100%;
            }
            h2 {
                color: white;
                font-size: 22px;
                text-align: left;
            }
            ul {
                width: 100%;
                margin-top: 30px;
                display: flex;
                flex-direction: column;
                gap: 30px;
                li {
                    list-style: none;
                    display: flex;
                    justify-content: space-between;
                    align-items: flex-start;
                    .footerCol3Icon {
                        width: 30px;
                        font-size: 20px;
                        line-height: 0.5;
                        color: var(--secondaryColor);
                        margin-top: 0px;
                    }
                    .footerCol3Content {
                        width: calc(100% - 40px);
                        display: flex;
                        flex-direction: column;
                        span {
                            color: var(--primaryColor);
                            font-size: 18px;
                            text-align: left;
                        }
                        p {
                            font-size: 18px;
                            color: white;
                            margin-top: 10px;
                            text-align: left;
                            line-height: 1.5;
                        }
                        a {
                            text-decoration: none;
                            font-size: 18px;
                            color: white;
                            margin-top: 7px;
                            text-align: left;
                            &:hover {
                                text-decoration: underline;
                            }
                        }
                    }
                }
            }
        }
    }
    .footerStrip {
        border-top: 1px solid #3b3b3b;
        margin-top: 50px;
        padding-top: 50px;
        width: 100%;
        .footerStripMain {
            width: 100%;
            display: flex;
            justify-content: space-between;
            @media(max-width:480px){
                flex-wrap: wrap;
            }
            .footerStripMainCol1 {
                width: 100%;
                @media (max-width: 680px) {
                    width: 100%;
                    align-items: center;
                    justify-content: center;
                }
                p {
                    font-size: 14px;
                    color: white;
                    text-align: left;
                    @media (max-width: 480px) {
                        text-align: center;
                    }
                }
            }
            .footerStripMainCol2 {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 20px;
                @media (max-width: 680px) {
                    width: 100%;
                    justify-content: center;
                    margin-top: 10px;
                }
                a {
                    text-decoration: none;
                    color: white;
                    font-size: 14px;
                    text-align: left;
                    &:hover {
                      text-decoration: underline;
                    }
                }
                p {
                    font-size: 14px;
                    color: white;
                    text-align: left;
                }
            }
            .footerStripMainCol3 {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                @media (max-width: 480px) {
                    margin-top: 10px;
                    justify-content: center;
                }
                .desined {
                    display: flex;
                    align-items: center;
                    gap: 10px;
                }
                p {
                    font-size: 14px;
                    text-align: left;
                    color: white;
                }
                svg {
                    font-size: 14px;
                    color: white;
                }
                a {
                    text-decoration: none;
                    width: 60px;
                    img {
                        width: 100%;
                        height: 100%;
                        -o-object-fit: contain;
                        object-fit: contain;
                        filter: grayscale(1);
                    }
                }
            }
        }
    }
}

// aboutBanner
#aboutBanner{
    width: 100%;
    margin-top: 80px;
    padding: 100px 0px;
    @media(max-width:480px){
        margin-top: 70px;
    }
    .aboutBannerMain{
        width: 100%;
        display: flex;
        flex-direction: column;
        h1{
            text-align: left;
            font-size: 3.5em;
            line-height: 1.3;
            width: 60%;
            @media(max-width:1024px){
                font-size: 3em;
                width: 80%;
            }
            @media(max-width:768px){
                font-size: 2.7em;
                width: 100%;
            }
            @media(max-width:480px){
                font-size: 2.5em;
            }
        }
        p{
            font-size: 20px;
            text-align: left;
            margin-top: 10px;
            line-height: 1.5;
            width: 50%;
            @media(max-width:1800px){
                width: 60%;
            }
            @media(max-width:1024px){
                width: 80%;
            }
            @media(max-width:768px){
                font-size:18px;
                width: 100%;
            }
        }
        .aboutBannerThumbnail{
            width: 100%;
            margin-top: 40px;
            height: 800px;
            background: #eee;
            border-radius: 20px;
            overflow: hidden;
            position: relative;
            @media(max-width:1800px){
                height: 600px;
            }
            @media(max-width:1024px){
                height: 500px;
            }
            @media(max-width:768px){
                height: 400px;
            }
            @media(max-width:480px){
                height: 250px;
            }
            .aboutBannerThumbnailShape1{
                position: absolute;
                top: -6px;
                right: -6px;
                width: 50%;
                z-index: 1;
                @media(max-width:768px){
                    width: 80%;
                }
                img{
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
            }
            .aboutBannerThumbnailShape2{
                position: absolute;
                bottom: -13px;
                left: -9px;
                width: 50%;
                z-index: 1;
                @media(max-width:768px){
                    width: 80%;
                }
                img{
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
            }
            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }
}

// aboutDetailsSection
#aboutDetailsSection{
    width: 100%;
    padding-bottom: 100px;
    @media(max-width:480px){
        padding-bottom: 0;
    }
    .aboutDetailsSectionMain{
        width: 100%;
        display: flex;
        flex-direction: column;
        p{
            font-size: 30px;
            text-align: left;
            margin-top: 10px;
            line-height: 1.5;
            margin-top: 50px;
            &:nth-of-type(1){
                margin-top: 0;
            }
            @media(max-width:1800px){
                font-size: 24px;
            }
            @media(max-width:768px){
                font-size: 20px;
            }
            @media(max-width:480px){
                font-size: 18px;
            }
        }
        ul{
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            gap: 40px;
            margin-top: 50px;
            @media(max-width:1800px){
                gap: 20px;
            }
            li{
                list-style: none;
                width: calc(100% / 4 - 30px);
                display: flex;
                flex-direction: column;
                border-radius: 20px;
                border: 1px solid #BFD8AF;
                padding: 30px 20px;
                position: relative;
                z-index: 0;
                overflow: hidden;
                @media(max-width:1800px){
                    width: calc(100% / 4 - 15px);
                }
                @media(max-width:1024px){
                    width: calc(100% / 2 - 10px);
                }
                @media(max-width:480px){
                    width: 100%;
                }
                &::after{
                    position: absolute;
                    content: '';
                    width: 150px;
                    height: 150px;
                    border-radius: 50%;
                    background: #BFD8AF;
                    top: -85px;
                    right: -85px;
                    z-index: -1;
                }
                &::before{
                    position: absolute;
                    content: '';
                    width: 150px;
                    height: 150px;
                    border-radius: 50%;
                    background: #E8EFCF;
                    top: -45px;
                    right: -75px;
                    z-index: -2;
                }
                .aboutDetailsCol2{
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    h3{
                        text-align: left;
                        font-size: 25px;
                        font-family: myFont2;
                        background: var(--gradientBgColor);
                        background-clip: text;
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                    }
                    h4{
                        text-align: left;
                        margin-top: 15px;
                        font-size: 18px;
                        font-family: myFont3;
                        line-height: 1.5;
                    }
                }
            }
        }
    }
}

// aboutTeam
#aboutTeam{
    width: 100%;
    margin-top: 100px;
    background: var(--lightBgColor);
    position: relative;
    .aboutTeamHeadOverlay{
        width: 100%;
        display: flex;
        align-items: center;
        height: 80px;
        background: white;
        .aboutTeamHeadOverlayBox1{
            width: 65%;
            height: 100%;
            border-top-right-radius: 50px;
            background: var(--lightBgColor);
        }
        .aboutTeamHeadOverlayBox2{
            width: 35%;
            height: 100%;
            background: var(--lightBgColor);
            .aboutTeamHeadOverlayBox2Layer{
                background: white;
                width: 100%;
                height: 100%;
                border-bottom-left-radius: 50px;
            }
        }
    }
    .aboutTeamFooterOverlay{
        width: 100%;
        display: flex;
        align-items: center;
        height: 80px;
        background: white;
        margin-top: 100px;
        .aboutTeamFooterOverlayBox1{
            width: 35%;
            height: 100%;
            background: var(--lightBgColor);
            .aboutTeamFooterOverlayBox1Layer{
                background: white;
                width: 100%;
                height: 100%;
                border-top-right-radius: 50px;
            }
        }
        .aboutTeamFooterOverlayBox2{
            width: 65%;
            height: 100%;
            background: var(--lightBgColor);
            border-bottom-left-radius: 50px;
        }
    }
    .aboutTeamTitle{
        width: 100%;
        display: flex;
        flex-direction: column;
        margin-top: 50px;
        h2{
            text-align: left;
            font-size: 3.5em;
            line-height: 1.3;
            width: 60%;
            @media(max-width:1800px){
                width: 80%;
            }
            @media(max-width:1024px){
                font-size: 3em;
                width: 100%;
            }
            @media(max-width:768px){
                font-size: 2.7em;
            }
            @media(max-width:480px){
                font-size: 2.5em;
            }
        }
        p{
            font-size: 20px;
            text-align: left;
            margin-top: 10px;
            line-height: 1.5;
            width: 60%;
            @media(max-width:1800px){
                width: 70%;
            }
            @media(max-width:768px){
                font-size: 18px;
                width: 100%;
            }
        }
    }
    .aboutTeamMain{
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        gap: 40px;
        margin-top: 50px;
        @media(max-width:1800px){
            gap: 20px;
        }
        .aboutTeamBox{
            width: calc(100% / 4 - 30px);
            display: flex;
            flex-direction: column;
            background: white;
            border-radius: 20px;
            @media(max-width:1800px){
                width: calc(100% / 4 - 15px);
            }
            @media(max-width:1024px){
                width: calc(100% / 3 - 14px);
            }
            @media(max-width:768px){
                width: calc(100% / 2 - 10px);
            }
            @media(max-width:480px){
                width: 100%;
            }
            .aboutTeamBoxThumbnail{
                width: 100%;
                height: 350px;
                background-color: #eee;
                border-radius: 20px;
                overflow: hidden;
                @media(max-width:1800px){
                    height: 320px;
                }
                img{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
            .aboutTeamBoxProfile{
                width: 100%;
                padding: 20px;
                display: flex;
                flex-direction: column;
                h3{
                    font-size: 20px;
                    text-align: left;
                }
                h4{
                    font-size: 16px;
                    text-align: left;
                    margin-top: 4px;
                    font-family: myFont3;
                }
            }
        }
        .loaded{
            .aboutTeamBoxThumbnail{
                position: relative;
                z-index: 0;
                &::after{
                    position: absolute;
                    content: '';
                    height: 100%;
                    width: 100%;
                    top: 0;
                    left: -100%;
                    z-index: -1;
                    background: #dedede;
                    box-shadow: 0 0 50px #00000024;
                    filter: blur(15px);
                    opacity: .5;
                    animation: abtLazyLoading1 1.5s linear infinite;
                    @keyframes abtLazyLoading1 {
                        0%{
                            left: -100%;
                        }
                        100%{
                            left: 100%;
                        }
                    }
                }
                img{
                    display: none;
                }
            }
            .aboutTeamBoxProfile{
                h3{
                    position: relative;
                    overflow: hidden;
                    &::after{
                        position: absolute;
                        content: '';
                        width: 100%;
                        height: 100%;
                        background: #eee;
                        border-radius: 5px;
                        z-index: 1;
                        left: 0;
                        top: 0;
                    }
                    &::before{
                        position: absolute;
                        content: '';
                        width: 100%;
                        height: 100%;
                        background: #eee;
                        border-radius: 5px;
                        z-index: 2;
                        left: 0;
                        top: 0;
                        background: #dedede;
                        box-shadow: 0 0 50px #00000024;
                        filter: blur(15px);
                        opacity: .5;
                        animation: lazyLoading2 1.5s linear infinite;
                        @keyframes lazyLoading2 {
                            0%{
                                left: -100%;
                            }
                            100%{
                                left: 100%;
                            }
                        }
                    }
                }
                h4{
                    position: relative;
                    overflow: hidden;
                    &::after{
                        position: absolute;
                        content: '';
                        width: 100%;
                        height: 100%;
                        background: #eee;
                        border-radius: 5px;
                        z-index: 1;
                        left: 0;
                        top: 0;
                    }
                    &::before{
                        position: absolute;
                        content: '';
                        width: 100%;
                        height: 100%;
                        background: #eee;
                        border-radius: 5px;
                        z-index: 2;
                        left: 0;
                        top: 0;
                        background: #dedede;
                        box-shadow: 0 0 50px #00000024;
                        filter: blur(15px);
                        opacity: .5;
                        animation: lazyLoading3 1.5s linear infinite;
                        @keyframes lazyLoading3 {
                            0%{
                                left: -100%;
                            }
                            100%{
                                left: 100%;
                            }
                        }
                    }
                }
            }
        }
    }
}

// aboutValues
#aboutValues{
    width: 100%;
    padding: 100px 0px;
    .aboutValuesMain{
        width: 100%;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        .aboutValuesThumbnail{
            flex: 0 0 40%;
            @media(max-width:1024px){
                flex: 100%;
            }
            .aboutValuesThumbnailBox{
                width: 100%;
                height: 700px;
                border-radius: 20px;
                overflow: hidden;
                background: #eee;
                @media(max-width:1024px){
                    height: 500px;
                }
                @media(max-width:768px){
                    height: 400px;
                }
                @media(max-width:480px){
                    height: 250px;
                }
                img{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }
        .loaded{
            .aboutValuesThumbnailBox{
                position: relative;
                z-index: 0;
                &::after{
                    position: absolute;
                    content: '';
                    height: 100%;
                    width: 100%;
                    top: 0;
                    left: -100%;
                    z-index: -1;
                    background: #dedede;
                    box-shadow: 0 0 50px #00000024;
                    filter: blur(15px);
                    opacity: .5;
                    animation: abtLazyLoading1 1.5s linear infinite;
                    @keyframes abtLazyLoading1 {
                        0%{
                            left: -100%;
                        }
                        100%{
                            left: 100%;
                        }
                    }
                }
                img{
                    display: none;
                }
            }
        }
        .aboutValuesDetails{
            flex: 0 0 50%;
            display: flex;
            flex-direction: column;
            @media(max-width:1800px){
                flex: 0 0 55%;
            }
            @media(max-width:1024px){
                flex: 100%;
                margin-top: 40px;
            }
            h4{
                font-size: 16px;
                width: fit-content;
                padding: 12px 30px;
                border-radius: 50px;    
                background: var(--lightBgColor);
                color: var(--thirdColor);
            }
            h2{
                text-align: left;
                font-size: 3.5em;
                margin-top: 20px;
                line-height: 1.3;
                @media(max-width:1024px){
                    font-size: 3em;
                }
                @media(max-width:768px){
                    font-size: 2.7em;
                }
                @media(max-width:480px){
                    font-size: 2.5em;
                }
            }
            p{
                font-size: 20px;
                text-align: left;
                margin-top: 30px;
                line-height: 1.5;
                @media(max-width:768px){
                    font-size: 18px;
                }
            }
        }
    }
}

// aboutMissionVision
#aboutMissionVision{
    width: 100%;
    padding: 100px 0px;
    @media(max-width:1800px){
        padding-top: 50px;
    }
    .aboutMissionVisionMain{
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        gap: 40px;
        border-radius: 20px;
        align-items: flex-start;
        .aboutMissionVisionBox{
            width: calc(100% / 2 - 20px);
            display: flex;
            flex-direction: column;
            border-radius: 20px;
            background: var(--primaryColor);
            @media(max-width:768px){
                width: 100%;
            }
            .aboutMissionVisionBoxDetails{
                padding: 30px;
                display: flex;
                flex-direction: column;
                @media(max-width:480px){
                    padding: 20px;
                }
                h2{
                    text-align: left;
                    font-size: 2.5em;
                    line-height: 1.3;
                    color: white;
                }
                p{
                    font-size: 20px;
                    text-align: left;
                    margin-top: 10px;
                    line-height: 1.5;
                    color: white;
                    @media(max-width:768px){
                        font-size: 18px;
                    }
                }
            }
            .aboutMissionVisionBoxThumbnail{
                width: 100%;
                height: 350px;
                background: #eee;
                border-radius: 20px;
                overflow: hidden;
                @media(max-width:480px){
                    height: 250px;
                }
                .aboutMissionVisionBoxThumbnailBox{
                    width: 100%;
                    height: 100%;
                    img{
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
            }
            .loaded{
                .aboutMissionVisionBoxThumbnailBox{
                    position: relative;
                    z-index: 0;
                    &::after{
                        position: absolute;
                        content: '';
                        height: 100%;
                        width: 100%;
                        top: 0;
                        left: -100%;
                        z-index: -1;
                        background: #dedede;
                        box-shadow: 0 0 50px #00000024;
                        filter: blur(15px);
                        opacity: .5;
                        animation: abtLazyLoading1 1.5s linear infinite;
                        @keyframes abtLazyLoading1 {
                            0%{
                                left: -100%;
                            }
                            100%{
                                left: 100%;
                            }
                        }
                    }
                    img{
                        display: none;
                    }
                }
            }
        }
    }
}

// innerServiceBanner
#innerServiceBanner{
    width: 100%;
    padding: 100px 0px;
    margin-top: 80px;
    background: #faf5f0;
    border-radius: 0px 0px 50px 50px;
    @media(max-width:480px){
        margin-top: 70px;
        padding-top: 50px;
    }
    .innerServiceBannerMain{
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        @media(max-width:1024px){
            flex-wrap: wrap;
        }
        .innerServiceBannerDetails{
            flex: 0 0 45%;
            display: flex;
            flex-direction: column;
            @media(max-width:1024px){
                flex: 100%;
                order: 2;
                margin-top: 40px;
            }
            h1{
                text-align: left;
                font-size: 3.5em;
                line-height: 1.3;
                @media(max-width:1024px){
                    font-size: 3em;
                }
                @media(max-width:768px){
                    font-size: 2.7em;
                }
                @media(max-width:480px){
                    font-size: 2.5em;
                }
            }
            p{
                font-size: 20px;
                text-align: left;
                margin-top: 10px;
                line-height: 1.5;
                @media(max-width:768px){
                    font-size: 18px;
                }
            }
            a{
                text-decoration: none;
                border: 1px solid var(--secondaryColor);
                background: var(--secondaryColor);
                font-size: 18px;
                border-radius: 50px;
                padding: 12px 30px;
                transition: .3s;
                width: fit-content;
                margin-top: 30px;
                color: white;
                @media(max-width:1800px){
                    font-size: 16px;
                }
                &:hover{
                    transition: .3s;
                    border-color: var(--thirdColor);
                    background: var(--thirdColor);
                    color: white;
                }
            }
        }
        .innerServiceBannerThumbnail{
            flex: 0 0 45%;
            background: #eee;
            border-radius: 10px;
            height: 400px;
            overflow: hidden;
            @media(max-width:1024px){
                flex: 100%;
                order: 1;
            }
            @media(max-width:480px){
                height: 300px;
            }
            .innerServiceBannerThumbnailBox{
                width: 100%;
                height: 100%;
                img{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }
        .loaded{
            .innerServiceBannerThumbnailBox{
                position: relative;
                z-index: 0;
                &::after{
                    position: absolute;
                    content: '';
                    height: 100%;
                    width: 100%;
                    top: 0;
                    left: -100%;
                    z-index: -1;
                    background: #dedede;
                    box-shadow: 0 0 50px #00000024;
                    filter: blur(15px);
                    opacity: .5;
                    animation: abtLazyLoading1 1.5s linear infinite;
                    @keyframes abtLazyLoading1 {
                        0%{
                            left: -100%;
                        }
                        100%{
                            left: 100%;
                        }
                    }
                }
                img{
                    display: none;
                }
            }
        }
    }
}

// innerServicePage
#innerServicePage{
    width: 100%;
    padding: 100px 0px;
}

// blogBanner
#blogBanner{
    width: 100%;
    margin-top: 80px;
    padding: 30px;
    position: relative;
    @media(max-width:480px){
        margin-top: 70px;
    }
    .blogBannerMain{
        width: 100%;
        padding: 50px 130px 100px 130px;
        background: paleturquoise;
        border-top-right-radius: 30px;
        border-bottom-left-radius: 30px;
        @media(max-width:1800px){
            padding: 50px 70px 100px 70px;
        }
        @media(max-width:768px){
            padding: 30px 50px 60px 50px;
        }
        @media(max-width:480px){
            padding: 30px;
        }
        h1{
            text-align: left;
            font-size: 3.5em;
            line-height: 1.3;
            @media(max-width:1024px){
                font-size: 3em;
            }
            @media(max-width:768px){
                font-size: 2.7em;
            }
            @media(max-width:480px){
                font-size: 2.5em;
            }
        }
        p{
            font-size: 20px;
            text-align: left;
            margin-top: 30px;
            line-height: 1.5;
            width: 85%;
            @media(max-width:768px){
                font-size:18px;
                width: 100%;
            }
        }
    }
    .blogBannerHeadOverlay{
        width: 100%;
        display: flex;
        align-items: center;
        height: 80px;
        background: white;
        @media(max-width:480px){
            height: 40px;
        }
        .blogBannerHeadOverlayBox1{
            width: 65%;
            height: 100%;
            border-top-right-radius: 50px;
            border-top-left-radius: 30px;
            background: paleturquoise;
            @media(max-width:480px){
                border-top-right-radius: 25px;
                border-top-left-radius: 20px;
            }
        }
        .blogBannerHeadOverlayBox2{
            width: 35%;
            height: 100%;
            background: paleturquoise;
            .blogBannerHeadOverlayBox2Layer{
                background: white;
                width: 100%;
                height: 100%;
                border-bottom-left-radius: 50px;
                @media(max-width:480px){
                    border-bottom-left-radius: 25px;
                }
            }
        }
    }
    .blogBannerFooterOverlay{
        width: 100%;
        display: flex;
        align-items: center;
        height: 80px;
        background: white;
        @media(max-width:480px){
            height: 40px;
        }
        .blogBannerFooterOverlayBox1{
            width: 35%;
            height: 100%;
            background: paleturquoise;
            .blogBannerFooterOverlayBox1Layer{
                background: white;
                width: 100%;
                height: 100%;
                border-top-right-radius: 50px;
                @media(max-width:480px){
                    border-top-right-radius: 25px;
                }
            }
        }
        .blogBannerFooterOverlayBox2{
            width: 65%;
            height: 100%;
            background: paleturquoise;
            border-bottom-left-radius: 50px;
            border-bottom-right-radius: 30px;
            @media(max-width:480px){
                border-bottom-left-radius: 25px;
                border-bottom-right-radius: 20px;
            }
        }
    }
}

// blogList
#blogList{
    width: 100%;
    padding: 100px 0px;
    .blogListMain{
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        gap: 40px;
        @media(max-width:1800px){
            gap: 20px;
        }
        .HomeBlogBox{
            width: calc(100% / 4 - 30px);
            display: flex;
            flex-direction: column;
            transition: .3s;
            text-decoration: none;
            box-shadow: 0 0 20px #00000010;
            border-radius: 10px;
            @media(max-width:1800px){
                width: calc(100% / 4 - 15px);
            }
            @media(max-width:1024px){
                width: calc(100% / 3 - 14px);
            }
            @media(max-width:768px){
                width: calc(100% / 2 - 10px);
            }
            @media(max-width:480px){
                width: 100%;
            }
            &:hover{
                transition: .3s;
                transform: scale(.95);
                .HomeBlogThumbnail{
                    img{
                        transform: scale(1.05);
                        transition: .3s;
                    }
                }
            }
            .HomeBlogThumbnail{
                width: 100%;
                height: 200px;
                background: #eee;
                overflow: hidden;
                border-radius: 10px;
                @media(max-width:1800px){
                    height: 180px;
                }
                img{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    transition: .3s;
                }
            }
            .HomeBlogDetails{
                width: 100%;
                display: flex;
                flex-direction: column;
                padding: 20px;
                .homeBlogDate{
                    text-align: left;
                    color: var(--secondaryColor);
                }
                h3{
                    color: #343434;
                    text-align: left;
                    font-size: 25px;
                    margin-top: 15px;
                    line-height: 1.3;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    @media(max-width:1800px){
                        font-size: 22px;
                    }
                }
                p{
                    font-size: 16px;
                    margin-top: 15px;
                    line-height: 1.5;
                    text-align: left;
                    opacity: .7;
                    color: #343434;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    @media(max-width:1800px){
                        margin-top: 10px;
                    }
                }
            }
        }
        .loaded{
            .HomeBlogThumbnail{
                position: relative;
                z-index: 0;
                &::after{
                    position: absolute;
                    content: '';
                    height: 100%;
                    width: 100%;
                    top: 0;
                    left: -100%;
                    z-index: -1;
                    background: #dedede;
                    box-shadow: 0 0 50px #00000024;
                    filter: blur(15px);
                    opacity: .5;
                    animation: lazyLoading1 1.5s linear infinite;
                    @keyframes lazyLoading1 {
                        0%{
                            left: -100%;
                        }
                        100%{
                            left: 100%;
                        }
                    }
                }
                img{
                    display: none;
                }
            }
            .HomeBlogDetails{
                h3{
                    position: relative;
                    overflow: hidden;
                    &::after{
                        position: absolute;
                        content: '';
                        width: 100%;
                        height: 100%;
                        background: #eee;
                        border-radius: 5px;
                        z-index: 1;
                        left: 0;
                        top: 0;
                    }
                    &::before{
                        position: absolute;
                        content: '';
                        width: 100%;
                        height: 100%;
                        background: #eee;
                        border-radius: 5px;
                        z-index: 2;
                        left: 0;
                        top: 0;
                        background: #dedede;
                        box-shadow: 0 0 50px #00000024;
                        filter: blur(15px);
                        opacity: .5;
                        animation: lazyLoading2 1.5s linear infinite;
                        @keyframes lazyLoading2 {
                            0%{
                                left: -100%;
                            }
                            100%{
                                left: 100%;
                            }
                        }
                    }
                }
                p{
                    position: relative;
                    overflow: hidden;
                    &::after{
                        position: absolute;
                        content: '';
                        width: 100%;
                        height: 100%;
                        background: #eee;
                        border-radius: 5px;
                        z-index: 1;
                        left: 0;
                        top: 0;
                    }
                    &::before{
                        position: absolute;
                        content: '';
                        width: 100%;
                        height: 100%;
                        background: #eee;
                        border-radius: 5px;
                        z-index: 2;
                        left: 0;
                        top: 0;
                        background: #dedede;
                        box-shadow: 0 0 50px #00000024;
                        filter: blur(15px);
                        opacity: .5;
                        animation: lazyLoading3 1.5s linear infinite;
                        @keyframes lazyLoading3 {
                            0%{
                                left: -100%;
                            }
                            100%{
                                left: 100%;
                            }
                        }
                    }
                }
                .homeBlogDate{
                    position: relative;
                    overflow: hidden;
                    border-color: #eee;
                    width: fit-content;
                    &::after{
                        position: absolute;
                        content: '';
                        width: 100%;
                        height: 100%;
                        background: #eee;
                        border-radius: 5px;
                        z-index: 1;
                        left: 0;
                        top: 0;
                    }
                    &::before{
                        position: absolute;
                        content: '';
                        width: 100%;
                        height: 100%;
                        background: #eee;
                        border-radius: 5px;
                        z-index: 2;
                        left: 0;
                        top: 0;
                        background: #dedede;
                        box-shadow: 0 0 50px #00000024;
                        filter: blur(15px);
                        opacity: .5;
                        animation: lazyLoading2 1.5s linear infinite;
                        @keyframes lazyLoading2 {
                            0%{
                                left: -100%;
                            }
                            100%{
                                left: 100%;
                            }
                        }
                    }
                }
            }
        }
    }
}

// innerBlogDetails
#innerBlogDetails{
    width: 100%;
    margin-top: 80px;
    padding: 50px 0px 100px 0px;
    background: var(--lightBgColor);
    @media(max-width:480px){
        margin-top: 70px;
    }
    .innerBlogDetailsMain{
        width: 100%;
        display: flex;
        flex-direction: column;
        padding: 80px 15%;
        background: white;
        border-radius: 20px;
        @media(max-width:1800px){
            padding: 80px 10%;
        }
        @media(max-width:1024px){
            padding: 50px 5%;
        }
        .backBtn{
            display: flex;
            align-items: center;
            gap: 10px;
            cursor: pointer;
            width: fit-content;
            font-size: 16px;
            transition: .3s;
            background: #00000010;
            padding: 10px 30px;
            border-radius: 10px;
            &:hover{
                transition: .3s;
                color: var(--primaryColor);
                background: #00000007;
            }
            svg{
                width: 20px;
                height: 20px;
            }
        }
        h1{
            text-align: left;
            font-size: 3.5em;
            line-height: 1.3;
            margin-top: 40px;
            @media(max-width:1024px){
                font-size: 3em;
            }
            @media(max-width:768px){
                font-size: 2.7em;
            }
            @media(max-width:480px){
                font-size: 2.5em;
            }
        }
        p{
            font-size: 20px;
            text-align: left;
            margin-top: 30px;
            line-height: 1.5;
            @media(max-width:768px){
                font-size: 18px;
            }
        }
        img{
            width: 100%;
            height: 100%;
            object-fit: contain;
            margin-top: 30px;
        }
    }
}

// relatedBlogs
#relatedBlogs{
    width: 100%;
    padding-bottom: 100px;
    .relatedBlogsHeadOverlay{
        width: 100%;
        display: flex;
        align-items: center;
        height: 80px;
        background: white;
        .relatedBlogsHeadOverlayBox1{
            width: 35%;
            height: 100%;
            background: var(--lightBgColor);
            .relatedBlogsHeadOverlayBox2Layer{
                background: white;
                width: 100%;
                height: 100%;
                border-top-right-radius: 50px;
            }
        }
        .relatedBlogsHeadOverlayBox2{
            width: 65%;
            height: 100%;
            background: var(--lightBgColor);
            border-bottom-left-radius: 50px;
            border-bottom-right-radius: 30px;
        }
    }
    .relatedBlogTitle{
        width: 100%;
        margin-top: 100px;
        display: flex;
        flex-direction: column;
        h2{
            text-align: left;
            font-size: 3.5em;
            line-height: 1.3;
            @media(max-width:1024px){
                font-size: 3em;
            }
            @media(max-width:768px){
                font-size: 2.7em;
            }
            @media(max-width:480px){
                font-size: 2.5em;
            }
        }
        p{
            font-size: 20px;
            text-align: left;
            margin-top: 30px;
            line-height: 1.5;
            width: 50%;
            @media(max-width:1800px){
                width: 60%;
            }
            @media(max-width:1024px){
                width: 80%;
            }
            @media(max-width:768px){
                font-size: 18px;
                width: 100%;
            }
        }
    }
    .relatedBlogsMain{
        width: 100%;
        margin-top: 80px;
        .HomeBlogBox{
            width: 100%;
            display: flex;
            flex-direction: column;
            background: white;
            overflow: hidden;
            text-decoration: none;
            border-radius: 10px;
            transition: .3s;
            &:hover{
                transition: .3s;
                transform: scale(.95);
                .HomeBlogThumbnail{
                    img{
                        transform: scale(1.05);
                        transition: .3s;
                    }
                }
            }
            .HomeBlogThumbnail{
                width: 100%;
                height: 200px;
                background: #eee;
                overflow: hidden;
                border-radius: 10px;
                @media(max-width:1800px){
                    height: 180px;
                }
                img{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    transition: .3s;
                }
            }
            .HomeBlogDetails{
                width: 100%;
                display: flex;
                flex-direction: column;
                padding: 20px;
                .homeBlogDate{
                    text-align: left;
                    color: var(--secondaryColor);
                }
                h3{
                    color: #343434;
                    text-align: left;
                    font-size: 25px;
                    margin-top: 15px;
                    line-height: 1.3;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                }
                p{
                    font-size: 16px;
                    margin-top: 15px;
                    line-height: 1.5;
                    text-align: left;
                    opacity: .7;
                    color: #343434;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                }
            }
        }
        .loaded{
            .HomeBlogThumbnail{
                position: relative;
                z-index: 0;
                &::after{
                    position: absolute;
                    content: '';
                    height: 100%;
                    width: 100%;
                    top: 0;
                    left: -100%;
                    z-index: -1;
                    background: #dedede;
                    box-shadow: 0 0 50px #00000024;
                    filter: blur(15px);
                    opacity: .5;
                    animation: lazyLoading1 1.5s linear infinite;
                    @keyframes lazyLoading1 {
                        0%{
                            left: -100%;
                        }
                        100%{
                            left: 100%;
                        }
                    }
                }
                img{
                    display: none;
                }
            }
            .HomeBlogDetails{
                h3{
                    position: relative;
                    overflow: hidden;
                    &::after{
                        position: absolute;
                        content: '';
                        width: 100%;
                        height: 100%;
                        background: #eee;
                        border-radius: 5px;
                        z-index: 1;
                        left: 0;
                        top: 0;
                    }
                    &::before{
                        position: absolute;
                        content: '';
                        width: 100%;
                        height: 100%;
                        background: #eee;
                        border-radius: 5px;
                        z-index: 2;
                        left: 0;
                        top: 0;
                        background: #dedede;
                        box-shadow: 0 0 50px #00000024;
                        filter: blur(15px);
                        opacity: .5;
                        animation: lazyLoading2 1.5s linear infinite;
                        @keyframes lazyLoading2 {
                            0%{
                                left: -100%;
                            }
                            100%{
                                left: 100%;
                            }
                        }
                    }
                }
                p{
                    position: relative;
                    overflow: hidden;
                    &::after{
                        position: absolute;
                        content: '';
                        width: 100%;
                        height: 100%;
                        background: #eee;
                        border-radius: 5px;
                        z-index: 1;
                        left: 0;
                        top: 0;
                    }
                    &::before{
                        position: absolute;
                        content: '';
                        width: 100%;
                        height: 100%;
                        background: #eee;
                        border-radius: 5px;
                        z-index: 2;
                        left: 0;
                        top: 0;
                        background: #dedede;
                        box-shadow: 0 0 50px #00000024;
                        filter: blur(15px);
                        opacity: .5;
                        animation: lazyLoading3 1.5s linear infinite;
                        @keyframes lazyLoading3 {
                            0%{
                                left: -100%;
                            }
                            100%{
                                left: 100%;
                            }
                        }
                    }
                }
                .homeBlogDate{
                    position: relative;
                    overflow: hidden;
                    border-color: #eee;
                    width: fit-content;
                    &::after{
                        position: absolute;
                        content: '';
                        width: 100%;
                        height: 100%;
                        background: #eee;
                        border-radius: 5px;
                        z-index: 1;
                        left: 0;
                        top: 0;
                    }
                    &::before{
                        position: absolute;
                        content: '';
                        width: 100%;
                        height: 100%;
                        background: #eee;
                        border-radius: 5px;
                        z-index: 2;
                        left: 0;
                        top: 0;
                        background: #dedede;
                        box-shadow: 0 0 50px #00000024;
                        filter: blur(15px);
                        opacity: .5;
                        animation: lazyLoading2 1.5s linear infinite;
                        @keyframes lazyLoading2 {
                            0%{
                                left: -100%;
                            }
                            100%{
                                left: 100%;
                            }
                        }
                    }
                }
            }
        }
        .slick-slide {
            padding: 0 10px;
        }
        
        .slick-list {
            margin: 0 -10px;
        }
        /* main.css */
        .slick-prev:before,
        .slick-next:before {
            content: '';
            display: none;
        }

        .slick-prev,
        .slick-next {
            width: 30px;
            height: 30px;
            z-index: 1;
        }

        .slick-prev {
            left: -35px; /* Adjust positioning */
            @media(max-width:480px){
                left: 0;
            }
        }

        .slick-next {
            right: -35px; /* Adjust positioning */
            @media(max-width:480px){
                right: 0;
            }
        }

        .slick-prev,
        .slick-next {
            background: var(--gradientBgColor);
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            svg{
                color: white;
                width: 60%;
                height: 60%;
            }
        }

        .slick-prev:hover,
        .slick-next:hover {
            background: white;
            svg{
                color: var(--primaryColor);
            }
        }
    }
}

// contactPage
#contactPage{
    margin-top: 80px;
    padding: 50px 0px 100px 0px;
    width: 100%;
    @media(max-width:480px){
        margin-top: 70px;
    }
    .contactPageTitle{
        width: 100%;
        display: flex;
        flex-direction: column;
        h1{
            text-align: left;
            font-size: 3.5em;
            line-height: 1.3;
            width: 60%;
            @media(max-width:1024px){
                font-size: 3em;
            }
            @media(max-width:768px){
                font-size: 2.7em;
            }
            @media(max-width:480px){
                font-size: 2.5em;
            }
        }
        p{
            font-size: 20px;
            text-align: left;
            margin-top: 10px;
            line-height: 1.5;
            width: 60%;
            @media(max-width:1800px){
                width: 70%;
            }
            @media(max-width:768px){
                font-size:18px;
                width: 100%;
            }
        }
    }
    .contactPageMain{
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-top: 80px;
        align-items: flex-start;
        .contactPageDetails{
            flex: 0 0 50%;
            display: flex;
            flex-wrap: wrap;
            gap: 40px;
            @media(max-width:1800px){
                gap: 20px;
                flex: 0 0 60%;
            }
            @media(max-width:1024px){
                flex: 100%;
            }
            .contactPageDetailsBox{
                width: calc(100% / 2 - 20px);
                display: flex;
                flex-direction: column;
                @media(max-width:1800px){
                    width: calc(100% / 2 - 10px);
                }
                @media(max-width:480px){
                    width: 100%;
                }
                .contactHeadOverlay{
                    width: 100%;
                    display: flex;
                    align-items: center;
                    height: 30px;
                    background: white;
                    .contactHeadOverlayBox1{
                        width: 65%;
                        height: 100%;
                        border-top-right-radius: 20px;
                        border-top-left-radius: 15px;
                        background: var(--lightBgColor);
                    }
                    .contactHeadOverlayBox2{
                        width: 35%;
                        height: 100%;
                        background: var(--lightBgColor);
                        .contactHeadOverlayBox2Layer{
                            background: white;
                            width: 100%;
                            height: 100%;
                            border-bottom-left-radius: 20px;
                        }
                    }
                }
                .contactFooterOverlay{
                    width: 100%;
                    display: flex;
                    align-items: center;
                    height: 30px;
                    background: white;
                    .contactFooterOverlayBox1{
                        width: 35%;
                        height: 100%;
                        background: var(--lightBgColor);
                        .contactFooterOverlayBox1Layer{
                            background: white;
                            width: 100%;
                            height: 100%;
                            border-top-right-radius: 20px;
                        }
                    }
                    .contactFooterOverlayBox2{
                        width: 65%;
                        height: 100%;
                        background: var(--lightBgColor);
                        border-bottom-left-radius: 20px;
                        border-bottom-right-radius: 15px;
                    }
                }
                .contactPageDetailsBoxMain{
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    padding: 0px 30px;
                    background: var(--lightBgColor);
                    border-radius: 0px 20px 0px 20px;
                    padding-bottom: 30px;
                    .contactPageDetailsBoxIcon{
                        text-align: left;
                        svg{
                            width: 60px;
                            height: 60px;
                            color: var(--secondaryColor);
                        }
                    }
                    h3{
                        text-align: left;
                        font-size: 1.5rem;
                        font-family: myFont3;
                        margin-top: 10px;
                    }
                    h4{
                        color: #00000069;
                        text-align: left;
                        font-size: 1.25rem;
                        margin-top: 20px;
                        @media(max-width:768px){
                            font-size: 16px;
                        }
                    }
                    p{
                        white-space: normal;
                        margin-top: 1rem;
                        font-size: 1.125rem;
                        font-weight: 300;
                        text-decoration: none;
                        text-align: left;
                        line-height: 1.5;
                    }
                    a{
                        color: black;
                        white-space: normal;
                        margin-top: 1rem;
                        font-size: 1.125rem;
                        font-weight: 300;
                        text-decoration: none;
                        text-align: left;
                        line-height: 1.5;
                    }
                }
            }
        }
        .contactPageForm{
            flex: 0 0 45%;
            // background: paleturquoise;
            @media(max-width:1800px){
                flex: 0 0 35%;
            }
            @media(max-width:1024px){
                flex: 100%;
                margin-top: 40px;
            }
            .contactHeadOverlay{
                width: 100%;
                display: flex;
                align-items: center;
                height: 50px;
                background: white;
                .contactHeadOverlayBox1{
                    width: 65%;
                    height: 100%;
                    border-top-right-radius: 30px;
                    border-top-left-radius: 20px;
                    background: paleturquoise;
                }
                .contactHeadOverlayBox2{
                    width: 35%;
                    height: 100%;
                    background: paleturquoise;
                    .contactHeadOverlayBox2Layer{
                        background: white;
                        width: 100%;
                        height: 100%;
                        border-bottom-left-radius: 30px;
                    }
                }
            }
            .contactFooterOverlay{
                width: 100%;
                display: flex;
                align-items: center;
                height: 50px;
                background: white;
                .contactFooterOverlayBox1{
                    width: 35%;
                    height: 100%;
                    background: paleturquoise;
                    .contactFooterOverlayBox1Layer{
                        background: white;
                        width: 100%;
                        height: 100%;
                        border-top-right-radius: 30px;
                    }
                }
                .contactFooterOverlayBox2{
                    width: 65%;
                    height: 100%;
                    background: paleturquoise;
                    border-bottom-left-radius: 30px;
                    border-bottom-right-radius: 20px;
                }
            }
            form{
                border-radius: 0px 20px 0px 20px;
                background: paleturquoise;
                padding: 40px;
                display: flex;
                flex-direction: column;
                @media(max-width:480px){
                    padding: 20px;
                }
                .formGroup{
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    margin-top: 20px;
                    &:nth-of-type(1){
                        margin-top: 0;
                    }
                    label{
                        font-size: 20px;
                        text-align: left;
                    }
                    input{
                        width: 100%;
                        height: 45px;
                        background: white;
                        border: 1px solid white;
                        outline: none;
                        margin-top: 4px;
                        padding: 0px 15px;
                        border-radius: 5px;
                        font-size: 20px;
                        &:focus{
                            border-color: var(--primaryColor);
                        }
                    }
                    textarea{
                        width: 100%;
                        height: 100px;
                        background: white;
                        border: 1px solid white;
                        outline: none;
                        margin-top: 4px;
                        padding: 15px 15px;
                        border-radius: 5px;
                        font-size: 20px;
                        &:focus{
                            border-color: var(--primaryColor);
                        }
                    }
                }
                .formBtnArea{
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                    margin-top: 30px;
                    button{
                        text-decoration: none;
                        color: white;
                        background: var(--thirdColor);
                        font-size: 18px;
                        cursor: pointer;
                        border-radius: 50px;
                        padding: 12px 50px;
                        transition: .3s;
                        position: relative;
                        overflow: hidden;
                        z-index: 0;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        border: none;
                        cursor: pointer;
                        @media(max-width:1800px){
                            font-size: 16px;
                        }
                        &::after{
                            position: absolute;
                            content: '';
                            width: 100%;
                            height: 100%;
                            z-index: -1;
                            background: var(--gradientBgColor);
                            transition: .3s;
                            border-radius: 50px;
                        }
                        &:hover{
                            &::after{
                                transform: scale(.5);
                                opacity: 0;
                                transition: .3s;
                            }
                        }
                    }
                }
            }
        }
    }
    .map{
        width: 100%;
        margin-top: 100px;
        border-radius: 20px;
        overflow: hidden;
        .mapMain{
            width: 100%;
            height: 400px;
            background: #eee;
            @media(max-width:480px){
                height: 300px;
            }
            iframe{
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }
}