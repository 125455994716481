@font-face {
  font-family: "myFont1";
  src: url("../font/PPTelegraf/PPTelegraf-Regular.otf");
}
@font-face {
  font-family: "myFont2";
  src: url("../font/PPTelegraf/PPTelegraf-UltraBold.otf");
}
@font-face {
  font-family: "myFont3";
  src: url("../font/PPTelegraf/PPTelegraf-UltraLight.otf");
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --primaryColor: #0382c3;
  --secondaryColor: #8ab652;
  --thirdColor: #337357;
  --fourthColor: #54ac9e;
  --gradientBgColor: linear-gradient(45deg, var(--primaryColor), var(--secondaryColor));
  --lightBgColor: #e3fff3;
}

body {
  color: #343434;
  font-family: "myFont1";
}

/* width */
::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

/* Track */
::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.048);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(117, 117, 117);
  border-radius: 5px;
}
::-webkit-scrollbar-thumb:hover {
  background: var(--primaryColor);
}

.container {
  width: calc(100% - 300px);
  margin: 0 auto;
}
@media (max-width: 1800px) {
  .container {
    width: calc(100% - 200px);
  }
}
@media (max-width: 1024px) {
  .container {
    width: calc(100% - 100px);
  }
}
@media (max-width: 480px) {
  .container {
    width: calc(100% - 32px);
  }
}

a,
article,
button,
div,
span,
i,
b,
h1,
h2,
h3,
h4,
h5,
h6,
p,
input,
ul,
li,
select,
label,
textarea {
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
  font-family: "myFont1";
}

input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
  font-family: "myFont1";
}

input {
  -webkit-tap-highlight-color: transparent;
}

::-webkit-datetime-edit-year-field:not([aria-valuenow]),
::-webkit-datetime-edit-month-field:not([aria-valuenow]),
::-webkit-datetime-edit-day-field:not([aria-valuenow]) {
  color: transparent;
}

video::-internal-media-controls-overlay-cast-button {
  display: none;
}

textarea {
  resize: none;
  font-family: "myFont1";
}

::-moz-selection {
  color: white;
  background: #1d1d1d;
}

::selection {
  color: white;
  background: #1d1d1d;
}

p {
  font-family: "myFont1";
}

main {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
}

.shimmer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 105;
  background: rgba(0, 0, 0, 0.486);
  display: none;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.shimmer.visible {
  display: block;
  opacity: 1;
}

nav {
  width: 100%;
  position: fixed;
  z-index: 20;
  top: 0;
  left: 0;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.0745098039);
}
nav::after {
  position: absolute;
  content: "";
  background: rgba(255, 255, 255, 0.848);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
}
nav .navMain {
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media (max-width: 480px) {
  nav .navMain {
    height: 70px;
  }
}
nav .navMain .navLogo {
  text-decoration: none;
  width: 150px;
  height: 70px;
  display: flex;
  text-decoration: none;
  align-items: center;
}
nav .navMain .navLogo img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
nav .navMain .navLogo h1 {
  font-size: 26px;
  color: black;
}
nav .navMain .navLink {
  display: flex;
  align-items: center;
  margin-left: auto;
}
@media (max-width: 1150px) {
  nav .navMain .navLink {
    display: none;
  }
}
nav .navMain .navLink ul {
  display: flex;
  align-items: center;
  gap: 80px;
}
@media (max-width: 1800px) {
  nav .navMain .navLink ul {
    gap: 60px;
  }
}
nav .navMain .navLink ul li {
  list-style: none;
}
nav .navMain .navLink ul li a {
  text-decoration: none;
  color: #343434;
  font-size: 18px;
  display: flex;
  transition: 0.3s;
}
@media (max-width: 1800px) {
  nav .navMain .navLink ul li a {
    font-size: 16px;
  }
}
nav .navMain .navLink ul li a:hover {
  transition: 0.3s;
  color: var(--primaryColor);
}
nav .navMain .navLink ul li .navLinkActive {
  font-family: myFont2;
  background: var(--gradientBgColor);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
nav .navMain .navContact {
  margin-left: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 1150px) {
  nav .navMain .navContact {
    margin-left: auto;
  }
}
@media (max-width: 480px) {
  nav .navMain .navContact {
    display: none;
  }
}
nav .navMain .navContact .navContactBtn {
  text-decoration: none;
  color: white;
  background: var(--thirdColor);
  font-size: 18px;
  cursor: pointer;
  border-radius: 50px;
  padding: 12px 30px;
  transition: 0.3s;
  position: relative;
  overflow: hidden;
  z-index: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (max-width: 1800px) {
  nav .navMain .navContact .navContactBtn {
    font-size: 16px;
  }
}
nav .navMain .navContact .navContactBtn::after {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  z-index: -1;
  background: var(--gradientBgColor);
  transition: 0.3s;
  border-radius: 50px;
}
nav .navMain .navContact .navContactBtn:hover::after {
  transform: scale(0.5);
  opacity: 0;
  transition: 0.3s;
}
nav .navMain .navBar {
  display: none;
  justify-content: center;
  align-items: center;
  margin-left: 60px;
}
@media (max-width: 1150px) {
  nav .navMain .navBar {
    display: flex;
  }
}
nav .navMain .navBar .navBarBox {
  width: 30px;
  display: flex;
  flex-direction: column;
  gap: 4px;
}
nav .navMain .navBar .navBarBox span {
  width: 100%;
  height: 2px;
  background: #343434;
}

.sidemenu {
  position: fixed;
  z-index: 300;
  width: 500px;
  height: 100vh;
  background: white;
  right: -110%;
  top: 0;
  transition: 0.3s;
}
@media (max-width: 480px) {
  .sidemenu {
    width: 100%;
  }
}
.sidemenu .closeSidemenu {
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
}
.sidemenu .closeSidemenu svg {
  width: 30px;
  height: 30px;
}
.sidemenu .sidemenuLink {
  width: 100%;
  margin-top: 100px;
}
.sidemenu .sidemenuLink ul {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.sidemenu .sidemenuLink ul li {
  list-style: none;
}
.sidemenu .sidemenuLink ul li a {
  text-decoration: none;
  color: black;
  display: flex;
  text-align: left;
  padding: 15px 40px;
  font-size: 30px;
}
.sidemenu .sidemenuLink ul li .sidmenuLinkActive {
  font-family: myFont2;
  background: var(--gradientBgColor);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.sidemenu .sidemenuLink .sidemenuContact{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top :20px;
    padding: 0px 40px;
  }
  .sidemenu .sidemenuLink .sidemenuContact .sidemenuContactBtn {
    text-decoration: none;
    color: white;
    background: var(--thirdColor);
    font-size: 18px;
    cursor: pointer;
    border-radius: 50px;
    padding: 12px 30px;
    transition: 0.3s;
    position: relative;
    overflow: hidden;
    z-index: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  @media (max-width: 1800px) {
    .sidemenu .sidemenuLink .sidemenuContact .sidemenuContactBtn {
      font-size: 16px;
    }
  }
  .sidemenu .sidemenuLink .sidemenuContact .sidemenuContactBtn::after {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    z-index: -1;
    background: var(--gradientBgColor);
    transition: 0.3s;
    border-radius: 50px;
  }
  .sidemenu .sidemenuLink .sidemenuContact .sidemenuContactBtn:hover::after {
    transform: scale(0.5);
    opacity: 0;
    transition: 0.3s;
  }

.bookPopup {
  position: fixed;
  z-index: 300;
  width: 500px;
  background: white;
  border-radius: 10px;
  padding: 40px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: none;
}
@media (max-width: 540px) {
  .bookPopup {
    width: 90%;
    padding: 30px 20px;
  }
}
@media (max-width: 480px) {
  .bookPopup {
    width: 95%;
  }
}
.bookPopup .closeBookPopup {
  width: 20px;
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
}
.bookPopup .closeBookPopup svg {
  width: 20px;
  height: 20px;
  color: #aaa;
}
.bookPopup h2 {
  font-size: 30px;
  font-weight: 600;
}
@media (max-width: 480px) {
  .bookPopup h2 {
    font-size: 28px;
  }
}
.bookPopup form {
  width: 100%;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
@media (max-height: 650px) {
  .bookPopup form {
    max-height: 60vh;
    overflow-y: auto;
  }
}
.bookPopup form .formGroup {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.bookPopup form .formGroup label {
  font-size: 16px;
}
.bookPopup form .formGroup input {
  width: 100%;
  height: 45px;
  border: 1px solid #aaa;
  border-radius: 5px;
  margin-top: 5px;
  padding: 0px 15px;
  font-size: 16px;
  outline: none;
}
.bookPopup form .formGroup input:focus {
  border-color: black;
}
.bookPopup form .formGroup textarea {
  width: 100%;
  height: 100px;
  border: 1px solid #aaa;
  border-radius: 5px;
  margin-top: 5px;
  padding: 10px 15px;
  font-size: 16px;
  outline: none;
}
.bookPopup form .formGroup textarea:focus {
  border-color: black;
}
.bookPopup form .formBtnArea {
  width: 100%;
  display: flex;
}
.bookPopup form .formBtnArea button {
  width: 100%;
  padding: 12px;
  font-size: 18px;
  background: var(--primaryColor);
  color: white;
  border: none;
  outline: none;
  border-radius: 5px;
  font-weight: 600;
  text-transform: uppercase;
  cursor: pointer;
  transition: 0.3s;
}
.bookPopup form .formBtnArea button:hover {
  transition: 0.3s;
  background: var(--secondaryColor);
}
.error{
  font-size: 0.8em;
  color:red;
  padding: 4px 0px;
}
/*# sourceMappingURL=main.css.map */